import React, { useState, useEffect, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {
  Button,
  Box,
  Typography,
  Paper,
  Toolbar,
  CircularProgress,
  Modal,
  Slider,
  IconButton,
  TextField,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import SmartToyIcon from "@mui/icons-material/SmartToy";
import SideNavBar from "./SideNavBar";
import "react-quill/dist/quill.snow.css";
import ReactQuill from "react-quill";
import { PDFDocument } from "pdf-lib";
import CropEasy from "react-easy-crop";
import CloseIcon from "@mui/icons-material/Close";
import "./CreateInvoice.css";

import PdfEditor from "./PdfEditor";

// ======================
// Helper: create a canvas from the cropped area
// ======================
const createImage = (url) =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener("load", () => resolve(image));
    image.addEventListener("error", (error) => reject(error));
    image.setAttribute("crossOrigin", "anonymous");
    image.src = url;
  });

async function getCroppedImg(imageSrc, croppedAreaPixels) {
  const image = await createImage(imageSrc);
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");

  canvas.width = croppedAreaPixels.width;
  canvas.height = croppedAreaPixels.height;

  ctx.drawImage(
    image,
    croppedAreaPixels.x,
    croppedAreaPixels.y,
    croppedAreaPixels.width,
    croppedAreaPixels.height,
    0,
    0,
    croppedAreaPixels.width,
    croppedAreaPixels.height
  );

  return new Promise((resolve, reject) => {
    canvas.toBlob((file) => {
      if (file) {
        resolve(file);
      } else {
        reject(new Error("Canvas is empty"));
      }
    }, "image/jpeg");
  });
}

// ==================================================
// 1) Convert to grayscale & auto-level
// ==================================================
function grayscaleAndAutoLevel(imageData) {
  const { data } = imageData;
  let minGray = 255,
    maxGray = 0;

  for (let i = 0; i < data.length; i += 4) {
    const gray = Math.round((data[i] + data[i + 1] + data[i + 2]) / 3);
    data[i] = data[i + 1] = data[i + 2] = gray;
    if (gray < minGray) minGray = gray;
    if (gray > maxGray) maxGray = gray;
  }

  const range = maxGray - minGray || 1;
  for (let i = 0; i < data.length; i += 4) {
    const scaled = ((data[i] - minGray) / range) * 255;
    data[i] = data[i + 1] = data[i + 2] = scaled;
  }
}

// ==================================================
// 2) Sharpen Helper (3×3 kernel)
// ==================================================
function sharpenGrayscale(imageData) {
  const { data, width, height } = imageData;
  const output = new Uint8ClampedArray(data.length);
  const kernel = [
    [0, -1, 0],
    [-1, 5, -1],
    [0, -1, 0],
  ];
  const idx = (x, y) => 4 * (y * width + x);

  for (let y = 1; y < height - 1; y++) {
    for (let x = 1; x < width - 1; x++) {
      let sum = 0;
      for (let ky = -1; ky <= 1; ky++) {
        for (let kx = -1; kx <= 1; kx++) {
          const pixelIndex = idx(x + kx, y + ky);
          sum += data[pixelIndex] * kernel[ky + 1][kx + 1];
        }
      }
      sum = Math.min(255, Math.max(0, sum));
      const outIndex = idx(x, y);
      output[outIndex] = sum;
      output[outIndex + 1] = sum;
      output[outIndex + 2] = sum;
      output[outIndex + 3] = 255;
    }
  }
  // Copy borders as-is
  for (let x = 0; x < width; x++) {
    [0, height - 1].forEach((y) => {
      const i = idx(x, y);
      output[i] = data[i];
      output[i + 1] = data[i + 1];
      output[i + 2] = data[i + 2];
      output[i + 3] = data[i + 3];
    });
  }
  for (let y = 0; y < height; y++) {
    [0, width - 1].forEach((x) => {
      const i = idx(x, y);
      output[i] = data[i];
      output[i + 1] = data[i + 1];
      output[i + 2] = data[i + 2];
      output[i + 3] = data[i + 3];
    });
  }
  for (let i = 0; i < data.length; i++) {
    data[i] = output[i];
  }
}

// ==================================================
// Combined "scan" effect: grayscale -> auto-level -> sharpen
// ==================================================
const processImageFile = async (file) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    const url = URL.createObjectURL(file);
    img.onload = () => {
      const canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0);
      let imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
      grayscaleAndAutoLevel(imageData);
      sharpenGrayscale(imageData);
      ctx.putImageData(imageData, 0, 0);
      canvas.toBlob(
        (blob) => {
          if (blob) {
            resolve(blob);
          } else {
            reject(new Error("Canvas conversion failed."));
          }
          URL.revokeObjectURL(url);
        },
        file.type,
        1
      );
    };
    img.onerror = (error) => {
      URL.revokeObjectURL(url);
      reject(error);
    };
    img.src = url;
  });
};

function Invoicing() {
  const { orderNumber } = useParams();
  const navigate = useNavigate();

  const [podFile, setPodFile] = useState(null);
  const [lcUrl, setLcUrl] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [pickupDate, setPickupDate] = useState("");
  const [pickupLocation, setPickupLocation] = useState("");
  const [deliveryLocation, setDeliveryLocation] = useState("");
  const [deliveryDate, setDeliveryDate] = useState("");
  const [rate, setRate] = useState(0.0);
  const [currency, setCurrency] = useState("");
  const [accountingEmail, setAccountingEmail] = useState("");
  const [dispatcherEmail, setDispatcherEmail] = useState("");
  const [pickups, setPickups] = useState([]);
  const [deliveries, setDeliveries] = useState([]);
  const [customerOrderNumber, setCustomerOrderNumber] = useState("");
  const [loadGivingCompany, setLoadGivingCompany] = useState({
    companyName: "",
    streetAddress: "",
    city: "",
    state: "",
    zip: "",
    phoneNumber: "",
  });
  const [organizationId, setOrganizationId] = useState("");
  const [invoicePdfUrl, setInvoicePdfUrl] = useState("");
  const [terms, setTerms] = useState("");
  const [showTnC, setShowTnC] = useState(false);
  const [imageFiles, setImageFiles] = useState([]);
  const [openCropModal, setOpenCropModal] = useState(false);
  const [cropImageObj, setCropImageObj] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [cropSize, setCropSize] = useState({ width: 350, height: 300 });
  const [combinedPdfFile, setCombinedPdfFile] = useState(null);
  const [openEmailModal, setOpenEmailModal] = useState(false);
  const [emailSubject, setEmailSubject] = useState("");
  const [emailBody, setEmailBody] = useState("");
  const [emailTo, setEmailTo] = useState("");
  const [emailCc, setEmailCc] = useState("");
  const [emailAttachments, setEmailAttachments] = useState([]);
  const [showPdfEditorModal, setShowPdfEditorModal] = useState(false);

  const [storageUserId, setStorageUserId] = useState("");
  const [userFirstName, setUserFirstName] = useState("");
  const [userLastName, setUserLastName] = useState("");
  const [userCompanyLogo, setUserCompanyLogo] = useState("");

  const darkTheme = createTheme({
    palette: {
      mode: "dark",
      background: { default: "#000000", paper: "#000000" },
      text: { primary: "#ffffff", secondary: "#aaaaaa" },
      primary: { main: "#3c94fc" },
      secondary: { main: "#f50057" },
    },
  });

  // Fetch LC PDF if needed
  useEffect(() => {
    const fetchLcUrl = async () => {
      setIsLoading(true);
      try {
        const response = await fetch(
          `/api/get-order-pdf?orderNumber=${encodeURIComponent(orderNumber)}`
        );
        if (!response.ok) {
          throw new Error(`Failed to fetch PDF for order #${orderNumber}`);
        }
        const data = await response.json();
        if (data && data.pdfUrl) {
          setLcUrl(data.pdfUrl);
        } else {
          console.warn("No pdfUrl returned from backend.");
        }
      } catch (error) {
        console.error("Error fetching PDF:", error);
      } finally {
        setIsLoading(false);
      }
    };
    if (orderNumber) fetchLcUrl();
  }, [orderNumber]);

  useEffect(() => {
    setOrganizationId(sessionStorage.getItem("storageOrganizationId"));
  }, []);

  useEffect(() => {
    const storedUserId = sessionStorage.getItem("storageUserId");
    const storedFirstName = sessionStorage.getItem("storageFirstName");
    const storedLastName = sessionStorage.getItem("storageLastName");
    const companyLogoUrl = sessionStorage.getItem("storageCompanyLogo");

    if (storedUserId) setStorageUserId(storedUserId);
    if (storedFirstName) setUserFirstName(storedFirstName);
    if (storedLastName) setUserLastName(storedLastName);
    if (companyLogoUrl) setUserCompanyLogo(companyLogoUrl);
  }, []);

  const companyInfo = {
    name: sessionStorage.getItem("storageCompanyName"),
    address: sessionStorage.getItem("storageAddress"),
    city: sessionStorage.getItem("storageCity"),
    state: sessionStorage.getItem("storageState"),
    zip: sessionStorage.getItem("storageZip"),
    phone: sessionStorage.getItem("storageCompanyPhone"),
    email: sessionStorage.getItem("storageUserAccountingEmail"),
    website: sessionStorage.getItem("storageCompanyWebsite"),
    logoBase64: sessionStorage.getItem("storageCompanyLogo"),
  };

  // Fetch new load details
  useEffect(() => {
    const fetchLoadDetails = async () => {
      try {
        const response = await fetch(
          `/api/get-order?orderId=${encodeURIComponent(orderNumber)}`
        );
        if (!response.ok) {
          throw new Error(`Failed to fetch load details for #${orderNumber}`);
        }
        const data = await response.json();

        setPickupDate(data.result.pickupDate || "");
        setDeliveryDate(data.result.deliveryDate || "");
        setRate(data.result.rate || 0.0);
        setCurrency(data.result.currency || "");
        setDispatcherEmail(data.result.CustomerCompany.dispatchEmail || "");
        setAccountingEmail(data.result.CustomerCompany.accountingEmail || "");

        if (Array.isArray(data.result.pickupLocation)) {
          setPickups(data.result.pickupLocation);
          if (data.result.pickupLocation.length > 0) {
            const firstPickup = data.result.pickupLocation[0];
            setPickupLocation(
              `${firstPickup.locationName || ""}, ${firstPickup.city || ""} ${
                firstPickup.state || ""
              }`
            );
          }
        } else {
          setPickups([]);
        }

        if (Array.isArray(data.result.deliveryLocation)) {
          setDeliveries(data.result.deliveryLocation);
          if (data.result.deliveryLocation.length > 0) {
            const firstDelivery = data.result.deliveryLocation[0];
            setDeliveryLocation(
              `${firstDelivery.locationName || ""}, ${
                firstDelivery.city || ""
              } ${firstDelivery.state || ""}`
            );
          }
        } else {
          setDeliveries([]);
        }
        setCustomerOrderNumber(data.result.customerOrderNo || "");

        // The new field for the load confirmation PDF link
        if (data.result.pdf) {
          setLcUrl(data.result.pdf);
        }

        // Bill To info
        if (data.result.CustomerCompany) {
          setLoadGivingCompany({
            companyName: data.result.CustomerCompany.name || "",
            streetAddress: data.result.CustomerCompany.address || "",
            city: data.result.CustomerCompany.city || "",
            state: data.result.CustomerCompany.state || "",
            zip: data.result.CustomerCompany.zip || "",
            phoneNumber: data.result.CustomerCompany.phoneNumber || "",
          });
        }
      } catch (error) {
        console.error("Error fetching load details:", error);
      }
    };

    const fetchTerms = async () => {
      try {
        const response = await fetch(
          `/api/get-terms-conditions?organizationId=${encodeURIComponent(
            organizationId
          )}&companyName=${encodeURIComponent(companyInfo.name)}`
        );
        if (response.ok) {
          const data = await response.json();
          setTerms(data.Invoice || "");
        } else {
          console.error("Failed to fetch terms and conditions.");
          setTerms("");
        }
      } catch (error) {
        console.error("Error fetching terms and conditions:", error);
        setTerms("");
      }
    };

    fetchLoadDetails();
    fetchTerms();
  }, [orderNumber, organizationId, companyInfo.name]);

  // -------------------------------------------------------
  // PDF Merge Helpers
  // -------------------------------------------------------
  const mergePdfBuffers = async (buffers, forceLetter = false) => {
    const mergedPdf = await PDFDocument.create();
    for (const buffer of buffers) {
      const loadedPdf = await PDFDocument.load(buffer);
      const pages = loadedPdf.getPages();
      for (let i = 0; i < pages.length; i++) {
        if (forceLetter) {
          const [embeddedPage] = await mergedPdf.embedPages([pages[i]]);
          const letterWidth = 612;
          const letterHeight = 792;
          const origWidth = embeddedPage.width;
          const origHeight = embeddedPage.height;
          const scale = Math.min(
            letterWidth / origWidth,
            letterHeight / origHeight
          );
          const scaledWidth = origWidth * scale;
          const scaledHeight = origHeight * scale;
          const x = (letterWidth - scaledWidth) / 2;
          const y = (letterHeight - scaledHeight) / 2;
          const newPage = mergedPdf.addPage([letterWidth, letterHeight]);
          newPage.drawPage(embeddedPage, {
            x,
            y,
            xScale: scale,
            yScale: scale,
          });
        } else {
          const [copiedPage] = await mergedPdf.copyPages(loadedPdf, [i]);
          mergedPdf.addPage(copiedPage);
        }
      }
    }
    return mergedPdf.save();
  };

  const copyPagesIntoMerged = async (
    mergedPdf,
    pdfBytes,
    forceLetter = false
  ) => {
    const loadedPdf = await PDFDocument.load(pdfBytes);
    const pages = loadedPdf.getPages();
    for (let i = 0; i < pages.length; i++) {
      if (forceLetter) {
        const letterWidth = 612;
        const letterHeight = 792;
        const [embeddedPage] = await mergedPdf.embedPages([pages[i]]);
        const scale = Math.min(
          letterWidth / embeddedPage.width,
          letterHeight / embeddedPage.height
        );
        const x = (letterWidth - embeddedPage.width * scale) / 2;
        const y = (letterHeight - embeddedPage.height * scale) / 2;
        const newPage = mergedPdf.addPage([letterWidth, letterHeight]);
        newPage.drawPage(embeddedPage, { x, y, xScale: scale, yScale: scale });
      } else {
        const [copiedPage] = await mergedPdf.copyPages(loadedPdf, [i]);
        mergedPdf.addPage(copiedPage);
      }
    }
  };

  // -------------------------------------------------------
  // Handle Uploads (PDF or Images)
  // -------------------------------------------------------
  const handleFileChange = async (event) => {
    const files = event.target.files;
    if (!files || files.length === 0) return;
    const pdfBuffers = [];
    const newImageFiles = [];

    for (const file of files) {
      if (file.type === "application/pdf") {
        pdfBuffers.push(await file.arrayBuffer());
      } else if (file.type.startsWith("image/")) {
        let processedBlob = null;
        try {
          processedBlob = await processImageFile(file);
        } catch (error) {
          console.error("Error processing image:", error);
        }
        const previewUrl = URL.createObjectURL(file);
        newImageFiles.push({
          id: Math.random().toString(36).substring(2),
          file,
          previewUrl,
          croppedBlob: null,
          processedBlob: processedBlob || null,
          isCropped: false,
          error: null,
        });
      } else {
        console.log(`Skipping merge for file type: ${file.type}`);
      }
    }

    let existingPdfBuffers = [];
    if (podFile) {
      // Merge in the existing POD PDF
      const existingArrayBuffer = await new Response(podFile).arrayBuffer();
      existingPdfBuffers.push(existingArrayBuffer);
    }
    const allPdfBuffers = [...existingPdfBuffers, ...pdfBuffers];
    let mergedPdfBytes = null;
    if (allPdfBuffers.length > 0) {
      mergedPdfBytes = await mergePdfBuffers(allPdfBuffers, true);
    }
    if (mergedPdfBytes) {
      const mergedBlob = new Blob([mergedPdfBytes], {
        type: "application/pdf",
      });
      setPodFile(mergedBlob);
    }
    // Add images to state for optional cropping
    setImageFiles((prev) => [...prev, ...newImageFiles]);
  };

  // -------------------------------------------------------
  // Crop Modal logic
  // -------------------------------------------------------
  const handleOpenCropModal = (imgFileObj) => {
    setCropImageObj(imgFileObj);
    setOpenCropModal(true);
  };

  const handleCloseCropModal = () => {
    setOpenCropModal(false);
    setCrop({ x: 0, y: 0 });
    setZoom(1);
    setCroppedAreaPixels(null);
    setCropImageObj(null);
  };

  const onCropComplete = useCallback((_, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const handleConfirmCrop = async () => {
    if (!cropImageObj || !croppedAreaPixels) return;
    try {
      const croppedBlob = await getCroppedImg(
        cropImageObj.previewUrl,
        croppedAreaPixels
      );
      const processedBlob = await processImageFile(croppedBlob);
      setImageFiles((prev) =>
        prev.map((item) =>
          item.id === cropImageObj.id
            ? {
                ...item,
                croppedBlob,
                processedBlob,
                isCropped: true,
                error: null,
              }
            : item
        )
      );
    } catch (error) {
      console.error(error);
      setImageFiles((prev) =>
        prev.map((item) =>
          item.id === cropImageObj.id
            ? { ...item, error: "Cropping failed." }
            : item
        )
      );
    }
    handleCloseCropModal();
  };

  // -------------------------------------------------------
  // Terms & Conditions
  // -------------------------------------------------------
  const handleToggleTerms = () => {
    setShowTnC((prev) => !prev);
  };

  const handleSaveTerms = async () => {
    try {
      const response = await fetch("/api/save-terms-conditions", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          organizationId: organizationId,
          Invoice: terms,
        }),
      });
      alert(
        response.ok
          ? "Terms and Conditions saved successfully."
          : "Error saving Terms and Conditions."
      );
    } catch (error) {
      console.error("Error saving terms and conditions:", error);
      alert("Error saving Terms and Conditions.");
    }
  };

  // -------------------------------------------------------
  // Invoice Generation
  // -------------------------------------------------------
  const handleCreateInvoice = () => {
    const invoiceFilename = combinedPdfFile
      ? combinedPdfFile.name
      : `${companyInfo.name} ${pickupLocation} ${deliveryLocation} ${rate}${currency}.pdf`;

    const invoiceData = {
      company: companyInfo,
      billTo: {
        name: loadGivingCompany.companyName,
        address: loadGivingCompany.streetAddress,
        city: loadGivingCompany.city,
        state: loadGivingCompany.state,
        zip: loadGivingCompany.zip,
        phone: loadGivingCompany.phoneNumber,
        email: accountingEmail,
      },
      invoice: {
        number: orderNumber,
        date: new Date().toISOString().split("T")[0],
        pickupDate,
        deliveryDate,
        customerOrder: customerOrderNumber,
        freightCharge: rate,
        additionalCharges: 0.0,
        currency,
      },
      terms,
      pickups: pickups.map((p) => ({
        address: `${p.locationName}, ${p.streetAddress || ""}, ${p.city}, ${
          p.state
        } ${p.zip || ""}`,
      })),
      deliveries: deliveries.map((d) => ({
        address: `${d.locationName}, ${d.streetAddress || ""}, ${d.city}, ${
          d.state
        } ${d.zip || ""}`,
      })),
      filename: invoiceFilename,
    };
    localStorage.setItem("invoiceData", JSON.stringify(invoiceData));
    window.open("/invoice.html", "_blank");
  };

  // -------------------------------------------------------
  // Combine PDFs with leftover images
  // -------------------------------------------------------
  const handleCombinePDFs = async () => {
    try {
      const mergedPdf = await PDFDocument.create();

      // 1) If there's an invoice PDF
      if (invoicePdfUrl) {
        const invoiceBytes = await fetch(invoicePdfUrl).then((res) =>
          res.arrayBuffer()
        );
        await copyPagesIntoMerged(mergedPdf, invoiceBytes, false);
      }

      // 2) Load confirmation
      if (lcUrl && lcUrl.trim() !== "") {
        const extension = lcUrl.split(".").pop().toLowerCase();
        let isPdfFile = extension === "pdf";
        let isWordFile = ["doc", "docx"].includes(extension);
        let isImageFile = ["jpg", "jpeg", "png", "gif", "bmp"].includes(
          extension
        );

        if (isWordFile) {
          alert(
            "Warning: The load confirmation is a Word document. It will NOT be combined."
          );
        } else if (isPdfFile) {
          const lcBytes = await fetch(lcUrl).then((res) => res.arrayBuffer());
          await copyPagesIntoMerged(mergedPdf, lcBytes, true);
        } else if (isImageFile) {
          // Convert the single LC image into PDF
          const response = await fetch(lcUrl);
          const imageBlob = await response.blob();
          const contentType = response.headers.get("content-type") || "";
          const buffer = await imageBlob.arrayBuffer();

          const tempPdfDoc = await PDFDocument.create();
          let embeddedImage;
          try {
            if (contentType.includes("png")) {
              try {
                // attempt embedPng
                embeddedImage = await tempPdfDoc.embedPng(buffer);
              } catch (err) {
                // fallback if mislabeled or fails
                console.warn("LC embedPng failed, fallback to embedJpg");
                embeddedImage = await tempPdfDoc.embedJpg(buffer);
              }
            } else {
              // fallback for other types
              embeddedImage = await tempPdfDoc.embedJpg(buffer);
            }
          } catch (err) {
            console.error("Error embedding LC image as PNG/JPG:", err);
            alert(
              "Warning: The load confirmation image could not be embedded."
            );
            embeddedImage = null;
          }
          if (embeddedImage) {
            const { width, height } = embeddedImage;
            const page = tempPdfDoc.addPage([width, height]);
            page.drawImage(embeddedImage, { x: 0, y: 0, width, height });
            const tempPdfBytes = await tempPdfDoc.save();
            await copyPagesIntoMerged(mergedPdf, tempPdfBytes, true);
          }
        }
      }

      // 3) Merged POD
      if (podFile) {
        const podBytes = await new Response(podFile).arrayBuffer();
        await copyPagesIntoMerged(mergedPdf, podBytes, true);
      }

      // 4) Convert leftover images to PDF pages
      let imagePdfBuffers = [];
      if (imageFiles && imageFiles.length > 0) {
        const imagesToConvert = imageFiles.filter((f) => f.processedBlob);
        if (imagesToConvert.length > 0) {
          const tempPdfDoc = await PDFDocument.create();
          for (const imgObj of imagesToConvert) {
            const buffer = await imgObj.processedBlob.arrayBuffer();

            let embeddedImage;
            try {
              if (imgObj.file.type.includes("png")) {
                // attempt embedPng
                try {
                  embeddedImage = await tempPdfDoc.embedPng(buffer);
                } catch (err) {
                  // fallback if mislabeled
                  console.warn("Image embedPng failed, fallback to embedJpg");
                  embeddedImage = await tempPdfDoc.embedJpg(buffer);
                }
              } else {
                embeddedImage = await tempPdfDoc.embedJpg(buffer);
              }
            } catch (err) {
              console.error("Error embedding leftover image:", err);
              continue; // skip embedding this image
            }
            if (embeddedImage) {
              const { width, height } = embeddedImage;
              const page = tempPdfDoc.addPage([width, height]);
              page.drawImage(embeddedImage, { x: 0, y: 0, width, height });
            }
          }
          const tempPdfBytes = await tempPdfDoc.save();
          imagePdfBuffers.push(tempPdfBytes);
        }
      }

      // 5) Merge leftover images
      if (imagePdfBuffers.length > 0) {
        for (const pdfBuffer of imagePdfBuffers) {
          await copyPagesIntoMerged(mergedPdf, pdfBuffer, true);
        }
      }

      // Final merged PDF as a File
      const mergedPdfBytes = await mergedPdf.save();
      const blob = new Blob([mergedPdfBytes], { type: "application/pdf" });
      const filename = `${companyInfo.name} ${pickupLocation} ${deliveryLocation} ${rate}${currency}.pdf`;
      const file = new File([blob], filename, { type: "application/pdf" });
      setCombinedPdfFile(file);
      setShowPdfEditorModal(true);
    } catch (error) {
      console.error("Error combining PDFs:", error);
      alert("There was an error combining the PDFs. Please try again.");
    }
  };

  // -------------------------------------------------------
  // Download Combined PDF
  // -------------------------------------------------------
  const handleDownloadCombinedPdf = () => {
    if (!combinedPdfFile) {
      alert("No combined PDF is available to download.");
      return;
    }
    const url = URL.createObjectURL(combinedPdfFile);
    const link = document.createElement("a");
    link.href = url;
    link.download = combinedPdfFile.name;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  // Handle inbound message from child
  useEffect(() => {
    const messageHandler = (event) => {
      if (event.data && event.data.type === "INVOICE_PDF") {
        setInvoicePdfUrl(event.data.pdfDataUrl);
      }
    };
    window.addEventListener("message", messageHandler);
    return () => window.removeEventListener("message", messageHandler);
  }, []);

  // Quill modules
  const quillModules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "strike"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link"],
      ["clean"],
    ],
  };

  // -------------------------------------------------------
  // Email Modal
  // -------------------------------------------------------
  const handleOpenEmailModal = () => {
    setEmailSubject(`Invoice POD ${customerOrderNumber || ""}`);
    setEmailBody(
      `Hi,\nPlease find attached the invoice and POD for order # ${
        customerOrderNumber || ""
      }.\nPlease confirm receipt.\n\nThanks.`
    );
    setEmailTo(accountingEmail || "");
    setEmailCc(dispatcherEmail || "");
    setOpenEmailModal(true);
  };

  const handleCloseEmailModal = () => {
    setOpenEmailModal(false);
  };

  const handleEmailAttachmentChange = (event) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      setEmailAttachments((prev) => [...prev, ...Array.from(files)]);
    }
  };

  const handleRemoveAttachment = (index) => {
    setEmailAttachments((prev) => {
      const updated = [...prev];
      updated.splice(index, 1);
      return updated;
    });
  };

  async function sendAccountingEmail() {
    const formData = new FormData();
    formData.append("toEmail", emailTo);
    formData.append("ccEmails", emailCc || "");
    formData.append("subject", emailSubject);
    formData.append("body", emailBody);

    formData.append("firstName", userFirstName || "");
    formData.append("lastName", userLastName || "");
    formData.append("phone", companyInfo.phone || "");
    formData.append("storageUserId", storageUserId || "");
    formData.append("storageOrganizationId", organizationId);

    formData.append("address", companyInfo.address || "");
    formData.append("city", companyInfo.city || "");
    formData.append("state", companyInfo.state || "");
    formData.append("zip", companyInfo.zip || "");

    formData.append("companyLogo", userCompanyLogo || "");
    formData.append("companyWebsite", companyInfo.website || "");

    // attach final combined PDF if present
    if (combinedPdfFile) {
      formData.append("attachment", combinedPdfFile);
    }

    // additional attachments
    if (emailAttachments && emailAttachments.length > 0) {
      for (const file of emailAttachments) {
        formData.append("additionalAttachments", file);
      }
    }

    const response = await fetch("/api/send-email-with-attachment-invoicing", {
      method: "POST",
      body: formData,
    });

    const data = await response.json();
    if (response.ok) {
      try {
        // create invoice record after email is sent
        const invoicePayload = {
          orderId: orderNumber,
          organizationId: organizationId,
          status: "Unpaid",
        };
        await fetch("/api/create-invoice-record", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(invoicePayload),
        });
      } catch (error) {
        console.error("Error creating invoice entry:", error);
      }
      alert("Email sent successfully!");
    } else {
      alert("Error sending email: " + data.error);
    }
  }

  const handleEnhanceEmail = async () => {
    try {
      const response = await fetch("/api/enhance-subject-body-accounting", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ subject: emailSubject, body: emailBody }),
      });
      if (!response.ok) throw new Error("Failed to enhance subject/body");
      const data = await response.json();
      const parsedMessage = JSON.parse(data.message);
      if (parsedMessage.subject) {
        setEmailSubject(parsedMessage.subject);
      }
      if (parsedMessage.body) {
        setEmailBody(parsedMessage.body);
      }
    } catch (error) {
      console.error("Error in AI enhancement:", error);
      alert("Error enhancing email content. Please try again.");
    }
  };

  // -------------------------------------------------------
  // PDF Editor Modal
  // -------------------------------------------------------
  const handlePdfEditorClose = () => {
    setShowPdfEditorModal(false);
  };

  const handlePdfEditorSave = (updatedBlob) => {
    setCombinedPdfFile(updatedBlob);
  };

  const handleViewCombinedPdf = () => {
    if (!combinedPdfFile) {
      alert("No combined PDF is available to view.");
      return;
    }
    const url = URL.createObjectURL(combinedPdfFile);
    window.open(url, "_blank");
  };

  const handleEditOrder = () => {
    sessionStorage.setItem("selectedLoadNumber", orderNumber);
    navigate("/edit-order", { state: { from: "invoicing" } });
  };

  if (isLoading) {
    return (
      <ThemeProvider theme={darkTheme}>
        <Box
          sx={{
            height: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#000000",
          }}
        >
          <CircularProgress color="primary" />
        </Box>
      </ThemeProvider>
    );
  }

  return (
    <ThemeProvider theme={darkTheme}>
      <div className="create-invoice-container">
        <SideNavBar />
        <div className="create-invoice-window">
          <div style={{ flex: 1 }}>
            <Toolbar style={{ minHeight: "83px" }} />
            <div className="create-invoice-body">
              <Box
                component={Paper}
                sx={{ m: 2, p: 2, backgroundColor: "#000000" }}
              >
                <Typography variant="h4" gutterBottom>
                  Invoicing for Order # {orderNumber}
                </Typography>

                {/* Load Details */}
                <Box sx={{ my: 2 }}>
                  <Typography variant="h6" gutterBottom>
                    Load Details:
                  </Typography>
                  <Typography variant="body2">
                    <strong>Pickup Date:</strong> {pickupDate}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Pickup Location:</strong> {pickupLocation}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Delivery Location:</strong> {deliveryLocation}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Delivery Date:</strong> {deliveryDate}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Rate:</strong> {rate} {currency}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Dispatcher Email:</strong> {dispatcherEmail}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Accounting Email:</strong> {accountingEmail}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Pickups:</strong>{" "}
                    {Array.isArray(pickups)
                      ? pickups.map((pickup, idx) => (
                          <span key={idx}>
                            {pickup.locationName}, {pickup.streetAddress},{" "}
                            {pickup.city}, {pickup.state} {pickup.zip}
                            {idx < pickups.length - 1 ? " | " : ""}
                          </span>
                        ))
                      : "N/A"}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Deliveries:</strong>{" "}
                    {Array.isArray(deliveries)
                      ? deliveries.map((delivery, idx) => (
                          <span key={idx}>
                            {delivery.locationName}, {delivery.streetAddress},{" "}
                            {delivery.city}, {delivery.state} {delivery.zip}
                            {idx < deliveries.length - 1 ? " | " : ""}
                          </span>
                        ))
                      : "N/A"}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Load Giving Company:</strong>{" "}
                    {loadGivingCompany.companyName} -{" "}
                    {loadGivingCompany.streetAddress}, {loadGivingCompany.city},{" "}
                    {loadGivingCompany.state} {loadGivingCompany.zip} (Phone:{" "}
                    {loadGivingCompany.phoneNumber})
                  </Typography>
                </Box>

                {/* Existing Load Confirmation File */}
                {lcUrl && lcUrl.trim() !== "" && (
                  <Box sx={{ my: 2 }}>
                    <Typography variant="body1">
                      Load Confirmation File:
                    </Typography>
                    <a
                      href={lcUrl}
                      target="_blank"
                      rel="noreferrer"
                      style={{ color: "#3c94fc" }}
                    >
                      View / Download
                    </a>
                  </Box>
                )}

                {/* POD Upload */}
                <Typography variant="body1" gutterBottom>
                  Upload your Proof of Delivery (POD) file(s) here.
                </Typography>
                <Box sx={{ mt: 2, mb: 2 }}>
                  <Button variant="contained" component="label" color="primary">
                    Select File(s)
                    <input
                      type="file"
                      hidden
                      accept="application/pdf, image/*"
                      multiple
                      onChange={handleFileChange}
                    />
                  </Button>
                </Box>

                {podFile && (
                  <Typography variant="body2" sx={{ mb: 2 }}>
                    Merged PDF (from previously uploaded PDFs) is ready.
                  </Typography>
                )}

                {/* Display uploaded images */}
                {imageFiles.length > 0 && (
                  <Box sx={{ border: "1px solid #aaa", p: 2, my: 2 }}>
                    <Typography variant="h6" gutterBottom>
                      POD Images (Crop optional)
                    </Typography>
                    {imageFiles.map((img) => (
                      <Box
                        key={img.id}
                        sx={{ display: "flex", alignItems: "center", mb: 1 }}
                      >
                        <img
                          src={img.previewUrl}
                          alt="preview"
                          style={{
                            width: 80,
                            height: 80,
                            objectFit: "cover",
                            marginRight: "1rem",
                            border: "1px solid #666",
                          }}
                        />
                        {img.isCropped ? (
                          <Typography variant="body2" color="green">
                            Cropped & Processed
                          </Typography>
                        ) : (
                          <Typography
                            variant="body2"
                            sx={{ mr: 2, color: "#ccc" }}
                          >
                            Automatically Processed
                          </Typography>
                        )}
                        <Button
                          variant="outlined"
                          color="secondary"
                          size="small"
                          onClick={() => handleOpenCropModal(img)}
                        >
                          Crop (Optional)
                        </Button>
                        {img.error && (
                          <Typography
                            variant="body2"
                            color="red"
                            sx={{ ml: 2 }}
                          >
                            {img.error}
                          </Typography>
                        )}
                      </Box>
                    ))}
                  </Box>
                )}

                {/* Crop Modal */}
                <Modal
                  open={openCropModal}
                  onClose={handleCloseCropModal}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box
                    sx={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      width: "90vw",
                      height: "80vh",
                      bgcolor: "background.paper",
                      boxShadow: 24,
                      p: 4,
                      outline: "none",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        mb: 2,
                      }}
                    >
                      <Typography variant="h6">
                        Crop your image (optional)
                      </Typography>
                      <IconButton onClick={handleCloseCropModal}>
                        <CloseIcon />
                      </IconButton>
                    </Box>
                    <Box
                      sx={{
                        position: "relative",
                        width: "100%",
                        height: "60%",
                        background: "#333",
                        mb: 2,
                      }}
                    >
                      {cropImageObj && (
                        <CropEasy
                          image={cropImageObj.previewUrl}
                          crop={crop}
                          zoom={zoom}
                          aspect={null}
                          cropShape="rect"
                          showGrid={true}
                          restrictPosition={false}
                          cropSize={cropSize}
                          onCropChange={setCrop}
                          onZoomChange={setZoom}
                          onCropComplete={onCropComplete}
                        />
                      )}
                    </Box>
                    <Typography variant="body2" sx={{ mb: 1 }}>
                      ** Adjust Crop Box Size **
                    </Typography>
                    <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
                      <Box sx={{ width: "40%" }}>
                        <Typography>Width: {cropSize.width}px</Typography>
                        <Slider
                          value={cropSize.width}
                          min={50}
                          max={1200}
                          step={10}
                          onChange={(e, newVal) =>
                            setCropSize((prev) => ({ ...prev, width: newVal }))
                          }
                        />
                      </Box>
                      <Box sx={{ width: "40%" }}>
                        <Typography>Height: {cropSize.height}px</Typography>
                        <Slider
                          value={cropSize.height}
                          min={50}
                          max={1200}
                          step={10}
                          onChange={(e, newVal) =>
                            setCropSize((prev) => ({ ...prev, height: newVal }))
                          }
                        />
                      </Box>
                    </Box>
                    <Box sx={{ mt: 3 }}>
                      <Typography>Zoom: {zoom.toFixed(2)}</Typography>
                      <Slider
                        value={zoom}
                        min={1}
                        max={5}
                        step={0.1}
                        onChange={(e, zoomValue) => setZoom(zoomValue)}
                      />
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        mt: 2,
                      }}
                    >
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={handleConfirmCrop}
                      >
                        Confirm Crop
                      </Button>
                    </Box>
                  </Box>
                </Modal>

                {/* Terms and Conditions */}
                <Box sx={{ mt: 4, mb: 2 }}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleToggleTerms}
                  >
                    {showTnC
                      ? "Hide Terms and Conditions"
                      : "Edit Terms and Conditions"}
                  </Button>
                  {showTnC ? (
                    <Box sx={{ mt: 2 }}>
                      <ReactQuill
                        theme="snow"
                        value={terms}
                        onChange={setTerms}
                        modules={quillModules}
                        style={{ backgroundColor: "#fff", color: "#000" }}
                      />
                      <Box sx={{ mt: 2 }}>
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={handleSaveTerms}
                        >
                          Save Terms and Conditions
                        </Button>
                      </Box>
                    </Box>
                  ) : (
                    <Box
                      sx={{ mt: 2, whiteSpace: "pre-wrap", color: "#ffffff" }}
                    >
                      <Typography variant="body1">
                        <span
                          dangerouslySetInnerHTML={{ __html: terms }}
                        ></span>
                      </Typography>
                    </Box>
                  )}
                </Box>

                {/* Action Buttons */}
                <Box sx={{ mt: 4, mb: 2, display: "flex", gap: 2 }}>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleCreateInvoice}
                  >
                    Create Invoice
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleCombinePDFs}
                  >
                    Combine PDFs
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleOpenEmailModal}
                    disabled={!combinedPdfFile}
                  >
                    Send Email
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleViewCombinedPdf}
                    disabled={!combinedPdfFile}
                  >
                    View Combined PDF
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleDownloadCombinedPdf}
                    disabled={!combinedPdfFile}
                  >
                    Download Invoice
                  </Button>
                  <Button
                    variant="contained"
                    color="info"
                    onClick={handleEditOrder}
                  >
                    Edit Order
                  </Button>
                </Box>

                {/* EMAIL Modal */}
                <Modal
                  open={openEmailModal}
                  onClose={handleCloseEmailModal}
                  aria-labelledby="modal-email-title"
                  aria-describedby="modal-email-description"
                >
                  <Box
                    sx={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      width: "600px",
                      maxWidth: "90vw",
                      bgcolor: "#333",
                      color: "#fff",
                      boxShadow: 24,
                      p: 4,
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        mb: 2,
                      }}
                    >
                      <Typography variant="h6">Send Email</Typography>
                      <IconButton onClick={handleCloseEmailModal}>
                        <CloseIcon style={{ color: "#fff" }} />
                      </IconButton>
                    </Box>
                    <Box
                      component="form"
                      sx={{ display: "flex", flexDirection: "column", gap: 2 }}
                    >
                      <TextField
                        label="Subject"
                        variant="filled"
                        fullWidth
                        value={emailSubject}
                        onChange={(e) => setEmailSubject(e.target.value)}
                        InputProps={{ style: { color: "#fff" } }}
                        InputLabelProps={{ style: { color: "#aaa" } }}
                        sx={{ backgroundColor: "#222" }}
                      />
                      <TextField
                        label="To"
                        variant="filled"
                        fullWidth
                        value={emailTo}
                        onChange={(e) => setEmailTo(e.target.value)}
                        InputProps={{ style: { color: "#fff" } }}
                        InputLabelProps={{ style: { color: "#aaa" } }}
                        sx={{ backgroundColor: "#222" }}
                      />
                      <TextField
                        label="CC"
                        variant="filled"
                        fullWidth
                        value={emailCc}
                        onChange={(e) => setEmailCc(e.target.value)}
                        InputProps={{ style: { color: "#fff" } }}
                        InputLabelProps={{ style: { color: "#aaa" } }}
                        sx={{ backgroundColor: "#222" }}
                      />
                      <TextField
                        label="Body"
                        variant="filled"
                        multiline
                        rows={5}
                        fullWidth
                        value={emailBody}
                        onChange={(e) => setEmailBody(e.target.value)}
                        InputProps={{
                          style: { color: "#fff" },
                          endAdornment: (
                            <IconButton onClick={handleEnhanceEmail}>
                              <SmartToyIcon sx={{ color: "#fff" }} />
                            </IconButton>
                          ),
                        }}
                        InputLabelProps={{ style: { color: "#aaa" } }}
                        sx={{ backgroundColor: "#222" }}
                      />
                      <Box>
                        <InputLabel sx={{ mb: 1, color: "#aaa" }}>
                          Additional Attachments
                        </InputLabel>
                        <Button variant="contained" component="label">
                          Add Files
                          <input
                            type="file"
                            hidden
                            multiple
                            onChange={handleEmailAttachmentChange}
                          />
                        </Button>
                        {emailAttachments.length > 0 && (
                          <List
                            dense
                            sx={{ mt: 2, maxHeight: 150, overflowY: "auto" }}
                          >
                            {emailAttachments.map((file, idx) => (
                              <ListItem key={idx}>
                                <ListItemText
                                  primary={file.name}
                                  secondary={`${(file.size / 1024).toFixed(
                                    2
                                  )} KB`}
                                />
                                <ListItemSecondaryAction>
                                  <IconButton
                                    edge="end"
                                    aria-label="delete"
                                    onClick={() => handleRemoveAttachment(idx)}
                                  >
                                    <DeleteIcon sx={{ color: "#fff" }} />
                                  </IconButton>
                                </ListItemSecondaryAction>
                              </ListItem>
                            ))}
                          </List>
                        )}
                      </Box>
                      {combinedPdfFile && (
                        <Typography variant="body2" sx={{ mt: 2 }}>
                          <strong>Auto-attached:</strong> {combinedPdfFile.name}
                        </Typography>
                      )}
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "flex-end",
                          mt: 2,
                        }}
                      >
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={sendAccountingEmail}
                        >
                          Send
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                </Modal>

                {/* PDF Editor Modal */}
                {combinedPdfFile && (
                  <Modal
                    open={showPdfEditorModal}
                    onClose={handlePdfEditorClose}
                    aria-labelledby="pdf-editor-modal"
                    aria-describedby="pdf-editor-modal-description"
                  >
                    <Box
                      sx={{
                        width: "95vw",
                        height: "95vh",
                        margin: "auto",
                        mt: 2,
                        backgroundColor: "#000",
                        position: "relative",
                        overflow: "auto",
                      }}
                    >
                      <PdfEditor
                        combinedPdfBlob={combinedPdfFile}
                        onSave={handlePdfEditorSave}
                        onClose={handlePdfEditorClose}
                        showSideNavBar={false}
                        paddingLeft="0%"
                        paddingRight="0%"
                        prefix="MyInvoice"
                        number={orderNumber || ""}
                        matchedBorder="Final"
                      />
                    </Box>
                  </Modal>
                )}
              </Box>
            </div>
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
}

export default Invoicing;
