import React, { useState, useEffect } from "react";
import {
  Card,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Button,
  Box,
  Typography,
  Pagination,
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import Toolbar from "@mui/material/Toolbar";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Spinner from "./Spinner";
import SideNavBar from "./SideNavBar";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import "./Broker.css";

function DriversManagement() {
  const [driversInfo, setDriversInfo] = useState([]);
  const [trucksList, setTrucksList] = useState([]);
  const [trailersList, setTrailersList] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [organizationId, setOrganizationId] = useState("");

  // Dialog state for Add and Edit Driver
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  // State for new driver details (Add Driver)
  const [newDriver, setNewDriver] = useState({
    name: "",
    age: "",
    licenseNo: "",
    address: "",
    city: "",
    state: "",
    zip: "",
    phone: "",
    email: "",
    truckId: "", // optional
    trailerId: "", // optional
  });

  // State for editing driver details (Edit Driver)
  const [editDriver, setEditDriver] = useState({
    driverId: "",
    name: "",
    age: "",
    licenseNo: "",
    address: "",
    city: "",
    state: "",
    zip: "",
    phone: "",
    email: "",
    truckId: "",
    trailerId: "",
  });

  // Fetch drivers
  const fetchDrivers = async () => {
    const storedOrganizationId = sessionStorage.getItem(
      "storageOrganizationId"
    );
    if (storedOrganizationId) setOrganizationId(storedOrganizationId);
    setIsLoading(true);
    try {
      const response = await fetch(
        `/api/fetch-drivers?organizationId=${encodeURIComponent(
          storedOrganizationId
        )}`,
        { method: "GET", headers: { "Content-Type": "application/json" } }
      );
      if (!response.ok)
        throw new Error(`HTTP error! Status: ${response.status}`);
      const data = await response.json();
      setDriversInfo(data.result);
    } catch (error) {
      console.error("Failed to fetch Drivers information:", error);
      alert("Failed to fetch Drivers information. Please try again later.");
    } finally {
      setIsLoading(false);
    }
  };

  // Fetch trucks for dropdown
  const fetchTrucks = async () => {
    const storedOrganizationId = sessionStorage.getItem(
      "storageOrganizationId"
    );
    try {
      const response = await fetch(
        `/api/fetch-trucks?organizationId=${encodeURIComponent(
          storedOrganizationId
        )}`,
        { method: "GET", headers: { "Content-Type": "application/json" } }
      );
      if (response.ok) {
        const data = await response.json();
        setTrucksList(data.result);
      }
    } catch (error) {
      console.error("Failed to fetch Trucks list:", error);
    }
  };

  // Fetch trailers for dropdown
  const fetchTrailers = async () => {
    const storedOrganizationId = sessionStorage.getItem(
      "storageOrganizationId"
    );
    try {
      const response = await fetch(
        `/api/fetch-trailers?organizationId=${encodeURIComponent(
          storedOrganizationId
        )}`,
        { method: "GET", headers: { "Content-Type": "application/json" } }
      );
      if (response.ok) {
        const data = await response.json();
        setTrailersList(data.result);
      }
    } catch (error) {
      console.error("Failed to fetch Trailers list:", error);
    }
  };

  useEffect(() => {
    fetchDrivers();
    fetchTrucks();
    fetchTrailers();
  }, []);

  // Open modals
  const openAddModal = () => setIsAddModalOpen(true);
  const closeAddModal = () => setIsAddModalOpen(false);
  const closeEditModal = () => setIsEditModalOpen(false);

  // Handle input change for both add and edit forms
  const handleInputChange = (e, formSetter) => {
    const { name, value } = e.target;
    formSetter((prev) => ({ ...prev, [name]: value }));
  };

  // Add Driver
  const handleAddDriver = async () => {
    // Validate required fields
    if (
      !newDriver.name ||
      !newDriver.age ||
      !newDriver.licenseNo ||
      !newDriver.address ||
      !newDriver.city ||
      !newDriver.state ||
      !newDriver.zip ||
      !newDriver.phone ||
      !newDriver.email
    ) {
      alert("Please fill in all required fields.");
      return;
    }
    closeAddModal();
    setIsLoading(true);
    try {
      const response = await fetch(`/api/insert-driver`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ ...newDriver, organizationId }),
      });
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(
          errorData.error || `HTTP error! Status: ${response.status}`
        );
      }
      alert("Driver added successfully!");
      await fetchDrivers();
      // Reset newDriver state
      setNewDriver({
        name: "",
        age: "",
        licenseNo: "",
        address: "",
        city: "",
        state: "",
        zip: "",
        phone: "",
        email: "",
        truckId: "",
        trailerId: "",
      });
    } catch (error) {
      console.error("Failed to add Driver:", error);
      alert(`Error adding Driver: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  // Edit Driver
  const handleEditDriver = (driverData) => {
    setEditDriver(driverData);
    setIsEditModalOpen(true);
  };

  // Save edited Driver
  const handleSaveEditDriver = async () => {
    if (
      !editDriver.name ||
      !editDriver.age ||
      !editDriver.licenseNo ||
      !editDriver.address ||
      !editDriver.city ||
      !editDriver.state ||
      !editDriver.zip ||
      !editDriver.phone ||
      !editDriver.email
    ) {
      alert("Please fill in all required fields.");
      return;
    }
    setIsLoading(true);
    try {
      const response = await fetch(`/api/edit-driver`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ ...editDriver, organizationId }),
      });
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(
          errorData.error || `HTTP error! Status: ${response.status}`
        );
      }
      const updatedDriver = await response.json();
      setDriversInfo((prev) =>
        prev.map((driver) =>
          driver.driverId === updatedDriver.result[0].driverId
            ? updatedDriver.result[0]
            : driver
        )
      );
      alert("Driver updated successfully!");
      closeEditModal();
    } catch (error) {
      console.error("Failed to edit Driver:", error);
      alert(`Error editing Driver: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  // Delete Driver
  const handleDeleteDriver = async (driverId, name) => {
    const confirmDelete = window.confirm(
      `Are you sure you want to delete Driver: ${name}?`
    );
    if (!confirmDelete) return;
    setIsLoading(true);
    try {
      const response = await fetch(`/api/delete-driver`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ driverId }),
      });
      if (!response.ok)
        throw new Error(`HTTP error! Status: ${response.status}`);
      alert("Driver deleted successfully!");
      await fetchDrivers();
    } catch (error) {
      console.error("Failed to delete Driver:", error);
      alert(`Error deleting Driver: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  // Filtering and pagination
  const filteredDrivers = driversInfo.filter((item) => {
    const searchLower = searchTerm.toLowerCase();
    return (
      item.name?.toLowerCase().includes(searchLower) ||
      item.licenseNo?.toLowerCase().includes(searchLower) ||
      item.phone?.toLowerCase().includes(searchLower) ||
      item.email?.toLowerCase().includes(searchLower)
    );
  });

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentDrivers = filteredDrivers.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  // Find truck number by truckId
  const getTruckNumber = (truckId) => {
    const truck = trucksList.find((t) => t.truckId === truckId);
    return truck ? truck.number : "";
  };

  // Find trailer number by trailerId
  const getTrailerNumber = (trailerId) => {
    const trailer = trailersList.find((t) => t.trailerId === trailerId);
    return trailer ? trailer.number : "";
  };

  const darkTheme = createTheme({
    palette: {
      mode: "dark",
      background: { default: "#000", paper: "#000" },
      text: { primary: "#fff", secondary: "#aaa" },
      primary: { main: "#3c94fc" },
      secondary: { main: "#f50057" },
    },
    components: {
      MuiPagination: {
        styleOverrides: {
          root: { backgroundColor: "#000", padding: "16px 0" },
          ul: { justifyContent: "center" },
        },
      },
      MuiPaginationItem: {
        styleOverrides: {
          root: {
            color: "#fff",
            backgroundColor: "#000",
            "&.Mui-selected": { backgroundColor: "#3c94fc", color: "#fff" },
            "&:hover": { backgroundColor: "#111" },
          },
        },
      },
    },
  });

  return (
    <ThemeProvider theme={darkTheme}>
      <div className="chat-container">
        <SideNavBar />
        <div className="broker-window">
          <Toolbar style={{ minHeight: "83px" }} />
          <div className="broker-body">
            <Card sx={{ maxWidth: "100%", overflowX: "auto", m: 2 }}>
              <Typography
                variant="h4"
                component="div"
                sx={{
                  m: 2,
                  display: "flex",
                  justifyContent: "center",
                  textAlign: "center",
                }}
              >
                Drivers
              </Typography>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                width="100%"
              >
                <TextField
                  variant="outlined"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  placeholder="Search by Name, License, Phone, Email"
                  margin="normal"
                  sx={{
                    m: 2,
                    width: "50%",
                    input: { color: "#fff" },
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": { borderColor: "#555" },
                      "&:hover fieldset": { borderColor: "#777" },
                      "&.Mui-focused fieldset": { borderColor: "#3c94fc" },
                    },
                  }}
                  InputLabelProps={{ style: { color: "#fff" } }}
                />
                <Button
                  onClick={openAddModal}
                  variant="contained"
                  color="primary"
                  className="add-button"
                  sx={{ m: 2 }}
                  style={{
                    backgroundColor: "transparent",
                    color: "#fff",
                    border: "1px solid #3c94fc",
                    borderRadius: "15px",
                    padding: "10px 20px",
                  }}
                >
                  Add Driver
                </Button>
              </Box>
              {isLoading ? (
                <Box
                  sx={{
                    m: 2,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Spinner sx={{ color: "#3c94fc" }} />
                </Box>
              ) : (
                <TableContainer component={Paper}>
                  <Table stickyHeader aria-label="drivers table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>Age</TableCell>
                        <TableCell>License No</TableCell>
                        <TableCell>Address</TableCell>
                        <TableCell>City</TableCell>
                        <TableCell>State</TableCell>
                        <TableCell>Zip</TableCell>
                        <TableCell>Phone</TableCell>
                        <TableCell>Email</TableCell>
                        <TableCell>Truck</TableCell>
                        <TableCell>Trailer</TableCell>
                        <TableCell>Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {currentDrivers.map((driver) => (
                        <TableRow key={driver.driverId}>
                          <TableCell>{driver.name}</TableCell>
                          <TableCell>{driver.age}</TableCell>
                          <TableCell>{driver.licenseNo}</TableCell>
                          <TableCell>{driver.address}</TableCell>
                          <TableCell>{driver.city}</TableCell>
                          <TableCell>{driver.state}</TableCell>
                          <TableCell>{driver.zip}</TableCell>
                          <TableCell>{driver.phone}</TableCell>
                          <TableCell>{driver.email}</TableCell>
                          <TableCell>
                            {getTruckNumber(driver.truckId)}
                          </TableCell>
                          <TableCell>
                            {getTrailerNumber(driver.trailerId)}
                          </TableCell>
                          <TableCell>
                            <IconButton
                              onClick={() => handleEditDriver(driver)}
                              sx={{ color: "#fff" }}
                            >
                              <EditIcon />
                            </IconButton>
                            <IconButton
                              onClick={() =>
                                handleDeleteDriver(driver.driverId, driver.name)
                              }
                              sx={{ color: "#fff" }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                  <Pagination
                    count={Math.ceil(filteredDrivers.length / itemsPerPage)}
                    page={currentPage}
                    onChange={(event, value) => setCurrentPage(value)}
                    color="primary"
                    sx={{ py: 2, display: "flex", justifyContent: "center" }}
                    showFirstButton
                    showLastButton
                  />
                </TableContainer>
              )}

              {/* Add Driver Dialog with Headings */}
              <Dialog open={isAddModalOpen} onClose={closeAddModal} fullWidth>
                <DialogTitle>Add New Driver</DialogTitle>
                <DialogContent>
                  <Box
                    component="form"
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 2,
                      m: 2,
                    }}
                  >
                    <Typography variant="subtitle1" sx={{ color: "#fff" }}>
                      Name
                    </Typography>
                    <TextField
                      name="name"
                      variant="standard"
                      value={newDriver.name}
                      onChange={(e) => handleInputChange(e, setNewDriver)}
                      required
                      InputLabelProps={{ style: { color: "#fff" } }}
                    />

                    <Typography variant="subtitle1" sx={{ color: "#fff" }}>
                      Age
                    </Typography>
                    <TextField
                      name="age"
                      variant="standard"
                      value={newDriver.age}
                      onChange={(e) => handleInputChange(e, setNewDriver)}
                      required
                      InputLabelProps={{ style: { color: "#fff" } }}
                    />

                    <Typography variant="subtitle1" sx={{ color: "#fff" }}>
                      License No
                    </Typography>
                    <TextField
                      name="licenseNo"
                      variant="standard"
                      value={newDriver.licenseNo}
                      onChange={(e) => handleInputChange(e, setNewDriver)}
                      required
                      InputLabelProps={{ style: { color: "#fff" } }}
                    />

                    <Typography variant="subtitle1" sx={{ color: "#fff" }}>
                      Address
                    </Typography>
                    <TextField
                      name="address"
                      variant="standard"
                      value={newDriver.address}
                      onChange={(e) => handleInputChange(e, setNewDriver)}
                      required
                      InputLabelProps={{ style: { color: "#fff" } }}
                    />

                    <Typography variant="subtitle1" sx={{ color: "#fff" }}>
                      City
                    </Typography>
                    <TextField
                      name="city"
                      variant="standard"
                      value={newDriver.city}
                      onChange={(e) => handleInputChange(e, setNewDriver)}
                      required
                      InputLabelProps={{ style: { color: "#fff" } }}
                    />

                    <Typography variant="subtitle1" sx={{ color: "#fff" }}>
                      State
                    </Typography>
                    <TextField
                      name="state"
                      variant="standard"
                      value={newDriver.state}
                      onChange={(e) => handleInputChange(e, setNewDriver)}
                      required
                      InputLabelProps={{ style: { color: "#fff" } }}
                    />

                    <Typography variant="subtitle1" sx={{ color: "#fff" }}>
                      Zip
                    </Typography>
                    <TextField
                      name="zip"
                      variant="standard"
                      value={newDriver.zip}
                      onChange={(e) => handleInputChange(e, setNewDriver)}
                      required
                      InputLabelProps={{ style: { color: "#fff" } }}
                    />

                    <Typography variant="subtitle1" sx={{ color: "#fff" }}>
                      Phone
                    </Typography>
                    <TextField
                      name="phone"
                      variant="standard"
                      value={newDriver.phone}
                      onChange={(e) => handleInputChange(e, setNewDriver)}
                      required
                      InputLabelProps={{ style: { color: "#fff" } }}
                    />

                    <Typography variant="subtitle1" sx={{ color: "#fff" }}>
                      Email
                    </Typography>
                    <TextField
                      name="email"
                      variant="standard"
                      value={newDriver.email}
                      onChange={(e) => handleInputChange(e, setNewDriver)}
                      required
                      InputLabelProps={{ style: { color: "#fff" } }}
                    />

                    {/* Dropdown for Truck selection */}
                    <FormControl fullWidth variant="standard">
                      <InputLabel
                        id="truck-select-label"
                        sx={{ color: "#fff" }}
                      >
                        Truck (optional)
                      </InputLabel>
                      <Select
                        labelId="truck-select-label"
                        name="truckId"
                        value={newDriver.truckId}
                        onChange={(e) => handleInputChange(e, setNewDriver)}
                        label="Truck (optional)"
                        sx={{ color: "#fff" }}
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        {trucksList.map((truck) => (
                          <MenuItem key={truck.truckId} value={truck.truckId}>
                            {truck.number}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                    {/* Dropdown for Trailer selection */}
                    <FormControl fullWidth variant="standard">
                      <InputLabel
                        id="trailer-select-label"
                        sx={{ color: "#fff" }}
                      >
                        Trailer (optional)
                      </InputLabel>
                      <Select
                        labelId="trailer-select-label"
                        name="trailerId"
                        value={newDriver.trailerId}
                        onChange={(e) => handleInputChange(e, setNewDriver)}
                        label="Trailer (optional)"
                        sx={{ color: "#fff" }}
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        {trailersList.map((trailer) => (
                          <MenuItem
                            key={trailer.trailerId}
                            value={trailer.trailerId}
                          >
                            {trailer.number}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleAddDriver} color="primary">
                    Add Driver
                  </Button>
                  <IconButton
                    onClick={closeAddModal}
                    aria-label="close"
                    style={{
                      position: "absolute",
                      top: "10px",
                      right: "10px",
                      zIndex: 9000,
                      border: "2px solid red",
                    }}
                  >
                    <CloseIcon style={{ color: "red" }} />
                  </IconButton>
                </DialogActions>
              </Dialog>

              {/* Edit Driver Dialog (unchanged) */}
              <Dialog open={isEditModalOpen} onClose={closeEditModal} fullWidth>
                <DialogTitle>Edit Driver</DialogTitle>
                <DialogContent>
                  <Box
                    component="form"
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 2,
                      mt: 1,
                    }}
                  >
                    <TextField
                      label="Name"
                      name="name"
                      variant="outlined"
                      value={editDriver.name}
                      onChange={(e) => handleInputChange(e, setEditDriver)}
                      required
                      InputLabelProps={{ style: { color: "#fff" } }}
                    />
                    <TextField
                      label="Age"
                      name="age"
                      variant="outlined"
                      value={editDriver.age}
                      onChange={(e) => handleInputChange(e, setEditDriver)}
                      required
                      InputLabelProps={{ style: { color: "#fff" } }}
                    />
                    <TextField
                      label="License No"
                      name="licenseNo"
                      variant="outlined"
                      value={editDriver.licenseNo}
                      onChange={(e) => handleInputChange(e, setEditDriver)}
                      required
                      InputLabelProps={{ style: { color: "#fff" } }}
                    />
                    <TextField
                      label="Address"
                      name="address"
                      variant="outlined"
                      value={editDriver.address}
                      onChange={(e) => handleInputChange(e, setEditDriver)}
                      required
                      InputLabelProps={{ style: { color: "#fff" } }}
                    />
                    <TextField
                      label="City"
                      name="city"
                      variant="outlined"
                      value={editDriver.city}
                      onChange={(e) => handleInputChange(e, setEditDriver)}
                      required
                      InputLabelProps={{ style: { color: "#fff" } }}
                    />
                    <TextField
                      label="State"
                      name="state"
                      variant="outlined"
                      value={editDriver.state}
                      onChange={(e) => handleInputChange(e, setEditDriver)}
                      required
                      InputLabelProps={{ style: { color: "#fff" } }}
                    />
                    <TextField
                      label="Zip"
                      name="zip"
                      variant="outlined"
                      value={editDriver.zip}
                      onChange={(e) => handleInputChange(e, setEditDriver)}
                      required
                      InputLabelProps={{ style: { color: "#fff" } }}
                    />
                    <TextField
                      label="Phone"
                      name="phone"
                      variant="outlined"
                      value={editDriver.phone}
                      onChange={(e) => handleInputChange(e, setEditDriver)}
                      required
                      InputLabelProps={{ style: { color: "#fff" } }}
                    />
                    <TextField
                      label="Email"
                      name="email"
                      variant="outlined"
                      value={editDriver.email}
                      onChange={(e) => handleInputChange(e, setEditDriver)}
                      required
                      InputLabelProps={{ style: { color: "#fff" } }}
                    />
                    <FormControl fullWidth variant="outlined">
                      <InputLabel
                        id="edit-truck-select-label"
                        sx={{ color: "#fff" }}
                      >
                        Truck (optional)
                      </InputLabel>
                      <Select
                        labelId="edit-truck-select-label"
                        name="truckId"
                        value={editDriver.truckId}
                        onChange={(e) => handleInputChange(e, setEditDriver)}
                        label="Truck (optional)"
                        sx={{ color: "#fff" }}
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        {trucksList.map((truck) => (
                          <MenuItem key={truck.truckId} value={truck.truckId}>
                            {truck.number}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <FormControl fullWidth variant="outlined">
                      <InputLabel
                        id="edit-trailer-select-label"
                        sx={{ color: "#fff" }}
                      >
                        Trailer (optional)
                      </InputLabel>
                      <Select
                        labelId="edit-trailer-select-label"
                        name="trailerId"
                        value={editDriver.trailerId}
                        onChange={(e) => handleInputChange(e, setEditDriver)}
                        label="Trailer (optional)"
                        sx={{ color: "#fff" }}
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        {trailersList.map((trailer) => (
                          <MenuItem
                            key={trailer.trailerId}
                            value={trailer.trailerId}
                          >
                            {trailer.number}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={handleSaveEditDriver}
                    color="primary"
                    variant="contained"
                  >
                    Save Changes
                  </Button>
                  <Button
                    onClick={closeEditModal}
                    color="secondary"
                    variant="outlined"
                  >
                    Cancel
                  </Button>
                </DialogActions>
              </Dialog>
            </Card>
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
}

export default DriversManagement;
