import React, { useState, useEffect } from "react";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { Toolbar } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Spinner from "./Spinner";
import {
  Card,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Button,
  Box,
  Typography,
  Pagination,
  Paper,
} from "@mui/material";
import SideNavBar from "./SideNavBar";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import "./Broker.css";

function TrailersManagement() {
  const [trailersInfo, setTrailersInfo] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [organizationId, setOrganizationId] = useState("");

  // Dialog state for Add and Edit Trailer
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  // State for new trailer details (Add Trailer)
  const [newTrailer, setNewTrailer] = useState({
    number: "",
    make: "",
    model: "",
    vin: "",
    licensePlate: "",
  });

  // State for editing trailer details (Edit Trailer)
  const [editTrailer, setEditTrailer] = useState({
    trailerId: "",
    number: "",
    make: "",
    model: "",
    vin: "",
    licensePlate: "",
  });

  const openAddModal = () => setIsAddModalOpen(true);
  const closeAddModal = () => setIsAddModalOpen(false);
  const closeEditModal = () => setIsEditModalOpen(false);

  // Fetch trailers data from backend
  const fetchData = async () => {
    const storedOrganizationId = sessionStorage.getItem(
      "storageOrganizationId"
    );
    if (storedOrganizationId) setOrganizationId(storedOrganizationId);
    setIsLoading(true);
    try {
      const response = await fetch(
        `/api/fetch-trailers?organizationId=${encodeURIComponent(
          storedOrganizationId
        )}`,
        {
          method: "GET",
          headers: { "Content-Type": "application/json" },
        }
      );
      if (!response.ok)
        throw new Error(`HTTP error! Status: ${response.status}`);
      const data = await response.json();
      setTrailersInfo(data.result);
    } catch (error) {
      console.error("Failed to fetch Trailers information:", error);
      alert("Failed to fetch Trailers information. Please try again later.");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleEditTrailer = (trailerData) => {
    setEditTrailer(trailerData);
    setIsEditModalOpen(true);
  };

  // Delete trailer
  const handleDeleteTrailer = async (trailerId, number) => {
    const confirmDelete = window.confirm(
      `Are you sure you want to delete Trailer: ${number}?`
    );
    if (!confirmDelete) return;
    setIsLoading(true);
    try {
      const response = await fetch(`/api/delete-trailer`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ trailerId }),
      });
      if (!response.ok)
        throw new Error(`HTTP error! Status: ${response.status}`);
      alert("Trailer deleted successfully!");
      await fetchData();
    } catch (error) {
      console.error("Failed to delete Trailer:", error);
      alert(`Error deleting Trailer: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  // Handle input change for new trailer
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewTrailer((prev) => ({ ...prev, [name]: value }));
  };

  // Add new trailer
  const handleAddTrailer = async () => {
    if (
      !newTrailer.number ||
      !newTrailer.make ||
      !newTrailer.model ||
      !newTrailer.vin ||
      !newTrailer.licensePlate
    ) {
      alert("All fields are required.");
      return;
    }
    closeAddModal();
    setIsLoading(true);
    try {
      const response = await fetch(`/api/insert-trailer`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ ...newTrailer, organizationId }),
      });
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(
          errorData.error || `HTTP error! Status: ${response.status}`
        );
      }
      alert("Trailer added successfully!");
      await fetchData();
      setNewTrailer({
        number: "",
        make: "",
        model: "",
        vin: "",
        licensePlate: "",
      });
    } catch (error) {
      console.error("Failed to add Trailer:", error);
      alert(`Error adding Trailer: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  // Handle input change for editing trailer
  const handleEditInputChange = (e) => {
    const { name, value } = e.target;
    setEditTrailer((prev) => ({ ...prev, [name]: value }));
  };

  // Save edited trailer
  const handleSaveEditTrailer = async () => {
    if (
      !editTrailer.number ||
      !editTrailer.make ||
      !editTrailer.model ||
      !editTrailer.vin ||
      !editTrailer.licensePlate
    ) {
      alert("All fields are required.");
      return;
    }
    setIsLoading(true);
    try {
      const response = await fetch(`/api/edit-trailer`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ ...editTrailer, organizationId }),
      });
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(
          errorData.error || `HTTP error! Status: ${response.status}`
        );
      }
      const updatedTrailer = await response.json();
      setTrailersInfo((prev) =>
        prev.map((trailer) =>
          trailer.trailerId === updatedTrailer.result[0].trailerId
            ? updatedTrailer.result[0]
            : trailer
        )
      );
      alert("Trailer updated successfully!");
      closeEditModal();
    } catch (error) {
      console.error("Failed to edit Trailer:", error);
      alert(`Error editing Trailer: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  // Filter trailers based on search term
  const filteredItems = trailersInfo.filter((item) => {
    const searchLower = searchTerm.toLowerCase();
    return (
      item.number?.toLowerCase().includes(searchLower) ||
      item.make?.toLowerCase().includes(searchLower) ||
      item.model?.toLowerCase().includes(searchLower) ||
      item.vin?.toLowerCase().includes(searchLower) ||
      item.licensePlate?.toLowerCase().includes(searchLower)
    );
  });

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredItems.slice(indexOfFirstItem, indexOfLastItem);

  const darkTheme = createTheme({
    palette: {
      mode: "dark",
      background: { default: "#000000", paper: "#000000" },
      text: { primary: "#fff", secondary: "#aaa" },
      primary: { main: "#3c94fc" },
      secondary: { main: "#f50057" },
    },
    components: {
      MuiPagination: {
        styleOverrides: {
          root: { backgroundColor: "#000", padding: "16px 0" },
          ul: { justifyContent: "center" },
        },
      },
      MuiPaginationItem: {
        styleOverrides: {
          root: {
            color: "#fff",
            backgroundColor: "#000",
            "&.Mui-selected": { backgroundColor: "#3c94fc", color: "#fff" },
            "&:hover": { backgroundColor: "#111" },
          },
        },
      },
    },
  });

  return (
    <ThemeProvider theme={darkTheme}>
      <div className="chat-container">
        <SideNavBar />
        <div className="broker-window">
          <Toolbar style={{ minHeight: "83px" }} />
          <div className="broker-body">
            <Card sx={{ maxWidth: "100%", overflowX: "auto", m: 2 }}>
              <Typography
                variant="h4"
                component="div"
                sx={{
                  m: 2,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                Trailers
              </Typography>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                width="100%"
              >
                <TextField
                  variant="outlined"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  placeholder="Search by Number, Make, Model, VIN, or License Plate"
                  margin="normal"
                  sx={{
                    m: 2,
                    width: "50%",
                    input: { color: "#fff" },
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": { borderColor: "#555" },
                      "&:hover fieldset": { borderColor: "#777" },
                      "&.Mui-focused fieldset": { borderColor: "#3c94fc" },
                    },
                  }}
                  InputLabelProps={{ style: { color: "#fff" } }}
                />
                <Button
                  onClick={openAddModal}
                  variant="contained"
                  color="primary"
                  className="add-button"
                  sx={{ m: 2 }}
                  style={{
                    backgroundColor: "transparent",
                    color: "#fff",
                    border: "1px solid #3c94fc",
                    borderRadius: "15px",
                    padding: "10px 20px",
                  }}
                >
                  Add Trailer
                </Button>
              </Box>
              {isLoading ? (
                <Box
                  sx={{
                    m: 2,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  <Spinner sx={{ color: "#3c94fc" }} />
                </Box>
              ) : (
                <>
                  <TableContainer component={Paper}>
                    <Table stickyHeader aria-label="trailers table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Number</TableCell>
                          <TableCell>Make</TableCell>
                          <TableCell>Model</TableCell>
                          <TableCell>VIN</TableCell>
                          <TableCell>License Plate</TableCell>
                          <TableCell>Action</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {currentItems.map((item) => (
                          <TableRow key={item.trailerId}>
                            <TableCell>{item.number}</TableCell>
                            <TableCell>{item.make}</TableCell>
                            <TableCell>{item.model}</TableCell>
                            <TableCell>{item.vin}</TableCell>
                            <TableCell>{item.licensePlate}</TableCell>
                            <TableCell>
                              <IconButton
                                onClick={() => handleEditTrailer(item)}
                                sx={{ color: "#fff" }}
                              >
                                <EditIcon />
                              </IconButton>
                              <IconButton
                                onClick={() =>
                                  handleDeleteTrailer(
                                    item.trailerId,
                                    item.number
                                  )
                                }
                                sx={{ color: "#fff" }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                    <Pagination
                      count={Math.ceil(filteredItems.length / itemsPerPage)}
                      page={currentPage}
                      onChange={(event, value) => setCurrentPage(value)}
                      color="primary"
                      sx={{ py: 2, display: "flex", justifyContent: "center" }}
                      showFirstButton
                      showLastButton
                    />
                  </TableContainer>
                </>
              )}

              {/* Add Trailer Dialog */}
              <Dialog open={isAddModalOpen} onClose={closeAddModal} fullWidth>
                <DialogTitle>Add New Trailer</DialogTitle>
                <DialogContent>
                  {isLoading ? (
                    <Box
                      sx={{
                        m: 2,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        textAlign: "center",
                      }}
                    >
                      <Spinner sx={{ color: "#3c94fc" }} />
                    </Box>
                  ) : (
                    <Box
                      component="form"
                      sx={{ display: "flex", flexDirection: "column", m: 2 }}
                    >
                      <label htmlFor="number" className="input-label">
                        Number:
                      </label>
                      <TextField
                        name="number"
                        variant="standard"
                        value={newTrailer.number}
                        onChange={handleInputChange}
                        required
                        InputLabelProps={{ style: { color: "#fff" } }}
                        sx={{
                          input: { color: "#fff" },
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": { borderColor: "#555" },
                            "&:hover fieldset": { borderColor: "#777" },
                            "&.Mui-focused fieldset": {
                              borderColor: "#3c94fc",
                            },
                          },
                        }}
                        margin="dense"
                      />
                      <label htmlFor="make" className="input-label">
                        Make:
                      </label>
                      <TextField
                        name="make"
                        variant="standard"
                        value={newTrailer.make}
                        onChange={handleInputChange}
                        required
                        InputLabelProps={{ style: { color: "#fff" } }}
                        sx={{
                          input: { color: "#fff" },
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": { borderColor: "#555" },
                            "&:hover fieldset": { borderColor: "#777" },
                            "&.Mui-focused fieldset": {
                              borderColor: "#3c94fc",
                            },
                          },
                        }}
                        margin="dense"
                      />
                      <label htmlFor="model" className="input-label">
                        Model:
                      </label>
                      <TextField
                        name="model"
                        variant="standard"
                        value={newTrailer.model}
                        onChange={handleInputChange}
                        required
                        InputLabelProps={{ style: { color: "#fff" } }}
                        sx={{
                          input: { color: "#fff" },
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": { borderColor: "#555" },
                            "&:hover fieldset": { borderColor: "#777" },
                            "&.Mui-focused fieldset": {
                              borderColor: "#3c94fc",
                            },
                          },
                        }}
                        margin="dense"
                      />
                      <label htmlFor="vin" className="input-label">
                        VIN:
                      </label>
                      <TextField
                        name="vin"
                        variant="standard"
                        value={newTrailer.vin}
                        onChange={handleInputChange}
                        required
                        InputLabelProps={{ style: { color: "#fff" } }}
                        sx={{
                          input: { color: "#fff" },
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": { borderColor: "#555" },
                            "&:hover fieldset": { borderColor: "#777" },
                            "&.Mui-focused fieldset": {
                              borderColor: "#3c94fc",
                            },
                          },
                        }}
                        margin="dense"
                      />
                      <label htmlFor="licensePlate" className="input-label">
                        License Plate:
                      </label>
                      <TextField
                        name="licensePlate"
                        variant="standard"
                        value={newTrailer.licensePlate}
                        onChange={handleInputChange}
                        required
                        InputLabelProps={{ style: { color: "#fff" } }}
                        sx={{
                          input: { color: "#fff" },
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": { borderColor: "#555" },
                            "&:hover fieldset": { borderColor: "#777" },
                            "&.Mui-focused fieldset": {
                              borderColor: "#3c94fc",
                            },
                          },
                        }}
                        margin="dense"
                      />
                    </Box>
                  )}
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleAddTrailer} color="primary">
                    Add Trailer
                  </Button>
                  <IconButton
                    onClick={closeAddModal}
                    aria-label="close"
                    style={{
                      position: "absolute",
                      top: "10px",
                      right: "10px",
                      zIndex: 9000,
                      border: "2px solid red",
                    }}
                  >
                    <CloseIcon style={{ color: "red" }} />
                  </IconButton>
                </DialogActions>
              </Dialog>

              {/* Edit Trailer Dialog */}
              <Dialog open={isEditModalOpen} onClose={closeEditModal} fullWidth>
                <DialogTitle>Edit Trailer</DialogTitle>
                <DialogContent>
                  <Box
                    component="form"
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 2,
                      mt: 1,
                    }}
                  >
                    <TextField
                      label="Number"
                      name="number"
                      variant="outlined"
                      value={editTrailer.number}
                      onChange={handleEditInputChange}
                      required
                      InputLabelProps={{ style: { color: "#fff" } }}
                      sx={{
                        input: { color: "#fff" },
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": { borderColor: "#555" },
                          "&:hover fieldset": { borderColor: "#777" },
                          "&.Mui-focused fieldset": { borderColor: "#3c94fc" },
                        },
                      }}
                    />
                    <TextField
                      label="Make"
                      name="make"
                      variant="outlined"
                      value={editTrailer.make}
                      onChange={handleEditInputChange}
                      required
                      InputLabelProps={{ style: { color: "#fff" } }}
                      sx={{
                        input: { color: "#fff" },
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": { borderColor: "#555" },
                          "&:hover fieldset": { borderColor: "#777" },
                          "&.Mui-focused fieldset": { borderColor: "#3c94fc" },
                        },
                      }}
                    />
                    <TextField
                      label="Model"
                      name="model"
                      variant="outlined"
                      value={editTrailer.model}
                      onChange={handleEditInputChange}
                      required
                      InputLabelProps={{ style: { color: "#fff" } }}
                      sx={{
                        input: { color: "#fff" },
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": { borderColor: "#555" },
                          "&:hover fieldset": { borderColor: "#777" },
                          "&.Mui-focused fieldset": { borderColor: "#3c94fc" },
                        },
                      }}
                    />
                    <TextField
                      label="VIN"
                      name="vin"
                      variant="outlined"
                      value={editTrailer.vin}
                      onChange={handleEditInputChange}
                      required
                      InputLabelProps={{ style: { color: "#fff" } }}
                      sx={{
                        input: { color: "#fff" },
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": { borderColor: "#555" },
                          "&:hover fieldset": { borderColor: "#777" },
                          "&.Mui-focused fieldset": { borderColor: "#3c94fc" },
                        },
                      }}
                    />
                    <TextField
                      label="License Plate"
                      name="licensePlate"
                      variant="outlined"
                      value={editTrailer.licensePlate}
                      onChange={handleEditInputChange}
                      required
                      InputLabelProps={{ style: { color: "#fff" } }}
                      sx={{
                        input: { color: "#fff" },
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": { borderColor: "#555" },
                          "&:hover fieldset": { borderColor: "#777" },
                          "&.Mui-focused fieldset": { borderColor: "#3c94fc" },
                        },
                      }}
                    />
                  </Box>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={handleSaveEditTrailer}
                    color="primary"
                    variant="contained"
                  >
                    Save Changes
                  </Button>
                  <Button
                    onClick={closeEditModal}
                    color="secondary"
                    variant="outlined"
                  >
                    Cancel
                  </Button>
                </DialogActions>
              </Dialog>
            </Card>
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
}

export default TrailersManagement;
