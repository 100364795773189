import React, { useState, useEffect } from "react";
import SideNavBar from "./SideNavBar";
import { Toolbar } from "@mui/material";

function MsAuthSetup() {
  // State for user data
  const [userId, setUserId] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [tokenType, setTokenType] = useState("customsToken");
  const [message, setMessage] = useState("");

  // Adjust this if your Functions endpoint is different
  const API_BASE_URL = "/api";

  useEffect(() => {
    // Retrieve userId and companyName from sessionStorage
    const storedUserId = sessionStorage.getItem("storageUserId");
    if (storedUserId) {
      setUserId(storedUserId);
    } else {
      setMessage("No user ID found in session storage.");
    }

    const storedCompanyName = sessionStorage.getItem("storageCompanyName");
    if (storedCompanyName) {
      setCompanyName(storedCompanyName);
    }

    // Parse query params for authSuccess and email
    const urlParams = new URLSearchParams(window.location.search);
    const authSuccess = urlParams.get("authSuccess");
    const email = urlParams.get("email");
    if (authSuccess === "true" && email) {
      setMessage(`Authentication successful for email: ${email}`);
    }
  }, []);

  const handleAuthorize = async () => {
    if (!userId) {
      setMessage("No User ID available.");
      return;
    }
    setMessage("Checking token status...");

    try {
      // Build the URL
      const url = `${API_BASE_URL}/ms-auth?userId=${encodeURIComponent(
        userId
      )}&tokenType=${encodeURIComponent(
        tokenType
      )}`;

      const response = await fetch(url, {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      });

      if (!response.ok) {
        const errData = await response.json();
        setMessage(`Error: ${errData.error || "unknown error"}`);
        return;
      }

      const data = await response.json();
      if (data.status === "already_authenticated") {
        setMessage(
          `User ${userId} is already authenticated for tokenType: ${tokenType}.`
        );
      } else if (data.auth_url) {
        // Redirect to Microsoft sign-in
        window.location.href = data.auth_url;
      } else {
        setMessage(
          "Unexpected response from server; neither auth_url nor status found."
        );
      }
    } catch (error) {
      console.error(error);
      setMessage(`An error occurred: ${error.message}`);
    }
  };

  const handleRemoveToken = async () => {
    if (!userId) {
      setMessage("No User ID available for removing token.");
      return;
    }
    setMessage("Removing token...");

    try {
      // Hit the remove token endpoint
      const url = `${API_BASE_URL}/ms-auth-remove?userId=${encodeURIComponent(
        userId
      )}&tokenType=${encodeURIComponent(tokenType)}`;

      const response = await fetch(url, {
        method: "GET", // or "POST"/"DELETE" if your route is set accordingly
        headers: { "Content-Type": "application/json" },
      });

      if (!response.ok) {
        const errData = await response.json();
        setMessage(`Error removing token: ${errData.error || "unknown error"}`);
        return;
      }

      const data = await response.json();
      if (data) {
        setMessage(
          `Token for user ${userId} (${tokenType}) has been removed. You will need to re-authorize if you want to send emails again.`
        );
      } else {
        setMessage("Unexpected response from remove-token API.");
      }
    } catch (error) {
      console.error(error);
      setMessage(`An error occurred while removing token: ${error.message}`);
    }
  };

  return (
    <div className="create-invoice-container">
      <SideNavBar />
      <div className="create-invoice-window">
        <Toolbar style={{ minHeight: "83px" }} />
        <div style={{ maxWidth: 600, margin: "auto", padding: 20 }}>
          <h2>Authorize Microsoft Account</h2>
          <p>
            Use this form to authorize either the <strong>customsToken</strong>{" "}
            or <strong>accountingToken</strong> mailbox. When you click
            “Authorize,” it will check if you have a valid token; if not, you’ll
            be redirected to Microsoft sign-in.
          </p>

          {/* Display the User ID */}
          <div style={{ marginBottom: 16 }}>
            <strong>User ID:</strong> {userId ? userId : "Not available"}
          </div>

          {/* Display the Company Name */}
          <div style={{ marginBottom: 16 }}>
            <strong>Company Name:</strong>{" "}
            {companyName ? companyName : "Not available"}
          </div>

          {/* Select token type */}
          <div style={{ marginBottom: 16 }}>
            <label htmlFor="tokenType" style={{ display: "block" }}>
              <strong>Select Token Type:</strong>
            </label>
            <select
              id="tokenType"
              value={tokenType}
              onChange={(e) => setTokenType(e.target.value)}
              style={{
                width: "100%",
                padding: 8,
                fontSize: 16,
                marginTop: 4,
                boxSizing: "border-box",
              }}
            >
              <option value="customsToken">Customs Token</option>
              <option value="accountingToken">Accounting Token</option>
            </select>
          </div>

          {/* Authorize button */}
          <button
            onClick={handleAuthorize}
            style={{
              padding: "10px 15px",
              fontSize: 16,
              cursor: "pointer",
              backgroundColor: "#0078D4",
              color: "#fff",
              border: "none",
              borderRadius: 4,
              marginRight: 10,
            }}
          >
            Authorize
          </button>

          {/* Remove Token button (always visible or only if user is already authed) */}
          <button
            onClick={handleRemoveToken}
            style={{
              padding: "10px 15px",
              fontSize: 16,
              cursor: "pointer",
              backgroundColor: "#f44336", // Red color
              color: "#fff",
              border: "none",
              borderRadius: 4,
            }}
          >
            Remove Token
          </button>

          {/* Message display */}
          {message && (
            <div
              style={{
                marginTop: 20,
                padding: 10,
                border: "1px solid #ccc",
                color: "black",
                backgroundColor: "#f5f5f5",
              }}
            >
              <strong>Message:</strong> {message}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default MsAuthSetup;
