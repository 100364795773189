import React, { useState, useEffect } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import SideNavBar from "./SideNavBar";
import ConfirmationModal from "./ConfirmationModal";
import { Toolbar } from "@mui/material";
import {
  Card,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  Pagination,
  Paper,
  Box,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import Spinner from "./Spinner";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import "./Broker.css";

function BrokersList() {
  const [brokers, setBrokers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [newBroker, setNewBroker] = useState({
    name: "", // was: brokerName
    papsEmail: "",
    parsEmail: "",
    phoneNumber: "", // was: brokerPhone
  });

  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  // Keep track of which brokers are "in edit mode"
  // We'll store their brokerId in this array:
  const [editedBrokers, setEditedBrokers] = useState([]);

  // Modals
  const [isAddBrokerModalOpen, setIsAddBrokerModalOpen] = useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

  const openAddBrokerModal = () => setIsAddBrokerModalOpen(true);
  const closeAddBrokerModal = () => setIsAddBrokerModalOpen(false);

  const openConfirmationModal = () => setIsConfirmationModalOpen(true);
  const closeConfirmationModal = () => setIsConfirmationModalOpen(false);

  const confirmNewChat = () => {
    closeConfirmationModal();
  };

  // --------------------------------
  // Fetch the brokers on mount
  // --------------------------------
  useEffect(() => {
    fetchBrokers();
  }, []);

  const fetchBrokers = async () => {
    setIsLoading(true);
    try {
      const response = await fetch("/api/fetch-brokers", {
        headers: { "Content-Type": "application/json" },
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();

      // Convert the backend fields to a consistent shape in the frontend
      // Example backend record:
      // {
      //   "brokerId": 3,
      //   "name": "A&A, aa",
      //   "papsEmail": "docs@aacbusa.com",
      //   "parsEmail": "docs@aacb.com",
      //   "phoneNumber": null
      // }
      const fetchedBrokers = data.result.map((b) => ({
        brokerId: b.brokerId,
        name: b.name,
        papsEmail: b.papsEmail,
        parsEmail: b.parsEmail,
        phoneNumber: b.phoneNumber,
      }));

      setBrokers(fetchedBrokers);
    } catch (error) {
      console.error("Failed to fetch brokers:", error);
    } finally {
      setIsLoading(false);
    }
  };

  // --------------------------------
  // Searching
  // --------------------------------
  const handleSearch = (e) => {
    setSearchTerm(e.target.value.toLowerCase());
  };

  // Filter by name
  const visibleBrokers = brokers.filter((broker) =>
    broker.name.toLowerCase().includes(searchTerm)
  );

  // --------------------------------
  // Adding a Broker
  // --------------------------------
  const handleAddBroker = async (event) => {
    event.preventDefault();
    closeAddBrokerModal();
    setIsLoading(true);
    try {
      const response = await fetch("/api/add-broker", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(newBroker),
        /*
          newBroker: {
            name: "...",
            papsEmail: "...",
            parsEmail: "...",
            phoneNumber: "..."
          }
          The backend can store these accordingly.
        */
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      await fetchBrokers();
      // Reset the "newBroker" form
      setNewBroker({ name: "", papsEmail: "", parsEmail: "", phoneNumber: "" });
      alert("Broker added successfully!");
    } catch (error) {
      console.error("Error adding broker:", error);
    } finally {
      setIsLoading(false);
    }
  };

  // --------------------------------
  // Editing a Broker
  // --------------------------------
  // Called whenever the user types into an editable TextField in the table.
  const handleEditChange = (e, brokerId) => {
    const { name, value } = e.target;
    setBrokers((prev) =>
      prev.map((broker) =>
        broker.brokerId === brokerId ? { ...broker, [name]: value } : broker
      )
    );
    if (!editedBrokers.includes(brokerId)) {
      setEditedBrokers((prevEdits) => [...prevEdits, brokerId]);
    }
  };

  // Actually send changes to the backend:
  const saveChanges = async (broker) => {
    setIsLoading(true);
    try {
      const response = await fetch(`/api/update-broker`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          // Send the entire updated record to the backend
          // so it knows exactly which ID to update and with which fields.
          brokerId: broker.brokerId,
          name: broker.name,
          papsEmail: broker.papsEmail,
          parsEmail: broker.parsEmail,
          phoneNumber: broker.phoneNumber,
        }),
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      alert("Changes saved successfully!");

      // Once saved, remove that brokerId from "editedBrokers"
      setEditedBrokers((prev) => prev.filter((id) => id !== broker.brokerId));
    } catch (error) {
      console.error("Error saving changes:", error.message);
      alert(`Error saving changes: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  // --------------------------------
  // Deleting a Broker
  // --------------------------------
  const handleDeleteBroker = async (brokerId, brokerName) => {
    const confirmDelete = window.confirm(
      `Are you sure you want to delete "${brokerName}"?`
    );
    if (!confirmDelete) return;

    setIsLoading(true);
    try {
      const response = await fetch(`/api/delete-broker`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ brokerId }),
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      alert("Broker deleted successfully!");
      // Remove it from the brokers array
      setBrokers((prev) => prev.filter((b) => b.brokerId !== brokerId));
    } catch (error) {
      console.error("Error deleting broker:", error);
      alert(`Error deleting broker: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  // --------------------------------
  // Render function for editable cells
  // --------------------------------
  const renderEditableCell = (value, broker, fieldName) => {
    const isEditing = editedBrokers.includes(broker.brokerId);

    return isEditing ? (
      <TextField
        type={
          fieldName.includes("Email")
            ? "email"
            : fieldName === "phoneNumber"
            ? "tel"
            : "text"
        }
        name={fieldName}
        value={value || ""}
        onChange={(e) => handleEditChange(e, broker.brokerId)}
        autoFocus
        fullWidth
        variant="outlined"
        InputProps={{
          style: { color: "#fff" },
          endAdornment: (
            <IconButton onClick={() => saveChanges(broker)}>
              <CheckIcon color="success" />
            </IconButton>
          ),
        }}
        InputLabelProps={{
          style: { color: "#fff" },
        }}
      />
    ) : (
      value || ""
    );
  };

  // --------------------------------
  // Dark Theme
  // --------------------------------
  const darkTheme = createTheme({
    palette: {
      mode: "dark",
      background: {
        default: "#000000",
        paper: "#000000",
      },
      text: {
        primary: "#ffffff",
        secondary: "#aaaaaa",
      },
      primary: {
        main: "#3c94fc",
      },
      secondary: {
        main: "#f50057",
      },
    },
    components: {
      MuiPagination: {
        styleOverrides: {
          root: {
            backgroundColor: "#000000",
            padding: "16px 0",
          },
          ul: {
            justifyContent: "center",
          },
        },
      },
      MuiPaginationItem: {
        styleOverrides: {
          root: {
            color: "#fff",
            backgroundColor: "#000000",
            "&.Mui-selected": {
              backgroundColor: "#3c94fc",
              color: "#fff",
            },
            "&:hover": {
              backgroundColor: "#111111",
            },
          },
        },
      },
    },
  });

  // --------------------------------
  // Render
  // --------------------------------
  return (
    <ThemeProvider theme={darkTheme}>
      <div className="chat-container">
        <SideNavBar handleNewChat={openConfirmationModal} />
        <ConfirmationModal
          open={isConfirmationModalOpen}
          handleClose={closeConfirmationModal}
          handleConfirm={confirmNewChat}
          option="BrokerList"
        />
        <div className="broker-window">
          <Toolbar style={{ minHeight: "83px" }} />
          <div className="broker-body">
            <Card sx={{ maxWidth: "100%", overflowX: "auto", m: 2 }}>
              <Typography
                variant="h4"
                component="div"
                sx={{
                  m: 2,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                Broker Management
              </Typography>

              <Box sx={{ display: "flex", justifyContent: "center", p: 2 }}>
                <TextField
                  placeholder="Search by Broker Name"
                  variant="outlined"
                  value={searchTerm}
                  onChange={handleSearch}
                  sx={{
                    width: "50%",
                    input: { color: "#fff" },
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "#555",
                      },
                      "&:hover fieldset": {
                        borderColor: "#777",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#3c94fc",
                      },
                    },
                  }}
                  InputLabelProps={{
                    style: { color: "#fff" },
                  }}
                />
                <Button
                  onClick={openAddBrokerModal}
                  variant="contained"
                  className="add-button"
                  style={{
                    backgroundColor: "transparent",
                    color: "#fff",
                    border: "1px solid #3c94fc",
                    borderRadius: "15px",
                    padding: "10px 20px",
                  }}
                  sx={{ mx: 2 }}
                >
                  Add Broker
                </Button>
              </Box>

              {isLoading ? (
                <Box
                  sx={{
                    m: 2,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  <Spinner sx={{ color: "#3c94fc" }} />
                </Box>
              ) : (
                <TableContainer component={Paper}>
                  <Table stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableCell>Broker Name</TableCell>
                        <TableCell>PAPS Email</TableCell>
                        <TableCell>PARS Email</TableCell>
                        <TableCell>Phone Number</TableCell>
                        <TableCell>Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {visibleBrokers
                        .slice(
                          (currentPage - 1) * itemsPerPage,
                          currentPage * itemsPerPage
                        )
                        .map((broker) => (
                          <TableRow key={broker.brokerId}>
                            <TableCell>
                              {renderEditableCell(broker.name, broker, "name")}
                            </TableCell>

                            {/* PAPS Email */}
                            <TableCell>
                              {renderEditableCell(
                                broker.papsEmail,
                                broker,
                                "papsEmail"
                              )}
                            </TableCell>

                            {/* PARS Email */}
                            <TableCell>
                              {renderEditableCell(
                                broker.parsEmail,
                                broker,
                                "parsEmail"
                              )}
                            </TableCell>

                            {/* Phone Number */}
                            <TableCell>
                              {renderEditableCell(
                                broker.phoneNumber,
                                broker,
                                "phoneNumber"
                              )}
                            </TableCell>

                            {/* Actions */}
                            <TableCell>
                              <IconButton
                                onClick={() => {
                                  if (
                                    !editedBrokers.includes(broker.brokerId)
                                  ) {
                                    setEditedBrokers((prev) => [
                                      ...prev,
                                      broker.brokerId,
                                    ]);
                                  }
                                }}
                              >
                                <EditIcon />
                              </IconButton>

                              <IconButton
                                onClick={() =>
                                  handleDeleteBroker(
                                    broker.brokerId,
                                    broker.name
                                  )
                                }
                              >
                                <DeleteIcon />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                  <Pagination
                    count={Math.ceil(visibleBrokers.length / itemsPerPage)}
                    page={currentPage}
                    onChange={(_, page) => setCurrentPage(page)}
                    sx={{
                      py: 2,
                      display: "flex",
                      justifyContent: "center",
                    }}
                  />
                </TableContainer>
              )}

              {/* Add Broker Dialog */}
              <Dialog
                open={isAddBrokerModalOpen}
                onClose={closeAddBrokerModal}
                fullWidth
              >
                <DialogTitle>Add New Broker</DialogTitle>
                <DialogContent>
                  {isLoading ? (
                    <Box
                      sx={{
                        m: 2,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        textAlign: "center",
                      }}
                    >
                      <Spinner sx={{ color: "#3c94fc" }} />
                    </Box>
                  ) : (
                    <>
                      <label htmlFor="name" className="input-label">
                        Broker Name :
                      </label>
                      <TextField
                        fullWidth
                        variant="standard"
                        InputLabelProps={{
                          shrink: true,
                          style: { color: "#fff" },
                        }}
                        inputProps={{ style: { color: "#fff" } }}
                        value={newBroker.name}
                        onChange={(e) =>
                          setNewBroker({ ...newBroker, name: e.target.value })
                        }
                        margin="dense"
                      />

                      <label htmlFor="papsEmail" className="input-label">
                        PAPS Email :
                      </label>
                      <TextField
                        fullWidth
                        variant="standard"
                        InputLabelProps={{
                          shrink: true,
                          style: { color: "#fff" },
                        }}
                        inputProps={{ style: { color: "#fff" } }}
                        value={newBroker.papsEmail}
                        onChange={(e) =>
                          setNewBroker({
                            ...newBroker,
                            papsEmail: e.target.value,
                          })
                        }
                        margin="dense"
                      />

                      <label htmlFor="parsEmail" className="input-label">
                        PARS Email :
                      </label>
                      <TextField
                        fullWidth
                        variant="standard"
                        InputLabelProps={{
                          shrink: true,
                          style: { color: "#fff" },
                        }}
                        inputProps={{ style: { color: "#fff" } }}
                        value={newBroker.parsEmail}
                        onChange={(e) =>
                          setNewBroker({
                            ...newBroker,
                            parsEmail: e.target.value,
                          })
                        }
                        margin="dense"
                      />

                      <label htmlFor="phoneNumber" className="input-label">
                        Phone Number :
                      </label>
                      <TextField
                        fullWidth
                        variant="standard"
                        InputLabelProps={{
                          shrink: true,
                          style: { color: "#fff" },
                        }}
                        inputProps={{ style: { color: "#fff" } }}
                        value={newBroker.phoneNumber}
                        onChange={(e) =>
                          setNewBroker({
                            ...newBroker,
                            phoneNumber: e.target.value,
                          })
                        }
                        margin="dense"
                      />
                    </>
                  )}
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleAddBroker} color="primary">
                    Submit
                  </Button>
                  <IconButton
                    onClick={closeAddBrokerModal}
                    aria-label="close"
                    style={{
                      position: "absolute",
                      top: "10px",
                      right: "10px",
                      zIndex: 9000,
                      border: "2px solid red",
                    }}
                  >
                    <CloseIcon style={{ color: "red" }} />
                  </IconButton>
                </DialogActions>
              </Dialog>
            </Card>
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
}

export default BrokersList;
