import React, { useState, useEffect } from "react";
import {
  Card,
  Toolbar,
  Typography,
  Box,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  IconButton,
  Tabs,
  Tab,
  CircularProgress,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import SideNavBar from "./SideNavBar"; // Replace with your actual SideNavBar component
import "./Broker.css";

function CustomsHistory() {
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState("PAPS");
  const [customsData, setCustomsData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const darkTheme = createTheme({
    palette: {
      mode: "dark",
      background: {
        default: "#000000",
        paper: "#000000",
      },
      text: {
        primary: "#ffffff",
      },
      primary: {
        main: "#3c94fc",
      },
      secondary: {
        main: "#f50057",
      },
    },
  });

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  // Fetch data from your new "Customs" table endpoint
  const fetchCustomsData = async () => {
    setIsLoading(true);
    try {
      const orgId = sessionStorage.getItem("storageOrganizationId") || "";
      const response = await fetch(
        `/api/fetch-customs?organizationId=${encodeURIComponent(orgId)}`
      );
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      setCustomsData(data.result || []);
    } catch (error) {
      console.error("Failed to fetch Customs data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchCustomsData();
  }, []);

  // Filter data based on selected tab (type)
  const filteredData = customsData.filter(
    (item) => item.type && item.type.toUpperCase() === selectedTab
  );

  const handleEdit = (customsId) => {
    sessionStorage.setItem("editCustomsId", customsId);
    navigate(`/edit-customs`);
  };

  const handleDelete = async (customsId) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this record?"
    );
    if (!confirmDelete) return;

    try {
      setIsLoading(true);
      const response = await fetch("/api/delete-customs", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ customsId }),
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      setCustomsData((prev) =>
        prev.filter((item) => item.customsId !== customsId)
      );
      alert("Record deleted successfully!");
    } catch (error) {
      console.error("Failed to delete record:", error);
      alert(`Error deleting record: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <ThemeProvider theme={darkTheme}>
      <div className="chat-container">
        <SideNavBar />
        <div className="broker-window">
          <Toolbar style={{ minHeight: "83px" }} />
          <div className="broker-body">
            <Card sx={{ maxWidth: "100%", m: 2, p: 2 }}>
              <Typography variant="h4" align="center" gutterBottom>
                Customs History
              </Typography>

              {/* Tabs for switching between PAPS and PARS */}
              <Tabs
                value={selectedTab}
                onChange={handleTabChange}
                indicatorColor="primary"
                textColor="primary"
                centered
              >
                <Tab value="PAPS" label="PAPS" />
                <Tab value="PARS" label="PARS" />
              </Tabs>

              {isLoading ? (
                <Box
                  sx={{
                    m: 2,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <CircularProgress color="primary" />
                </Box>
              ) : (
                <TableContainer component={Paper} sx={{ mt: 2 }}>
                  <Table stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableCell>Number</TableCell>
                        <TableCell>Border</TableCell>
                        <TableCell>Broker</TableCell>
                        <TableCell>Customer</TableCell>
                        <TableCell>Customer Order #</TableCell>
                        <TableCell>Pickup Location</TableCell>
                        <TableCell>Delivery Location</TableCell>
                        <TableCell>Date</TableCell>
                        <TableCell>Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {filteredData.map((row) => (
                        <TableRow key={row.customsId}>
                          <TableCell>{row.number}</TableCell>
                          <TableCell>{row.border}</TableCell>
                          <TableCell>{row.brokerName}</TableCell>
                          <TableCell>{row.customerName}</TableCell>
                          <TableCell>{row.customerOrderNo}</TableCell>
                          <TableCell>{row.pickupLocation}</TableCell>
                          <TableCell>{row.deliveryLocation}</TableCell>
                          <TableCell>{row.date}</TableCell>
                          <TableCell>
                            <IconButton
                              onClick={() => handleEdit(row.customsId)}
                              sx={{ color: "#fff" }}
                            >
                              <EditIcon />
                            </IconButton>
                            <IconButton
                              onClick={() => handleDelete(row.customsId)}
                              sx={{ color: "#fff" }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            </Card>
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
}

export default CustomsHistory;
