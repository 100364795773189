import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  Paper,
  Select,
  Tab,
  Tabs,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  TextField,
  Toolbar,
  Typography,
  Button,
  Snackbar,
  Alert,
  Pagination,
} from "@mui/material";
// import CloseIcon from "@mui/icons-material/Close";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Spinner from "./Spinner";
import SideNavBar from "./SideNavBar";
import { useNavigate } from "react-router-dom";
import jsPDF from "jspdf";
import AddIcon from "@mui/icons-material/Add";
import "jspdf-autotable";
import "./Broker.css";

function TruckPayments() {
  // -------------------- State Variables --------------------
  // Truck & Month
  const [trucksList, setTrucksList] = useState([]);
  const [selectedTruck, setSelectedTruck] = useState("");
  const [selectedTruckNumber, setSelectedTruckNumber] = useState("");
  // Driver info for the selected truck
  const [selectedDriver, setSelectedDriver] = useState({
    driverId: "",
    name: "",
  });

  // --- For date range ---
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  // Tabs
  const [activeTab, setActiveTab] = useState(0);

  // Data
  const [truckExpenses, setTruckExpenses] = useState([]);
  const [orders, setOrders] = useState([]);

  // Loading Spinner
  const [isLoading, setIsLoading] = useState(false);

  // Dialog for editing an expense
  const [isEditExpenseModalOpen, setIsEditExpenseModalOpen] = useState(false);
  // Expense object keys to match backend: expenseId, name, price, currency
  const [editExpense, setEditExpense] = useState({
    expenseId: "",
    name: "",
    price: "",
    currency: "",
  });

  // Pay totals
  const [cadExpensesTotal, setCadExpensesTotal] = useState("");
  const [usdExpensesTotal, setUsdExpensesTotal] = useState("");
  const [cadLoadsTotal, setCadLoadsTotal] = useState("");
  const [usdLoadsTotal, setUsdLoadsTotal] = useState("");
  const [cadPay, setCadPay] = useState("");
  const [usdPay, setUsdPay] = useState("");
  const [finalPay, setFinalPay] = useState("");
  const [finalPayCurrency, setFinalPayCurrency] = useState("");
  const [finalPayExplanation, setFinalPayExplanation] = useState("");

  // -- Company info states (from sessionStorage) --
  const [userCompanyName, setUserCompanyName] = useState("");
  const [userCompanyLogo, setUserCompanyLogo] = useState("");
  const [userAddress, setUserAddress] = useState("");
  const [userCity, setUserCity] = useState("");
  const [userState, setUserState] = useState("");
  const [userZip, setUserZip] = useState("");
  const [userPhone, setUserPhone] = useState("");
  const [userCustomsEmail, setUserCustomsEmail] = useState("");
  const [userCompanyWebsite, setUserCompanyWebsite] = useState("");
  const [organizationId, setOrganizationId] = useState("");

  // Expense dialog state
  const [isAddExpenseModalOpen, setIsAddExpenseModalOpen] = useState(false);
  const [newExpense, setNewExpense] = useState({
    name: "",
    price: "",
    currency: "",
  });

  // Snackbar for notifications
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });

  const navigate = useNavigate();

  // -------------------- Search & Pagination --------------------
  // Expenses Tab
  const [expenseSearchTerm, setExpenseSearchTerm] = useState("");
  const [filteredExpenses, setFilteredExpenses] = useState([]);
  const [currentExpensePage, setCurrentExpensePage] = useState(1);
  const expensesPerPage = 10;

  // Orders Tab
  const [orderSearchTerm, setOrderSearchTerm] = useState("");
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [currentOrderPage, setCurrentOrderPage] = useState(1);
  const ordersPerPage = 10;

  // -------------------- Dark Theme --------------------
  const darkTheme = createTheme({
    palette: {
      mode: "dark",
      background: { default: "#000000", paper: "#000000" },
      text: { primary: "#ffffff", secondary: "#aaaaaa" },
      primary: { main: "#3c94fc" },
      secondary: { main: "#f50057" },
    },
    components: {
      MuiPagination: {
        styleOverrides: {
          root: { backgroundColor: "#000000", padding: "16px 0" },
          ul: { justifyContent: "center" },
        },
      },
      MuiPaginationItem: {
        styleOverrides: {
          root: {
            color: "#fff",
            backgroundColor: "#000000",
            "&.Mui-selected": { backgroundColor: "#3c94fc", color: "#fff" },
            "&:hover": { backgroundColor: "#111111" },
          },
        },
      },
    },
  });

  // -------------------- Helper: Input Changes --------------------
  const handleInputChange = (e, formSetter) => {
    const { name, value } = e.target;
    formSetter((prev) => ({ ...prev, [name]: value }));
  };

  const closeAddExpenseModal = () => {
    setIsAddExpenseModalOpen(false);
    setNewExpense({ name: "", price: "", currency: "" });
  };

  // -------------------- useEffect: Load Company Info --------------------
  useEffect(() => {
    const storedCompanyName = sessionStorage.getItem("storageCompanyName");
    const storedCompanyLogo = sessionStorage.getItem("storageCompanyLogo");
    const storedAddress = sessionStorage.getItem("storageAddress");
    const storedCity = sessionStorage.getItem("storageCity");
    const storedState = sessionStorage.getItem("storageState");
    const storedZip = sessionStorage.getItem("storageZip");
    const storedPhone = sessionStorage.getItem("storagePhone");
    const storedCustomsEmail = sessionStorage.getItem("storageCustomsEmail");
    const storedCompanyWebsite = sessionStorage.getItem(
      "storageCompanyWebsite"
    );
    const storedOrganizationId = sessionStorage.getItem(
      "storageOrganizationId"
    );

    if (storedCompanyName) setUserCompanyName(storedCompanyName);
    if (storedCompanyLogo) setUserCompanyLogo(storedCompanyLogo);
    if (storedAddress) setUserAddress(storedAddress);
    if (storedCity) setUserCity(storedCity);
    if (storedState) setUserState(storedState);
    if (storedZip) setUserZip(storedZip);
    if (storedPhone) setUserPhone(storedPhone);
    if (storedCustomsEmail) setUserCustomsEmail(storedCustomsEmail);
    if (storedCompanyWebsite) setUserCompanyWebsite(storedCompanyWebsite);
    if (storedOrganizationId) setOrganizationId(storedOrganizationId);
  }, []);

  // -------------------- Fetch Truck List (on mount) --------------------
  useEffect(() => {
    const fetchTrucksList = async () => {
      setIsLoading(true);
      try {
        const storedOrganizationId = sessionStorage.getItem(
          "storageOrganizationId"
        );
        const response = await fetch(
          `/api/fetch-trucks?organizationId=${encodeURIComponent(
            storedOrganizationId
          )}`,
          {
            method: "GET",
            headers: { "Content-Type": "application/json" },
          }
        );
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        setTrucksList(data.result || []);
      } catch (error) {
        console.error("Failed to fetch trucks list:", error);
        setSnackbar({
          open: true,
          message: "Failed to fetch trucks. Please try again later.",
          severity: "error",
        });
      } finally {
        setIsLoading(false);
      }
    };
    fetchTrucksList();
  }, []);

  // -------------------- Fetch Expenses --------------------
  const fetchExpenses = useCallback(
    async (truckId) => {
      if (!truckId) {
        setTruckExpenses([]);
        setFilteredExpenses([]);
        return;
      }
      setIsLoading(true);
      try {
        const response = await fetch(
          `/api/fetch-expenses?truckId=${encodeURIComponent(truckId)}`,
          {
            method: "GET",
            headers: { "Content-Type": "application/json" },
          }
        );
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        setTruckExpenses(data.result || []);
        setFilteredExpenses(data.result || []);
        setExpenseSearchTerm("");
        setCurrentExpensePage(1);
      } catch (error) {
        console.error("Failed to fetch expenses:", error);
        setSnackbar({
          open: true,
          message: "Failed to fetch expenses. Please try again later.",
          severity: "error",
        });
      } finally {
        setIsLoading(false);
      }
    },
    [setSnackbar]
  );

  // -------------------- Fetch Truck Orders --------------------
  /**
   * This function fetches the orders for the selected truck (by truckId)
   * regardless of date range. It’s called whenever the user selects a different truck.
   */
  const fetchTruckOrders = async (truckId) => {
    if (!truckId) {
      setOrders([]);
      setFilteredOrders([]);
      return;
    }
    try {
      const response = await fetch(
        `/api/fetch-truck-orders?truckId=${encodeURIComponent(truckId)}`
      );
      const data = await response.json();
      // data.result is an array of orders

      // --- Transform each order ---
      const transformed = data.result.map((order) => {
        const pickupFirst = order.pickupLocation?.[0] || {};
        const deliveryFirst = order.deliveryLocation?.[0] || {};

        return {
          ...order,
          PickupDate: order.pickupDate,
          PickupLocation: `${pickupFirst.city || ""}, ${
            pickupFirst.state || ""
          }`,
          DeliveryLocation: `${deliveryFirst.city || ""}, ${
            deliveryFirst.state || ""
          }`,
          PriceKept: order.priceGiven,
          Currency: order.currency,
        };
      });

      setOrders(transformed);
      setFilteredOrders(transformed);
      setOrderSearchTerm("");
      setCurrentOrderPage(1);
    } catch (err) {
      console.error("Error fetching truck orders:", err);
    }
  };

  // Whenever `selectedTruck` changes, fetch BOTH expenses and orders
  useEffect(() => {
    fetchExpenses(selectedTruck);
    fetchTruckOrders(selectedTruck);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTruck]);

  // -------------------- Fetch Orders by Date Range --------------------
  useEffect(() => {
    if (!selectedTruckNumber || !startDate || !endDate) {
      setOrders([]);
      setFilteredOrders([]);
      setOrderSearchTerm("");
      setCurrentOrderPage(1);
      return;
    }
    const fetchOrdersInRange = async () => {
      setIsLoading(true);
      try {
        const response = await fetch(
          `/api/fetch-loads-range-truck?truckId=${encodeURIComponent(
            selectedTruckNumber
          )}&startDate=${encodeURIComponent(
            startDate
          )}&endDate=${encodeURIComponent(endDate)}`,
          {
            method: "GET",
            headers: { "Content-Type": "application/json" },
          }
        );
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        setOrders(data.result || []);
        setFilteredOrders(data.result || []);
        setOrderSearchTerm("");
        setCurrentOrderPage(1);
      } catch (error) {
        console.error("Failed to fetch orders by date range:", error);
        setSnackbar({
          open: true,
          message: "Failed to fetch orders. Please try again later.",
          severity: "error",
        });
      } finally {
        setIsLoading(false);
      }
    };
    fetchOrdersInRange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTruckNumber, startDate, endDate]);

  // -------------------- Fetch Driver for Selected Truck --------------------
  const fetchDriverForTruck = async (truckId) => {
    if (!truckId) {
      setSelectedDriver({ driverId: "", name: "" });
      return;
    }
    try {
      const response = await fetch(
        `/api/fetch-driver-by-truck?truckId=${encodeURIComponent(truckId)}`,
        { method: "GET", headers: { "Content-Type": "application/json" } }
      );
      if (response.ok) {
        const data = await response.json();
        if (data.result && data.result.length > 0) {
          setSelectedDriver({
            driverId: data.result[0].driverId,
            name: data.result[0].name,
          });
        } else {
          setSelectedDriver({ driverId: "", name: "" });
        }
      } else {
        console.error("Failed to fetch driver for truck, response not OK");
      }
    } catch (error) {
      console.error("Error fetching driver for truck:", error);
    }
  };

  // -------------------- Handlers --------------------
  const handleChangeTruck = (event) => {
    const truckId = Number(event.target.value);
    setSelectedTruck(truckId);
    const truckObj = trucksList.find((t) => t.truckId === truckId);
    if (truckObj) {
      setSelectedTruckNumber(truckObj.number);
    } else {
      setSelectedTruckNumber("");
    }
    fetchDriverForTruck(truckId);
  };

  const handleStartDateChange = (event) => setStartDate(event.target.value);
  const handleEndDateChange = (event) => setEndDate(event.target.value);
  const handleTabChange = (event, newValue) => setActiveTab(newValue);

  const handleEditOrder = (orderData) => {
    sessionStorage.setItem("orderData", JSON.stringify(orderData));
    navigate("/edit-order");
  };

  const handleEditExpenseClick = (expense) => {
    setEditExpense({
      expenseId: expense.expenseId,
      name: expense.name,
      price: expense.price,
      currency: expense.currency,
    });
    setIsEditExpenseModalOpen(true);
  };

  const handleDeleteExpenseClick = async (expense) => {
    const confirmDelete = window.confirm(
      `Are you sure you want to delete expense: ${expense.name}?`
    );
    if (!confirmDelete) return;
    setIsLoading(true);
    try {
      const response = await fetch(`/api/delete-expense`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ expenseId: expense.expenseId }),
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      // Remove from local state
      setTruckExpenses((prev) =>
        prev.filter((exp) => exp.expenseId !== expense.expenseId)
      );
      setFilteredExpenses((prev) =>
        prev.filter((exp) => exp.expenseId !== expense.expenseId)
      );
      setSnackbar({
        open: true,
        message: "Expense deleted successfully!",
        severity: "success",
      });
    } catch (error) {
      console.error("Failed to delete Expense:", error);
      setSnackbar({
        open: true,
        message: `Error deleting Expense: ${error.message}`,
        severity: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleEditExpenseInputChange = (e) => {
    const { name, value } = e.target;
    setEditExpense((prev) => ({ ...prev, [name]: value }));
  };

  const closeEditExpenseModal = () => {
    setIsEditExpenseModalOpen(false);
    setEditExpense({ expenseId: "", name: "", price: "", currency: "" });
  };

  const handleSaveNewExpense = async () => {
    closeAddExpenseModal();
    if (
      !newExpense.name.trim() ||
      !newExpense.price.toString().trim() ||
      !newExpense.currency.trim()
    ) {
      setSnackbar({
        open: true,
        message: "All fields (Expense Name, Price, Currency) are required.",
        severity: "warning",
      });
      return;
    }
    setIsLoading(true);
    try {
      const response = await fetch(`/api/add-expense`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          type: "Truck",
          name: newExpense.name,
          price: parseFloat(newExpense.price),
          currency: newExpense.currency,
          truckId: selectedTruck,
          driverId: selectedDriver.driverId,
          organizationId: organizationId,
        }),
      });
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(
          errorData.error || `HTTP error! Status: ${response.status}`
        );
      }
      // Refresh expenses
      await fetchExpenses(selectedTruck);
      setSnackbar({
        open: true,
        message: "Expense added successfully!",
        severity: "success",
      });
      setNewExpense({ name: "", price: "", currency: "" });
    } catch (error) {
      console.error("Failed to add expense:", error);
      setSnackbar({
        open: true,
        message: `Error adding expense: ${error.message}`,
        severity: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleSaveEditExpense = async () => {
    if (
      !editExpense.name.trim() ||
      !editExpense.price.toString().trim() ||
      !editExpense.currency.trim()
    ) {
      setSnackbar({
        open: true,
        message: "All fields are required to edit this expense.",
        severity: "warning",
      });
      return;
    }
    closeEditExpenseModal();
    setIsLoading(true);
    try {
      const response = await fetch(`/api/edit-expense`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          expenseId: editExpense.expenseId,
          type: "Truck",
          name: editExpense.name,
          price: parseFloat(editExpense.price),
          currency: editExpense.currency,
          truckId: selectedTruck,
          organizationId: organizationId,
        }),
      });
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(
          errorData.error || `HTTP error! Status: ${response.status}`
        );
      }
      await fetchExpenses(selectedTruck);
      setSnackbar({
        open: true,
        message: "Expense updated successfully!",
        severity: "success",
      });
    } catch (error) {
      console.error("Failed to edit expense:", error);
      setSnackbar({
        open: true,
        message: `Error editing expense: ${error.message}`,
        severity: "error",
      });
    } finally {
      setIsLoading(false);
      setEditExpense({ expenseId: "", name: "", price: "", currency: "" });
    }
  };

  // -------------------- Searching & Filtering --------------------
  const handleExpenseSearchChange = (e) => {
    const term = e.target.value;
    setExpenseSearchTerm(term);
    if (term.trim() === "") {
      setFilteredExpenses(truckExpenses);
    } else {
      const lowerTerm = term.toLowerCase();
      const filtered = truckExpenses.filter((expense) =>
        Object.values(expense).some((val) =>
          String(val).toLowerCase().includes(lowerTerm)
        )
      );
      setFilteredExpenses(filtered);
      setCurrentExpensePage(1);
    }
  };

  const handleOrderSearchChange = (e) => {
    const term = e.target.value;
    setOrderSearchTerm(term);
    if (term.trim() === "") {
      setFilteredOrders(orders);
    } else {
      const lowerTerm = term.toLowerCase();
      const filtered = orders.filter((order) =>
        Object.values(order).some((val) =>
          String(val).toLowerCase().includes(lowerTerm)
        )
      );
      setFilteredOrders(filtered);
      setCurrentOrderPage(1);
    }
  };

  // -------------------- Calculations --------------------
  const handleCalculateAll = () => {
    setFinalPay("");
    setFinalPayCurrency("");
    setFinalPayExplanation("");

    // Sum up all CAD/USD from expenses
    const cadExp = truckExpenses
      .filter((exp) => exp.currency === "CAD")
      .reduce((acc, exp) => acc + parseFloat(exp.price || 0), 0);
    setCadExpensesTotal(cadExp.toFixed(2));

    const usdExp = truckExpenses
      .filter((exp) => exp.currency === "USD")
      .reduce((acc, exp) => acc + parseFloat(exp.price || 0), 0);
    setUsdExpensesTotal(usdExp.toFixed(2));

    // Sum up all CAD/USD from orders
    const cadLd = orders
      .filter((ord) => ord.Currency === "CAD")
      .reduce((acc, ord) => acc + parseFloat(ord.PriceKept || 0), 0);
    setCadLoadsTotal(cadLd.toFixed(2));

    const usdLd = orders
      .filter((ord) => ord.Currency === "USD")
      .reduce((acc, ord) => acc + parseFloat(ord.PriceKept || 0), 0);
    setUsdLoadsTotal(usdLd.toFixed(2));

    // Calculate net pay in each currency
    const newCadPay = cadLd - cadExp;
    const newUsdPay = usdLd - usdExp;
    setCadPay(newCadPay.toFixed(2));
    setUsdPay(newUsdPay.toFixed(2));

    // If one currency is negative and the other positive, prompt user for conversion
    if (newCadPay < 0 && newUsdPay > 0) {
      const rateInput = window.prompt(
        "CAD Pay is negative but you have positive USD. Enter CAD→USD conversion rate (e.g. 0.75):"
      );
      if (!rateInput) return;
      const rate = parseFloat(rateInput);
      if (isNaN(rate) || rate <= 0) {
        setSnackbar({
          open: true,
          message: "Invalid conversion rate.",
          severity: "error",
        });
        return;
      }
      const negativeCadInUsd = newCadPay * rate;
      const finalUsd = newUsdPay + negativeCadInUsd;
      setFinalPay(finalUsd.toFixed(2));
      setFinalPayCurrency("USD");
      setFinalPayExplanation(
        `We had negative CAD pay of ${newCadPay.toFixed(
          2
        )}.\nConverted to USD using rate ${rate.toFixed(
          2
        )} => (${newCadPay.toFixed(2)} × ${rate.toFixed(
          2
        )}) = ${negativeCadInUsd.toFixed(
          2
        )}.\nThen we add your positive USD pay of ${newUsdPay.toFixed(
          2
        )}, resulting in final USD pay: ${finalUsd.toFixed(2)}.`
      );
    } else if (newUsdPay < 0 && newCadPay > 0) {
      const rateInput = window.prompt(
        "USD Pay is negative but you have positive CAD. Enter USD→CAD conversion rate (e.g. 1.33):"
      );
      if (!rateInput) return;
      const rate = parseFloat(rateInput);
      if (isNaN(rate) || rate <= 0) {
        setSnackbar({
          open: true,
          message: "Invalid conversion rate.",
          severity: "error",
        });
        return;
      }
      const negativeUsdInCad = newUsdPay * rate;
      const finalCad = newCadPay + negativeUsdInCad;
      setFinalPay(finalCad.toFixed(2));
      setFinalPayCurrency("CAD");
      setFinalPayExplanation(
        `We had negative USD pay of ${newUsdPay.toFixed(
          2
        )}.\nConverted to CAD using rate ${rate.toFixed(
          2
        )} => (${newUsdPay.toFixed(2)} × ${rate.toFixed(
          2
        )}) = ${negativeUsdInCad.toFixed(
          2
        )}.\nThen we add your positive CAD pay of ${newCadPay.toFixed(
          2
        )}, resulting in final CAD pay: ${finalCad.toFixed(2)}.`
      );
    }
  };

  // -------------------- PDF Helper Functions --------------------
  const convertLogoToBase64 = async (logoUrl) => {
    if (!logoUrl) return null;
    try {
      const response = await fetch(logoUrl, { mode: "cors" });
      const blob = await response.blob();
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      });
    } catch (error) {
      console.error("Error loading company logo:", error);
      return null;
    }
  };

  const addCompanyHeader = async (doc) => {
    let logoBase64Data = await convertLogoToBase64(userCompanyLogo);
    const pageWidth = doc.internal.pageSize.getWidth();
    const marginLeft = 40;
    const marginRight = 40;
    let yPosition = 50;
    const logoWidth = 140;
    const logoHeight = 60;

    if (logoBase64Data) {
      doc.addImage(
        logoBase64Data,
        "PNG",
        pageWidth - marginRight - logoWidth,
        yPosition,
        logoWidth,
        logoHeight
      );
    }
    doc.setFont("helvetica", "bold");
    doc.setFontSize(16);
    doc.setTextColor(0);
    doc.text(`${userCompanyName || ""}`, marginLeft, yPosition + 20);

    doc.setFont("helvetica", "normal");
    doc.setFontSize(11);
    let headerYPos = yPosition + 40;
    if (userAddress) {
      doc.text(userAddress, marginLeft, headerYPos);
      headerYPos += 12;
    }
    if ((userCity && userState) || userZip) {
      doc.text(`${userCity}, ${userState} ${userZip}`, marginLeft, headerYPos);
      headerYPos += 12;
    }
    if (userPhone) {
      doc.setFont("helvetica", "bold");
      doc.text("Phone:", marginLeft, headerYPos);
      doc.setFont("helvetica", "normal");
      doc.text(userPhone, marginLeft + 50, headerYPos);
      headerYPos += 12;
    }
    if (userCustomsEmail) {
      doc.setFont("helvetica", "bold");
      doc.text("Email:", marginLeft, headerYPos);
      doc.setFont("helvetica", "italic");
      doc.text(userCustomsEmail, marginLeft + 50, headerYPos);
      headerYPos += 12;
    }
    if (userCompanyWebsite) {
      doc.setFont("helvetica", "bold");
      doc.text("Website:", marginLeft, headerYPos);
      doc.setFont("helvetica", "italic");
      doc.text(userCompanyWebsite, marginLeft + 60, headerYPos);
      headerYPos += 12;
    }
    const logoBottom = yPosition + logoHeight;
    headerYPos = Math.max(headerYPos, logoBottom);
    doc.setLineWidth(1);
    doc.setDrawColor(0);
    doc.line(
      marginLeft,
      headerYPos + 10,
      pageWidth - marginRight,
      headerYPos + 10
    );
    return headerYPos + 30;
  };

  const createPdfHeading = (doc, title, startY) => {
    doc.setFont("helvetica", "bold");
    doc.setFontSize(18);
    doc.setTextColor(0);
    const pageWidth = doc.internal.pageSize.getWidth();
    doc.text(title, pageWidth / 2, startY, { align: "center" });
    doc.setLineWidth(1);
    doc.setDrawColor(0);
    doc.line(40, startY + 10, pageWidth - 40, startY + 10);
    doc.setFont("helvetica", "normal");
    doc.setFontSize(11);
    return startY + 50;
  };

  // Updated function to also print driver name:
  const printTruckAndMonth = (doc, yPos) => {
    // Truck Number
    {
      doc.setFont("helvetica", "bold");
      const label1 = "Truck Number: ";
      const label1X = 40;
      doc.text(label1, label1X, yPos);
      const label1Width = doc.getTextWidth(label1);
      doc.setFont("helvetica", "normal");
      const truckNumX = label1X + label1Width;
      doc.text(`${selectedTruckNumber}`, truckNumX, yPos);
      yPos += 20;
    }

    // Driver Name
    {
      doc.setFont("helvetica", "bold");
      const labelDriver = "Driver: ";
      const labelDriverX = 40;
      doc.text(labelDriver, labelDriverX, yPos);
      const labelDriverWidth = doc.getTextWidth(labelDriver);
      doc.setFont("helvetica", "normal");
      const driverX = labelDriverX + labelDriverWidth;
      doc.text(`${selectedDriver?.name || ""}`, driverX, yPos);
      yPos += 20;
    }

    // Period
    {
      doc.setFont("helvetica", "bold");
      const label2 = "Period: ";
      const label2X = 40;
      doc.text(label2, label2X, yPos);
      const label2Width = doc.getTextWidth(label2);
      doc.setFont("helvetica", "normal");
      const startDateX = label2X + label2Width;
      doc.text(`${startDate}`, startDateX, yPos);
      const startDateWidth = doc.getTextWidth(`${startDate}`);
      doc.setFont("helvetica", "bold");
      const toText = " To ";
      const toX = startDateX + startDateWidth;
      doc.text(toText, toX, yPos);
      const toWidth = doc.getTextWidth(toText);
      doc.setFont("helvetica", "normal");
      const endDateX = toX + toWidth + 2;
      doc.text(`${endDate}`, endDateX, yPos);
      yPos += 20;
    }
    return yPos;
  };

  const addFooterNote = (doc) => {
    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();
    doc.setLineWidth(0.5);
    doc.setDrawColor(0);
    doc.line(40, pageHeight - 40, pageWidth - 40, pageHeight - 40);
    doc.setFontSize(9);
    doc.setTextColor(100);
    doc.text("Generated by LoadMinds ©", 40, pageHeight - 25);
    doc.setTextColor(0);
  };

  // -------------------- PDF Download: Expenses --------------------
  const handleDownloadExpensesPDF = async () => {
    const doc = new jsPDF("p", "pt", "a4");
    let nextY = await addCompanyHeader(doc);
    nextY = createPdfHeading(doc, "Expenses Report", nextY);
    nextY = printTruckAndMonth(doc, nextY);

    const columns = [
      { header: "Name", dataKey: "name" },
      { header: "Price", dataKey: "Price" },
      { header: "Currency", dataKey: "Currency" },
    ];
    const rows = truckExpenses.map((exp) => ({
      name: exp.name,
      Price: exp.price,
      Currency: exp.currency,
    }));
    const totalExpensesCAD = truckExpenses
      .filter((exp) => exp.currency === "CAD")
      .reduce((acc, exp) => acc + parseFloat(exp.price || 0), 0);
    const totalExpensesUSD = truckExpenses
      .filter((exp) => exp.currency === "USD")
      .reduce((acc, exp) => acc + parseFloat(exp.price || 0), 0);

    doc.autoTable({
      columns,
      body: rows,
      startY: nextY,
      margin: { left: 40, right: 40 },
      styles: {
        fontSize: 10,
        font: "helvetica",
        cellPadding: 6,
        lineColor: [0, 0, 0],
        lineWidth: 0.1,
        textColor: [0, 0, 0],
      },
      headStyles: {
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
        lineColor: [0, 0, 0],
        lineWidth: 0.1,
        fontStyle: "bold",
      },
      alternateRowStyles: {},
    });

    let summaryY = doc.lastAutoTable.finalY + 30;
    doc.setFont("helvetica", "bold");
    doc.setFontSize(12);
    doc.text(
      `Total Expenses (CAD): ${totalExpensesCAD.toFixed(2)}`,
      40,
      summaryY
    );
    summaryY += 24;
    doc.text(
      `Total Expenses (USD): ${totalExpensesUSD.toFixed(2)}`,
      40,
      summaryY
    );
    addFooterNote(doc);
    const fileName = `Expenses_${selectedTruckNumber || "NoTruck"}.pdf`;
    doc.save(fileName);
  };

  // -------------------- PDF Download: Orders --------------------
  const handleDownloadOrdersPDF = async () => {
    const doc = new jsPDF("p", "pt", "a4");
    let nextY = await addCompanyHeader(doc);
    nextY = createPdfHeading(doc, "Orders Report", nextY);
    nextY = printTruckAndMonth(doc, nextY);

    const columns = [
      { header: "Pickup Date", dataKey: "PickupDate" },
      { header: "Pickup Location", dataKey: "PickupLocation" },
      { header: "Delivery Location", dataKey: "DeliveryLocation" },
      { header: "Price", dataKey: "PriceKept" },
      { header: "Currency", dataKey: "Currency" },
    ];
    const rows = orders.map((ord) => ({
      PickupDate: ord.PickupDate,
      PickupLocation: ord.PickupLocation,
      DeliveryLocation: ord.DeliveryLocation,
      PriceKept: ord.PriceKept,
      Currency: ord.Currency,
    }));
    const totalOrdersCAD = orders
      .filter((ord) => ord.Currency === "CAD")
      .reduce((acc, ord) => acc + parseFloat(ord.PriceKept || 0), 0);
    const totalOrdersUSD = orders
      .filter((ord) => ord.Currency === "USD")
      .reduce((acc, ord) => acc + parseFloat(ord.PriceKept || 0), 0);

    doc.autoTable({
      columns,
      body: rows,
      startY: nextY,
      margin: { left: 40, right: 40 },
      styles: {
        fontSize: 10,
        font: "helvetica",
        cellPadding: 6,
        lineColor: [0, 0, 0],
        lineWidth: 0.1,
        textColor: [0, 0, 0],
      },
      headStyles: {
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
        lineColor: [0, 0, 0],
        lineWidth: 0.1,
        fontStyle: "bold",
      },
      alternateRowStyles: {},
    });

    let summaryY = doc.lastAutoTable.finalY + 30;
    doc.setFont("helvetica", "bold");
    doc.setFontSize(12);
    doc.text(`Total Freight (CAD): ${totalOrdersCAD.toFixed(2)}`, 40, summaryY);
    summaryY += 24;
    doc.text(`Total Freight (USD): ${totalOrdersUSD.toFixed(2)}`, 40, summaryY);

    addFooterNote(doc);
    const fileName = `Orders_${selectedTruckNumber || "NoTruck"}.pdf`;
    doc.save(fileName);
  };

  // -------------------- PDF Download: Pay --------------------
  const handleDownloadPayPDF = async () => {
    const doc = new jsPDF("p", "pt", "a4");
    let nextY = await addCompanyHeader(doc);
    nextY = createPdfHeading(doc, "Pay Summary", nextY);
    nextY = printTruckAndMonth(doc, nextY);

    const payColumns = [
      { header: "", dataKey: "labelLeft" },
      { header: "", dataKey: "valueLeft" },
      { header: "", dataKey: "labelRight" },
      { header: "", dataKey: "valueRight" },
    ];
    const payRows = [
      {
        labelLeft: "CAD Loads",
        valueLeft: cadLoadsTotal,
        labelRight: "USD Loads",
        valueRight: usdLoadsTotal,
      },
      {
        labelLeft: "CAD Expenses",
        valueLeft: cadExpensesTotal,
        labelRight: "USD Expenses",
        valueRight: usdExpensesTotal,
      },
      {
        labelLeft: "CAD Pay",
        valueLeft: cadPay,
        labelRight: "USD Pay",
        valueRight: usdPay,
      },
    ];

    doc.autoTable({
      startY: nextY,
      margin: { left: 40, right: 40 },
      styles: {
        fontSize: 10,
        font: "helvetica",
        cellPadding: 6,
        lineColor: [0, 0, 0],
        lineWidth: 0.1,
        textColor: [0, 0, 0],
      },
      bodyStyles: { textColor: [0, 0, 0] },
      head: [],
      columns: payColumns,
      body: payRows.map((row) => [
        row.labelLeft,
        row.valueLeft,
        row.labelRight,
        row.valueRight,
      ]),
      alternateRowStyles: {},
      didParseCell: (hookData) => {
        // Highlight the row that shows "CAD Pay" & "USD Pay"
        if (hookData.section === "body" && hookData.row.index === 2) {
          hookData.cell.styles.fillColor = [220, 220, 220];
          hookData.cell.styles.fontStyle = "bold";
        }
      },
    });

    let newY = doc.lastAutoTable.finalY + 20;
    // If a final single-currency pay was computed:
    if (finalPay) {
      doc.setDrawColor(0);
      doc.setLineWidth(1);
      const boxX = 40;
      const boxY = newY;
      const boxWidth = doc.internal.pageSize.getWidth() - 80;
      const boxHeight = 100;
      doc.rect(boxX, boxY, boxWidth, boxHeight);

      doc.setFont("helvetica", "bold");
      doc.setFontSize(12);
      doc.setTextColor(0);
      doc.text("Final Pay", boxX + 15, boxY + 25);

      doc.setFont("helvetica", "normal");
      doc.setFontSize(11);
      doc.setTextColor(0);
      doc.text(`${finalPay} ${finalPayCurrency}`, boxX + 15, boxY + 45);

      if (finalPayExplanation) {
        doc.setTextColor(120);
        const explanationLines = doc.splitTextToSize(
          finalPayExplanation,
          boxWidth - 30
        );
        doc.text(explanationLines, boxX + 15, boxY + 65);
        doc.setTextColor(0);
      }
    }

    addFooterNote(doc);
    const fileName = `PaySummary_${selectedTruckNumber || "NoTruck"}.pdf`;
    doc.save(fileName);
  };

  // -------------------- Render UI --------------------
  const renderTruckAndDriverSelect = () => (
    <Box sx={{ display: "flex", gap: 4, alignItems: "flex-start", m: 2 }}>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
        <Typography variant="subtitle1">Select Truck</Typography>
        <Select
          value={selectedTruck}
          onChange={handleChangeTruck}
          displayEmpty
          sx={{
            minWidth: 200,
            color: "#fff",
            "& .MuiSelect-icon": { color: "#fff" },
            "& .MuiOutlinedInput-notchedOutline": { borderColor: "#3c94fc" },
            "&:hover .MuiOutlinedInput-notchedOutline": { borderColor: "#fff" },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: "#3c94fc",
            },
          }}
          inputProps={{ "aria-label": "Select Truck" }}
        >
          <MenuItem value="">
            <em>-- Select Truck --</em>
          </MenuItem>
          {trucksList.map((truck) => (
            <MenuItem key={truck.truckId} value={truck.truckId}>
              {truck.number}
            </MenuItem>
          ))}
        </Select>
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
        <Typography variant="subtitle1">Driver</Typography>
        <TextField
          value={selectedDriver.name}
          variant="outlined"
          InputProps={{ readOnly: true }}
          sx={{
            minWidth: 200,
            input: { color: "#fff" },
            "& .MuiOutlinedInput-notchedOutline": { borderColor: "#3c94fc" },
          }}
        />
      </Box>
      {renderDateRangeInputs()}
    </Box>
  );

  const renderDateRangeInputs = () => (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Typography variant="subtitle1" sx={{ mb: 1 }}>
        Select Date Range
      </Typography>
      <Box sx={{ display: "flex", gap: 2, flexDirection: "row" }}>
        <TextField
          label="Start Date"
          type="date"
          value={startDate}
          onChange={handleStartDateChange}
          sx={{
            width: 170,
            input: { color: "#fff" },
            "& .MuiInputLabel-root": { color: "#fff" },
            "& .MuiOutlinedInput-root": {
              "& fieldset": { borderColor: "#3c94fc" },
              "&:hover fieldset": { borderColor: "#fff" },
              "&.Mui-focused fieldset": { borderColor: "#3c94fc" },
            },
          }}
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          label="End Date"
          type="date"
          value={endDate}
          onChange={handleEndDateChange}
          sx={{
            width: 170,
            input: { color: "#fff" },
            "& .MuiInputLabel-root": { color: "#fff" },
            "& .MuiOutlinedInput-root": {
              "& fieldset": { borderColor: "#3c94fc" },
              "&:hover fieldset": { borderColor: "#fff" },
              "&.Mui-focused fieldset": { borderColor: "#3c94fc" },
            },
          }}
          InputLabelProps={{ shrink: true }}
        />
      </Box>
    </Box>
  );

  return (
    <ThemeProvider theme={darkTheme}>
      <div className="chat-container">
        <SideNavBar />
        <div className="broker-window">
          <Toolbar style={{ minHeight: "83px" }} />
          <div className="broker-body">
            <Card sx={{ maxWidth: "100%", overflowX: "auto", m: 2 }}>
              <Typography
                variant="h4"
                component="div"
                sx={{
                  m: 2,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                Truck Payments
              </Typography>

              {renderTruckAndDriverSelect()}

              {isLoading ? (
                <Box
                  sx={{
                    m: 2,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  <Spinner sx={{ color: "#3c94fc" }} />
                </Box>
              ) : (
                <>
                  <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <Tabs
                      value={activeTab}
                      onChange={handleTabChange}
                      textColor="primary"
                      indicatorColor="primary"
                      variant="fullWidth"
                    >
                      <Tab label="Expenses" />
                      <Tab label="Orders" />
                      <Tab label="Pay" />
                    </Tabs>
                  </Box>

                  {/* EXPENSES TAB */}
                  {activeTab === 0 && (
                    <Box sx={{ p: 2 }}>
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        mb={2}
                        flexDirection={{ xs: "column", sm: "row" }}
                        gap={2}
                      >
                        <TextField
                          variant="outlined"
                          value={expenseSearchTerm}
                          onChange={handleExpenseSearchChange}
                          placeholder="Search Expenses"
                          size="small"
                          sx={{ width: { xs: "100%", sm: "40%" } }}
                        />
                        <Box
                          display="flex"
                          justifyContent="flex-end"
                          alignItems="center"
                          gap={2}
                          sx={{ width: { xs: "100%", sm: "auto" } }}
                        >
                          <Button
                            variant="contained"
                            startIcon={<AddIcon />}
                            onClick={() => setIsAddExpenseModalOpen(true)}
                          >
                            Add Expense
                          </Button>
                          <Button
                            variant="outlined"
                            onClick={handleDownloadExpensesPDF}
                          >
                            Download PDF
                          </Button>
                        </Box>
                      </Box>

                      <TableContainer component={Paper}>
                        <Table stickyHeader>
                          <TableHead>
                            <TableRow>
                              <TableCell>Name</TableCell>
                              <TableCell>Price</TableCell>
                              <TableCell>Currency</TableCell>
                              <TableCell>Action</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {filteredExpenses.length === 0 ? (
                              <TableRow>
                                <TableCell colSpan={4} align="center">
                                  No expenses found
                                </TableCell>
                              </TableRow>
                            ) : (
                              filteredExpenses
                                .slice(
                                  (currentExpensePage - 1) * expensesPerPage,
                                  currentExpensePage * expensesPerPage
                                )
                                .map((exp) => (
                                  <TableRow key={exp.expenseId}>
                                    <TableCell>{exp.name}</TableCell>
                                    <TableCell>{exp.price}</TableCell>
                                    <TableCell>{exp.currency}</TableCell>
                                    <TableCell>
                                      <IconButton
                                        sx={{ color: "#3c94fc" }}
                                        onClick={() =>
                                          handleEditExpenseClick(exp)
                                        }
                                        aria-label="edit"
                                      >
                                        <EditIcon />
                                      </IconButton>
                                      <IconButton
                                        sx={{ color: "#f44336" }}
                                        onClick={() =>
                                          handleDeleteExpenseClick(exp)
                                        }
                                        aria-label="delete"
                                      >
                                        <DeleteIcon />
                                      </IconButton>
                                    </TableCell>
                                  </TableRow>
                                ))
                            )}
                          </TableBody>
                        </Table>
                      </TableContainer>

                      {filteredExpenses.length > expensesPerPage && (
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            mt: 2,
                          }}
                        >
                          <Pagination
                            count={Math.ceil(
                              filteredExpenses.length / expensesPerPage
                            )}
                            page={currentExpensePage}
                            onChange={(event, value) =>
                              setCurrentExpensePage(value)
                            }
                            color="primary"
                          />
                        </Box>
                      )}
                    </Box>
                  )}

                  {/* ORDERS TAB */}
                  {activeTab === 1 && (
                    <Box sx={{ p: 2 }}>
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        mb={2}
                        flexDirection={{ xs: "column", sm: "row" }}
                        gap={2}
                      >
                        <TextField
                          variant="outlined"
                          value={orderSearchTerm}
                          onChange={handleOrderSearchChange}
                          placeholder="Search Orders"
                          size="small"
                          sx={{ width: { xs: "100%", sm: "40%" } }}
                        />
                        <Button
                          variant="outlined"
                          onClick={handleDownloadOrdersPDF}
                        >
                          Download PDF
                        </Button>
                      </Box>

                      <TableContainer component={Paper}>
                        <Table stickyHeader>
                          <TableHead>
                            <TableRow>
                              <TableCell>Pickup Date</TableCell>
                              <TableCell>Pickup Location</TableCell>
                              <TableCell>Delivery Location</TableCell>
                              <TableCell>Price</TableCell>
                              <TableCell>Currency</TableCell>
                              <TableCell>Action</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {filteredOrders.length === 0 ? (
                              <TableRow>
                                <TableCell colSpan={6} align="center">
                                  No orders found
                                </TableCell>
                              </TableRow>
                            ) : (
                              filteredOrders
                                .slice(
                                  (currentOrderPage - 1) * ordersPerPage,
                                  currentOrderPage * ordersPerPage
                                )
                                .map((order, idx) => (
                                  <TableRow key={idx}>
                                    <TableCell>{order.PickupDate}</TableCell>
                                    <TableCell>
                                      {order.PickupLocation}
                                    </TableCell>
                                    <TableCell>
                                      {order.DeliveryLocation}
                                    </TableCell>
                                    <TableCell>{order.PriceKept}</TableCell>
                                    <TableCell>{order.Currency}</TableCell>
                                    <TableCell>
                                      <IconButton
                                        sx={{ color: "#3c94fc" }}
                                        onClick={() => handleEditOrder(order)}
                                        aria-label="edit"
                                      >
                                        <EditIcon />
                                      </IconButton>
                                    </TableCell>
                                  </TableRow>
                                ))
                            )}
                          </TableBody>
                        </Table>
                      </TableContainer>

                      {filteredOrders.length > ordersPerPage && (
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            mt: 2,
                          }}
                        >
                          <Pagination
                            count={Math.ceil(
                              filteredOrders.length / ordersPerPage
                            )}
                            page={currentOrderPage}
                            onChange={(event, value) =>
                              setCurrentOrderPage(value)
                            }
                            color="primary"
                          />
                        </Box>
                      )}
                    </Box>
                  )}

                  {/* PAY TAB */}
                  {activeTab === 2 && (
                    <Box sx={{ p: 2 }}>
                      <Box sx={{ mb: 2, display: "flex", gap: 2 }}>
                        <Button
                          variant="contained"
                          onClick={handleCalculateAll}
                        >
                          Calculate
                        </Button>
                        <Button
                          variant="outlined"
                          onClick={handleDownloadPayPDF}
                        >
                          Download PDF
                        </Button>
                      </Box>

                      <Typography variant="h6" sx={{ mb: 2 }}>
                        Pay
                      </Typography>
                      <Box
                        sx={{
                          display: "grid",
                          gridTemplateColumns: "1fr 1fr",
                          gap: 2,
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: 1,
                          }}
                        >
                          <Typography variant="subtitle2">CAD Loads</Typography>
                          <TextField
                            variant="outlined"
                            value={cadLoadsTotal}
                            InputProps={{ readOnly: true }}
                            sx={{
                              input: { color: "#fff" },
                              "& .MuiOutlinedInput-notchedOutline": {
                                borderColor: "#3c94fc",
                              },
                            }}
                          />
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: 1,
                          }}
                        >
                          <Typography variant="subtitle2">USD Loads</Typography>
                          <TextField
                            variant="outlined"
                            value={usdLoadsTotal}
                            InputProps={{ readOnly: true }}
                            sx={{
                              input: { color: "#fff" },
                              "& .MuiOutlinedInput-notchedOutline": {
                                borderColor: "#3c94fc",
                              },
                            }}
                          />
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: 1,
                          }}
                        >
                          <Typography variant="subtitle2">
                            CAD Expenses
                          </Typography>
                          <TextField
                            variant="outlined"
                            value={cadExpensesTotal}
                            InputProps={{ readOnly: true }}
                            sx={{
                              input: { color: "#fff" },
                              "& .MuiOutlinedInput-notchedOutline": {
                                borderColor: "#3c94fc",
                              },
                            }}
                          />
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: 1,
                          }}
                        >
                          <Typography variant="subtitle2">
                            USD Expenses
                          </Typography>
                          <TextField
                            variant="outlined"
                            value={usdExpensesTotal}
                            InputProps={{ readOnly: true }}
                            sx={{
                              input: { color: "#fff" },
                              "& .MuiOutlinedInput-notchedOutline": {
                                borderColor: "#3c94fc",
                              },
                            }}
                          />
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: 1,
                          }}
                        >
                          <Typography variant="subtitle2">CAD Pay</Typography>
                          <TextField
                            variant="outlined"
                            value={cadPay}
                            InputProps={{ readOnly: true }}
                            sx={{
                              input: { color: "#fff" },
                              "& .MuiOutlinedInput-notchedOutline": {
                                borderColor: "#3c94fc",
                              },
                            }}
                          />
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: 1,
                          }}
                        >
                          <Typography variant="subtitle2">USD Pay</Typography>
                          <TextField
                            variant="outlined"
                            value={usdPay}
                            InputProps={{ readOnly: true }}
                            sx={{
                              input: { color: "#fff" },
                              "& .MuiOutlinedInput-notchedOutline": {
                                borderColor: "#3c94fc",
                              },
                            }}
                          />
                        </Box>
                      </Box>

                      {/* If a final single currency pay was calculated, show here */}
                      {finalPay && (
                        <Box sx={{ mt: 3 }}>
                          <Typography variant="subtitle1">
                            Final Pay:
                          </Typography>
                          <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                            {finalPay} {finalPayCurrency}
                          </Typography>
                          {finalPayExplanation && (
                            <pre
                              style={{
                                background: "#333",
                                padding: "1rem",
                                marginTop: "1rem",
                                whiteSpace: "pre-wrap",
                              }}
                            >
                              {finalPayExplanation}
                            </pre>
                          )}
                        </Box>
                      )}
                    </Box>
                  )}
                </>
              )}
            </Card>
          </div>
        </div>
      </div>

      {/* -------------- ADD EXPENSE DIALOG -------------- */}
      <Dialog open={isAddExpenseModalOpen} onClose={closeAddExpenseModal}>
        <DialogTitle>Add Expense</DialogTitle>
        <DialogContent
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            width: "400px",
          }}
        >
          <TextField
            label="Expense Name"
            name="name"
            value={newExpense.name}
            onChange={(e) => handleInputChange(e, setNewExpense)}
          />
          <TextField
            label="Price"
            name="price"
            type="number"
            value={newExpense.price}
            onChange={(e) => handleInputChange(e, setNewExpense)}
          />
          <Select
            name="currency"
            value={newExpense.currency}
            onChange={(e) => handleInputChange(e, setNewExpense)}
            displayEmpty
          >
            <MenuItem value="">
              <em>-- Select Currency --</em>
            </MenuItem>
            <MenuItem value="CAD">CAD</MenuItem>
            <MenuItem value="USD">USD</MenuItem>
          </Select>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeAddExpenseModal}>Cancel</Button>
          <Button onClick={handleSaveNewExpense}>Save</Button>
        </DialogActions>
      </Dialog>

      {/* -------------- EDIT EXPENSE DIALOG -------------- */}
      <Dialog open={isEditExpenseModalOpen} onClose={closeEditExpenseModal}>
        <DialogTitle>Edit Expense</DialogTitle>
        <DialogContent
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            width: "400px",
          }}
        >
          <TextField
            label="Expense Name"
            name="name"
            value={editExpense.name}
            onChange={handleEditExpenseInputChange}
          />
          <TextField
            label="Price"
            name="price"
            type="number"
            value={editExpense.price}
            onChange={handleEditExpenseInputChange}
          />
          <Select
            name="currency"
            value={editExpense.currency}
            onChange={handleEditExpenseInputChange}
            displayEmpty
          >
            <MenuItem value="">
              <em>-- Select Currency --</em>
            </MenuItem>
            <MenuItem value="CAD">CAD</MenuItem>
            <MenuItem value="USD">USD</MenuItem>
          </Select>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeEditExpenseModal}>Cancel</Button>
          <Button onClick={handleSaveEditExpense}>Save</Button>
        </DialogActions>
      </Dialog>

      {/* -------------- SNACKBAR -------------- */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity}
          sx={{ width: "100%" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </ThemeProvider>
  );
}

export default TruckPayments;
