import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef,
} from "react";
import { PDFDocument, StandardFonts, rgb } from "pdf-lib";
import Modal from "react-modal";
import bwipjs from "bwip-js";
import CropEasy from "react-easy-crop";
import {
  Card,
  CardContent,
  Typography,
  CircularProgress,
  Box,
  Button,
  Toolbar,
  TextField,
  MenuItem,
  IconButton,
  InputLabel,
  Slider,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import SmartToyIcon from "@mui/icons-material/SmartToy";
import CloseIcon from "@mui/icons-material/Close";
import PdfEditor from "./PdfEditor";
import MessageModal from "./MessageModal";
import SideNavBar from "./SideNavBar";
import { useNavigate } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import "./Broker.css";

// Predefined border arrays (defined outside so they are stable)
const pars_borders = [
  "Huntingdon, BC",
  "Pacific Highway, BC",
  "Osoyoos, BC",
  "Kingsgate, BC",
  "Coutts, AB",
  "North Portal, SK",
  "Regway, SK",
  "Emerson, MB",
  "Fort Erie, ON",
  "Windsor, ON",
  "Sarnia, ON",
  "Prescott, ON",
  "Lacolle, QC",
  "Stanstead, QC",
  "St. Stephen, NB",
  "Woodstock, NB",
];
const paps_borders = [
  "Sumas, WA",
  "Blaine, WA",
  "Oroville, WA",
  "Eastport, ID",
  "Sweet Grass, MT",
  "Portal, ND",
  "Raymond, MT",
  "Pembina, ND",
  "Buffalo, NY",
  "Detroit, MI",
  "Port Huron, MI",
  "Ogdensburg, NY",
  "Champlain, NY",
  "Derby Line, VT",
  "Calais, ME",
  "Houlton, ME",
];

function EditCustoms() {
  const navigate = useNavigate();

  // State for customs record and loading indicator.
  const [customs, setCustoms] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  // Editable fields.
  const [border, setBorder] = useState("");
  const [customsType, setCustomsType] = useState("");
  const [numberField, setNumberField] = useState("");

  // New state for orderId and organizationId from the fetched customs row.
  const [orderId, setOrderId] = useState("");
  const [organizationId, setOrganizationId] = useState("");

  // Broker-related state.
  const [brokerList, setBrokerList] = useState([]);
  const [selectedBrokerId, setSelectedBrokerId] = useState("");
  const [brokerEmail, setBrokerEmail] = useState("");
  const [brokerPhone, setBrokerPhone] = useState("");

  // PDF & Email states.
  const [pdfFiles, setPdfFiles] = useState([]);
  const [imageFiles, setImageFiles] = useState([]);
  const [combinedPdfBlob, setCombinedPdfBlob] = useState(null);
  const [openEmailModal, setOpenEmailModal] = useState(false);
  const [emailSubject, setEmailSubject] = useState("");
  const [emailBody, setEmailBody] = useState("");
  const [emailTo, setEmailTo] = useState("");
  const [emailCc, setEmailCc] = useState("");
  const [emailAttachments, setEmailAttachments] = useState([]);

  // Modal and message states.
  const [isPdfEditorOpen, setIsPdfEditorOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState("");

  // Cropping modal states.
  const [openCropModal, setOpenCropModal] = useState(false);
  const [cropImageObj, setCropImageObj] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [cropSize, setCropSize] = useState({ width: 350, height: 300 });

  // Ref for file input.
  const fileInputRef = useRef(null);

  // Sorted list of borders.
  const sortedBorders = useMemo(() => {
    return [...pars_borders, ...paps_borders].sort((a, b) =>
      a.localeCompare(b)
    );
  }, []);

  // Retrieve customsId from sessionStorage.
  const customsId = sessionStorage.getItem("editCustomsId");

  // Dark theme.
  const darkTheme = createTheme({
    palette: {
      mode: "dark",
      background: { default: "#000000", paper: "#000000" },
      text: { primary: "#ffffff" },
      primary: { main: "#3c94fc" },
      secondary: { main: "#f50057" },
    },
  });

  // Fetch the customs record.
  const fetchCustomsRow = useCallback(async () => {
    if (!customsId) return;
    setIsLoading(true);
    try {
      const response = await fetch(
        `/api/fetch-customs-row?customsId=${encodeURIComponent(customsId)}`
      );
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      const fetchedCustoms = data.result || null;
      setCustoms(fetchedCustoms);
      if (fetchedCustoms) {
        setBorder(fetchedCustoms.border || "");
        setCustomsType(fetchedCustoms.type || "");
        setNumberField(fetchedCustoms.number || "");
        if (fetchedCustoms.brokerId) {
          setSelectedBrokerId(fetchedCustoms.brokerId);
        }
        if (fetchedCustoms.orderId) {
          setOrderId(fetchedCustoms.orderId);
        }
        if (fetchedCustoms.organizationId) {
          setOrganizationId(fetchedCustoms.organizationId);
        }
      }
    } catch (error) {
      console.error("Failed to fetch customs row:", error);
    } finally {
      setIsLoading(false);
    }
  }, [customsId]);

  // Fetch the list of brokers.
  const fetchBrokers = useCallback(async () => {
    try {
      const response = await fetch("/api/fetch-brokers");
      if (!response.ok) {
        throw new Error("Failed to fetch broker list");
      }
      const data = await response.json();
      setBrokerList(data.result || []);
    } catch (error) {
      console.error("Failed to fetch brokers:", error);
    }
  }, []);

  useEffect(() => {
    fetchCustomsRow();
    fetchBrokers();
  }, [fetchCustomsRow, fetchBrokers]);

  // Update broker details when selected broker or customsType changes.
  useEffect(() => {
    if (!selectedBrokerId) {
      setBrokerEmail("");
      setBrokerPhone("");
      return;
    }
    const selectedBroker = brokerList.find(
      (b) => b.brokerId === selectedBrokerId
    );
    if (selectedBroker && customsType) {
      if (customsType === "PAPS") {
        setBrokerEmail(selectedBroker.papsEmail || "");
      } else if (customsType === "PARS") {
        setBrokerEmail(selectedBroker.parsEmail || "");
      } else {
        setBrokerEmail("");
      }
      setBrokerPhone(selectedBroker.phoneNumber || "");
    }
  }, [selectedBrokerId, brokerList, customsType]);

  // Handler for broker selection.
  const handleBrokerChange = (e) => {
    const newBrokerId = parseInt(e.target.value, 10);
    setSelectedBrokerId(newBrokerId);
  };

  // Handler for border selection.
  const handleBorderChange = (e) => {
    const newBorder = e.target.value;
    setBorder(newBorder);
    if (paps_borders.includes(newBorder)) {
      setCustomsType("PAPS");
    } else if (pars_borders.includes(newBorder)) {
      setCustomsType("PARS");
    } else {
      setCustomsType("");
    }
  };

  // File input change handler.
  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    const newPdfFiles = [];
    const newImageFiles = [];
    files.forEach((file) => {
      if (file.type === "application/pdf") {
        newPdfFiles.push(file);
      } else if (file.type.startsWith("image/")) {
        const previewUrl = URL.createObjectURL(file);
        newImageFiles.push({
          id: Math.random().toString(36).substring(2),
          file,
          previewUrl,
          croppedBlob: null,
          processedBlob: null,
          isCropped: false,
          error: null,
        });
      }
    });
    setPdfFiles((prev) => [...prev, ...newPdfFiles]);
    setImageFiles((prev) => [...prev, ...newImageFiles]);
  };

  // --- Cropping modal handlers ---
  const handleCloseCropModal = () => {
    setOpenCropModal(false);
    setCrop({ x: 0, y: 0 });
    setZoom(1);
    setCroppedAreaPixels(null);
    setCropImageObj(null);
  };

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const handleConfirmCrop = async () => {
    if (!cropImageObj || !croppedAreaPixels) return;
    try {
      const croppedBlob = await getCroppedImg(
        cropImageObj.previewUrl,
        croppedAreaPixels
      );
      const processedBlob = await processImageFile(croppedBlob);
      setImageFiles((prev) =>
        prev.map((item) => {
          if (item.id === cropImageObj.id) {
            return {
              ...item,
              croppedBlob,
              processedBlob,
              isCropped: true,
              error: null,
            };
          }
          return item;
        })
      );
    } catch (error) {
      console.error(error);
      setImageFiles((prev) =>
        prev.map((item) => {
          if (item.id === cropImageObj.id) {
            return { ...item, error: "Cropping failed." };
          }
          return item;
        })
      );
    }
    handleCloseCropModal();
  };

  // --- PDF generation logic ---
  const generatePdf = async () => {
    if (pdfFiles.length === 0 && imageFiles.length === 0) {
      setMessage(
        "Please select at least one PDF or image file before generating."
      );
      setStatus("Bad");
      return;
    }
    if (!numberField) {
      setMessage("Please provide a number for the barcode.");
      setStatus("Bad");
      return;
    }
    setIsLoading(true);
    try {
      const mergedPdf = await PDFDocument.create();
      const letterWidth = 612;
      const letterHeight = 792;
      const customPage = mergedPdf.addPage([letterWidth, letterHeight]);
      const fontSize = 30;
      const font = await mergedPdf.embedFont(StandardFonts.Helvetica);
      const text1 = `${customsType} ${numberField}`;
      const text2 = `Crossing at ${border}`;
      const nowUTC = new Date();
      const currentDate = new Date(
        nowUTC.toLocaleString("en-US", { timeZone: "America/Vancouver" })
      );
      currentDate.setHours(currentDate.getHours() + 2);
      if (currentDate.getMinutes() >= 30) {
        currentDate.setMinutes(0);
        currentDate.setHours(currentDate.getHours() + 1);
      } else {
        currentDate.setMinutes(0);
      }
      const options = {
        day: "2-digit",
        month: "long",
        year: "numeric",
        hour: "numeric",
        minute: "2-digit",
        hour12: true,
      };
      const dateString = currentDate.toLocaleString("en-US", options);
      const text3 = `On ${dateString}`;
      const centerX = letterWidth / 2;
      const centerY = letterHeight / 2;
      const barcodeImage = await generateBarcodeImage(numberField, mergedPdf);
      if (!barcodeImage) {
        setMessage("Error generating barcode. Please try again.");
        setStatus("Bad");
        setIsLoading(false);
        return;
      }
      const barcodeHeight = 60;
      customPage.drawImage(barcodeImage, {
        x: centerX - barcodeImage.width / 2,
        y: centerY + barcodeHeight,
        width: barcodeImage.width,
        height: barcodeHeight,
      });
      const text1Width = font.widthOfTextAtSize(text1, fontSize);
      const text2Width = font.widthOfTextAtSize(text2, fontSize);
      const text3Width = font.widthOfTextAtSize(text3, fontSize);
      customPage.drawText(text1, {
        x: centerX - text1Width / 2,
        y: centerY,
        size: fontSize,
        font,
      });
      customPage.drawText(text2, {
        x: centerX - text2Width / 2,
        y: centerY - 40,
        size: fontSize,
        font,
      });
      customPage.drawText(text3, {
        x: centerX - text3Width / 2,
        y: centerY - 80,
        size: fontSize,
        font,
      });
      customPage.drawLine({
        start: { x: 40, y: 40 },
        end: { x: letterWidth - 40, y: 40 },
        thickness: 0.5,
        color: rgb(0, 0, 0),
      });
      customPage.drawText("Generated by LoadMinds ©", {
        x: 40,
        y: 25,
        size: 9,
        font,
        color: rgb(0.39, 0.39, 0.39),
      });

      const addPdfPagesToMerged = async (mergedPdf, pdfBytes, forceLetter) => {
        const loadedPdf = await PDFDocument.load(pdfBytes);
        const pageIndices = loadedPdf.getPageIndices();
        for (const idx of pageIndices) {
          if (forceLetter) {
            const [embeddedPage] = await mergedPdf.embedPages([
              loadedPdf.getPage(idx),
            ]);
            const origWidth = embeddedPage.width;
            const origHeight = embeddedPage.height;
            const xScale = letterWidth / origWidth;
            const yScale = letterHeight / origHeight;
            const scale = Math.min(xScale, yScale);
            const scaledWidth = origWidth * scale;
            const scaledHeight = origHeight * scale;
            const x = (letterWidth - scaledWidth) / 2;
            const y = (letterHeight - scaledHeight) / 2;
            const newPage = mergedPdf.addPage([letterWidth, letterHeight]);
            newPage.drawPage(embeddedPage, {
              x,
              y,
              xScale: scale,
              yScale: scale,
            });
          } else {
            const [copiedPage] = await mergedPdf.copyPages(loadedPdf, [idx]);
            mergedPdf.addPage(copiedPage);
          }
        }
      };

      for (const file of pdfFiles) {
        const fileBytes = await file.arrayBuffer();
        await addPdfPagesToMerged(mergedPdf, fileBytes, true);
      }

      for (const imgObj of imageFiles) {
        const blobToUse = imgObj.processedBlob || imgObj.file;
        if (blobToUse) {
          const buffer = await blobToUse.arrayBuffer();
          let embeddedImage;
          if (imgObj.file.type.includes("png")) {
            embeddedImage = await mergedPdf.embedPng(buffer);
          } else {
            embeddedImage = await mergedPdf.embedJpg(buffer);
          }
          const { width: origWidth, height: origHeight } =
            embeddedImage.scale(1);
          const xScale = letterWidth / origWidth;
          const yScale = letterHeight / origHeight;
          const scale = Math.min(xScale, yScale);
          const scaledWidth = origWidth * scale;
          const scaledHeight = origHeight * scale;
          const x = (letterWidth - scaledWidth) / 2;
          const y = (letterHeight - scaledHeight) / 2;
          const newPage = mergedPdf.addPage([letterWidth, letterHeight]);
          newPage.drawImage(embeddedImage, {
            x,
            y,
            width: scaledWidth,
            height: scaledHeight,
          });
        }
      }

      const mergedPdfBytes = await mergedPdf.save();
      const fileName = `${customsType} ${numberField} ${border.replace(
        /,/g,
        ""
      )}.pdf`;
      const mergedFile = new File([mergedPdfBytes], fileName, {
        type: "application/pdf",
      });
      setCombinedPdfBlob(mergedFile);
      togglePdfEditorModal();
    } catch (error) {
      console.error("Error generating PDF:", error);
      setMessage("An unexpected error occurred while generating the PDF.");
      setStatus("Bad");
    } finally {
      setIsLoading(false);
    }
  };

  const generateBarcodeImage = async (data, mergedPdf) => {
    if (!data) {
      throw new Error("Barcode data is missing");
    }
    try {
      const canvas = document.createElement("canvas");
      await bwipjs.toCanvas(canvas, {
        bcid: "code128",
        text: data,
        scale: 3,
        height: 10,
        includetext: true,
        textxalign: "center",
      });
      const barcodeDataUrl = canvas.toDataURL("image/png");
      const barcodeImageBytes = await fetch(barcodeDataUrl).then((res) =>
        res.arrayBuffer()
      );
      return await mergedPdf.embedPng(barcodeImageBytes);
    } catch (err) {
      console.error("Error generating barcode:", err);
      throw err;
    }
  };

  // --- Update Customs Row function ---
  const updateCustomsRow = async () => {
    const payload = {
      customsId: customsId,
      border: border,
      number: numberField,
      type: customsType,
      brokerId: selectedBrokerId,
      orderId: orderId,
      organizationId: organizationId,
    };
    try {
      const response = await fetch("/api/update-customs", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(payload),
      });
      const data = await response.json();
      if (response.ok) {
        setMessage(`Update successful: ${data.message}`);
        setStatus("Good");
      } else {
        throw new Error(data.error || "Failed to update customs row");
      }
    } catch (error) {
      setMessage(`Error: ${error.message}`);
      setStatus("Bad");
    }
  };

  // --- Email Modal Helpers ---
  const handleEnhanceEmail = async () => {
    try {
      const response = await fetch("/api/enhance-subject-body-customs", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          subject: emailSubject,
          body: emailBody,
        }),
      });
      if (!response.ok) {
        throw new Error("Failed to enhance subject/body");
      }
      const data = await response.json();
      const parsedMessage = JSON.parse(data.message);
      if (parsedMessage.subject) {
        setEmailSubject(parsedMessage.subject);
        console.log("Enhanced Subject:", parsedMessage.subject);
      }
      if (parsedMessage.body) {
        setEmailBody(parsedMessage.body);
        console.log("Enhanced Body:", parsedMessage.body);
      }
    } catch (error) {
      console.error("Error enhancing email:", error);
      alert("Error enhancing email content. Please try again.");
    }
  };

  const handleRemoveEmailAttachment = (index) => {
    setEmailAttachments((prev) => {
      const updated = [...prev];
      updated.splice(index, 1);
      return updated;
    });
  };

  // Open Email Modal – prepopulate fields.
  const handleOpenEmailModal = () => {
    if (!combinedPdfBlob) {
      setMessage("Please generate a PDF first.");
      setStatus("Bad");
      return;
    }
    setEmailTo(brokerEmail);
    setEmailCc("");
    setEmailSubject(`${customsType} ${numberField} ${border}`);
    setEmailBody("Thanks.");
    setOpenEmailModal(true);
  };

  // --- Send Email ---
  const sendEmailWithAttachment = async (
    toEmail,
    subject,
    attachment,
    body,
    additionalAttachments = []
  ) => {
    const emails = toEmail.split(",").map((e) => e.trim());
    const primaryEmail = emails.shift();
    const ccEmails = emails.join(",");
    const userCustomsEmail =
      sessionStorage.getItem("storageCustomsEmail") || "";
    const storageOrganizationId =
      sessionStorage.getItem("storageOrganizationId") || "";
    const userFirstName = sessionStorage.getItem("storageFirstName") || "";
    const userLastName = sessionStorage.getItem("storageLastName") || "";
    const userCompanyName = sessionStorage.getItem("storageCompanyName") || "";
    const userPhone = sessionStorage.getItem("storagePhone") || "";
    const userAddress = sessionStorage.getItem("storageAddress") || "";
    const userCity = sessionStorage.getItem("storageCity") || "";
    const userState = sessionStorage.getItem("storageState") || "";
    const userZip = sessionStorage.getItem("storageZip") || "";
    const storageUserId = sessionStorage.getItem("storageUserId" || "");
    const userCompanyLogo = sessionStorage.getItem("storageCompanyLogo") || "";
    const userCompanyWebsite =
      sessionStorage.getItem("storageCompanyWebsite") || "";
    const formData = new FormData();
    formData.append("firstName", userFirstName);
    formData.append("lastName", userLastName);
    formData.append("companyName", userCompanyName);
    formData.append("phone", userPhone);
    formData.append("toEmail", primaryEmail);
    formData.append("storageOrganizationId", storageOrganizationId);
    formData.append("storageUserId", storageUserId);
    formData.append("ccEmails", ccEmails);
    formData.append("subject", subject);
    formData.append("body", body);
    formData.append("attachment", attachment);
    formData.append("replyTo", userCustomsEmail);
    formData.append("number", numberField);
    formData.append("address", userAddress);
    formData.append("city", userCity);
    formData.append("state", userState);
    formData.append("zip", userZip);
    formData.append("companyLogo", userCompanyLogo);
    formData.append("companyWebsite", userCompanyWebsite);
    if (additionalAttachments && additionalAttachments.length > 0) {
      additionalAttachments.forEach((file) => {
        formData.append("additionalAttachments", file);
      });
    }
    try {
      const response = await fetch("/api/send-email-with-attachment-customs", {
        method: "POST",
        body: formData,
      });
      const data = await response.json();
      if (response.ok) {
        setMessage(data.message);
      } else {
        setMessage(data.error);
      }
    } catch (error) {
      setMessage("An error occurred while sending the email.");
    }
  };

  const handleSendEmailModal = async () => {
    setIsLoading(true);
    try {
      const combinedEmails = emailTo + (emailCc ? `, ${emailCc}` : "");
      await sendEmailWithAttachment(
        combinedEmails,
        emailSubject,
        combinedPdfBlob,
        emailBody,
        emailAttachments
      );
      await updateCustomsRow();
      setMessage("Email sent successfully.");
      setStatus("Good");
      setOpenEmailModal(false);
    } catch (error) {
      console.error("Error sending email:", error);
      setMessage("An error occurred while sending the email.");
      setStatus("Bad");
    } finally {
      setIsLoading(false);
    }
  };

  const togglePdfEditorModal = () => {
    setIsPdfEditorOpen(!isPdfEditorOpen);
  };

  // --- Helper functions for image processing & cropping ---
  const createImage = (url) =>
    new Promise((resolve, reject) => {
      const image = new Image();
      image.addEventListener("load", () => resolve(image));
      image.addEventListener("error", (error) => reject(error));
      image.setAttribute("crossOrigin", "anonymous");
      image.src = url;
    });

  async function getCroppedImg(imageSrc, croppedAreaPixels) {
    const image = await createImage(imageSrc);
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    canvas.width = croppedAreaPixels.width;
    canvas.height = croppedAreaPixels.height;
    ctx.drawImage(
      image,
      croppedAreaPixels.x,
      croppedAreaPixels.y,
      croppedAreaPixels.width,
      croppedAreaPixels.height,
      0,
      0,
      croppedAreaPixels.width,
      croppedAreaPixels.height
    );
    return new Promise((resolve, reject) => {
      canvas.toBlob((file) => {
        if (file) {
          resolve(file);
        } else {
          reject(new Error("Canvas is empty"));
        }
      }, "image/jpeg");
    });
  }

  function grayscaleAndAutoLevel(imageData) {
    const { data } = imageData;
    let minGray = 255,
      maxGray = 0;
    for (let i = 0; i < data.length; i += 4) {
      const gray = Math.round((data[i] + data[i + 1] + data[i + 2]) / 3);
      data[i] = gray;
      data[i + 1] = gray;
      data[i + 2] = gray;
      if (gray < minGray) minGray = gray;
      if (gray > maxGray) maxGray = gray;
    }
    const range = maxGray - minGray || 1;
    for (let i = 0; i < data.length; i += 4) {
      const g = data[i];
      const scaled = ((g - minGray) / range) * 255;
      data[i] = scaled;
      data[i + 1] = scaled;
      data[i + 2] = scaled;
    }
  }

  function sharpenGrayscale(imageData) {
    const { data, width, height } = imageData;
    const output = new Uint8ClampedArray(data.length);
    const kernel = [
      [0, -1, 0],
      [-1, 5, -1],
      [0, -1, 0],
    ];
    const idx = (x, y) => 4 * (y * width + x);
    for (let y = 1; y < height - 1; y++) {
      for (let x = 1; x < width - 1; x++) {
        let sum = 0;
        for (let ky = -1; ky <= 1; ky++) {
          for (let kx = -1; kx <= 1; kx++) {
            const pixelIndex = idx(x + kx, y + ky);
            sum += data[pixelIndex] * kernel[ky + 1][kx + 1];
          }
        }
        sum = Math.min(255, Math.max(0, sum));
        const outIndex = idx(x, y);
        output[outIndex] = sum;
        output[outIndex + 1] = sum;
        output[outIndex + 2] = sum;
        output[outIndex + 3] = 255;
      }
    }
    for (let x = 0; x < width; x++) {
      let topIdx = idx(x, 0);
      output[topIdx] = data[topIdx];
      output[topIdx + 1] = data[topIdx + 1];
      output[topIdx + 2] = data[topIdx + 2];
      output[topIdx + 3] = data[topIdx + 3];
      let botIdx = idx(x, height - 1);
      output[botIdx] = data[botIdx];
      output[botIdx + 1] = data[botIdx + 1];
      output[botIdx + 2] = data[botIdx + 2];
      output[botIdx + 3] = data[botIdx + 3];
    }
    for (let y = 0; y < height; y++) {
      let leftIdx = idx(0, y);
      output[leftIdx] = data[leftIdx];
      output[leftIdx + 1] = data[leftIdx + 1];
      output[leftIdx + 2] = data[leftIdx + 2];
      output[leftIdx + 3] = data[leftIdx + 3];
      let rightIdx = idx(width - 1, y);
      output[rightIdx] = data[rightIdx];
      output[rightIdx + 1] = data[rightIdx + 1];
      output[rightIdx + 2] = data[rightIdx + 2];
      output[rightIdx + 3] = data[rightIdx + 3];
    }
    for (let i = 0; i < data.length; i++) {
      data[i] = output[i];
    }
  }

  async function processImageFile(file) {
    return new Promise((resolve, reject) => {
      const img = new Image();
      const url = URL.createObjectURL(file);
      img.onload = () => {
        const canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);
        let imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
        grayscaleAndAutoLevel(imageData);
        sharpenGrayscale(imageData);
        ctx.putImageData(imageData, 0, 0);
        canvas.toBlob(
          (blob) => {
            if (blob) {
              resolve(blob);
            } else {
              reject(new Error("Canvas conversion failed."));
            }
            URL.revokeObjectURL(url);
          },
          file.type,
          1
        );
      };
      img.onerror = (error) => {
        URL.revokeObjectURL(url);
        reject(error);
      };
      img.src = url;
    });
  }

  return (
    <ThemeProvider theme={darkTheme}>
      <div className="chat-container">
        <SideNavBar />
        <div className="broker-window">
          <Toolbar style={{ minHeight: "83px" }} />
          <div className="broker-body">
            {isLoading ? (
              <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
                <CircularProgress color="primary" />
              </Box>
            ) : customs ? (
              <Card sx={{ maxWidth: "600px", margin: "0 auto", p: 2 }}>
                <CardContent>
                  <Typography variant="h4" gutterBottom>
                    Edit Customs
                  </Typography>
                  <Box sx={{ mt: 2 }}>
                    {/* Editable Number Field */}
                    <TextField
                      label="Number"
                      value={numberField}
                      onChange={(e) => setNumberField(e.target.value)}
                      variant="outlined"
                      fullWidth
                      sx={{ mt: 1, mb: 2 }}
                    />
                    {/* Border selection */}
                    <Typography variant="subtitle1" sx={{ mt: 2 }}>
                      <strong>Select Border:</strong>
                    </Typography>
                    <TextField
                      select
                      value={border}
                      onChange={handleBorderChange}
                      variant="outlined"
                      fullWidth
                      sx={{ mt: 1, mb: 2 }}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {sortedBorders.map((borderOption) => (
                        <MenuItem key={borderOption} value={borderOption}>
                          {borderOption}
                        </MenuItem>
                      ))}
                    </TextField>
                    <Typography variant="subtitle1">
                      <strong>Customer:</strong> {customs.customerName}
                    </Typography>
                    <Typography variant="subtitle1">
                      <strong>Date:</strong> {customs.date}
                    </Typography>
                    {/* Broker selection */}
                    <Typography variant="subtitle1" sx={{ mt: 2 }}>
                      <strong>Select Broker:</strong>
                    </Typography>
                    <TextField
                      select
                      value={selectedBrokerId}
                      onChange={handleBrokerChange}
                      variant="outlined"
                      fullWidth
                      sx={{ mt: 1, mb: 2 }}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {brokerList.map((broker) => (
                        <MenuItem key={broker.brokerId} value={broker.brokerId}>
                          {broker.name}
                        </MenuItem>
                      ))}
                    </TextField>
                    {/* Editable Broker Email */}
                    <TextField
                      label="Broker Email"
                      value={brokerEmail}
                      onChange={(e) => setBrokerEmail(e.target.value)}
                      variant="outlined"
                      fullWidth
                      sx={{ mt: 1, mb: 2 }}
                    />
                    {/* Editable Broker Phone */}
                    <TextField
                      label="Broker Phone"
                      value={brokerPhone}
                      onChange={(e) => setBrokerPhone(e.target.value)}
                      variant="outlined"
                      fullWidth
                      sx={{ mt: 1, mb: 2 }}
                    />
                  </Box>
                  <Box sx={{ mt: 2 }}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => navigate(-1)}
                    >
                      Back
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={generatePdf}
                      sx={{ ml: 2 }}
                    >
                      GENERATE PDF
                    </Button>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={handleOpenEmailModal}
                      sx={{ ml: 2 }}
                    >
                      SEND EMAIL
                    </Button>
                    <Button
                      variant="contained"
                      color="success"
                      onClick={updateCustomsRow}
                      sx={{ ml: 2 }}
                    >
                      Save
                    </Button>
                    <Button
                      variant="contained"
                      onClick={togglePdfEditorModal}
                      sx={{ ml: 2 }}
                      disabled={!combinedPdfBlob}
                    >
                      Show PDF
                    </Button>
                  </Box>
                </CardContent>
              </Card>
            ) : (
              <Typography variant="h5" sx={{ mt: 4, textAlign: "center" }}>
                No customs data found.
              </Typography>
            )}
          </div>
        </div>
        {/* File Input for PDFs and Images */}
        <input
          type="file"
          id="pdfUpload"
          multiple
          accept="application/pdf, image/*"
          className="file-input"
          onChange={handleFileChange}
          ref={fileInputRef}
        />
      </div>
      {/* PDF Editor Modal */}
      <Modal
        isOpen={isPdfEditorOpen}
        onRequestClose={togglePdfEditorModal}
        contentLabel="PDF Editor"
        style={{ content: { inset: "3%", overflow: "auto" } }}
        ariaHideApp={false}
      >
        <PdfEditor
          combinedPdfBlob={combinedPdfBlob}
          onSave={(updatedBlob) => setCombinedPdfBlob(updatedBlob)}
          onClose={togglePdfEditorModal}
          showSideNavBar={false}
          paddingLeft="12%"
          paddingRight="5%"
          prefix={customsType}
          number={numberField}
          matchedBorder={border}
        />
      </Modal>
      {/* Message Modal */}
      <MessageModal
        open={status !== ""}
        handleClose={() => setStatus("")}
        title={
          status === "Good"
            ? "Success"
            : status === "Bad"
            ? "Error"
            : "Processing..."
        }
        message={message}
        status={status}
      />
      {/* Cropping Modal */}
      <Modal
        isOpen={openCropModal}
        onRequestClose={handleCloseCropModal}
        contentLabel="Crop Image"
        style={{
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            transform: "translate(-50%, -50%)",
            width: "90vw",
            height: "80vh",
            background: "#fff",
            padding: "20px",
          },
        }}
        ariaHideApp={false}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h3>Crop your image (optional)</h3>
          <IconButton onClick={handleCloseCropModal}>
            <CloseIcon />
          </IconButton>
        </div>
        <div
          style={{
            position: "relative",
            width: "100%",
            height: "60%",
            background: "#333",
            marginBottom: "20px",
          }}
        >
          {cropImageObj && (
            <CropEasy
              image={cropImageObj.previewUrl}
              crop={crop}
              zoom={zoom}
              aspect={null}
              cropShape="rect"
              showGrid={true}
              restrictPosition={false}
              cropSize={cropSize}
              onCropChange={setCrop}
              onZoomChange={setZoom}
              onCropComplete={onCropComplete}
            />
          )}
        </div>
        <div style={{ marginBottom: "20px" }}>
          <div style={{ display: "flex", gap: "20px", alignItems: "center" }}>
            <div style={{ flex: 1 }}>
              <p>Width: {cropSize.width}px</p>
              <Slider
                value={cropSize.width}
                min={50}
                max={1200}
                step={10}
                onChange={(e, newVal) =>
                  setCropSize((prev) => ({ ...prev, width: newVal }))
                }
              />
            </div>
            <div style={{ flex: 1 }}>
              <p>Height: {cropSize.height}px</p>
              <Slider
                value={cropSize.height}
                min={50}
                max={1200}
                step={10}
                onChange={(e, newVal) =>
                  setCropSize((prev) => ({ ...prev, height: newVal }))
                }
              />
            </div>
          </div>
          <div style={{ marginTop: "20px" }}>
            <p>Zoom: {zoom.toFixed(2)}</p>
            <Slider
              value={zoom}
              min={1}
              max={5}
              step={0.1}
              onChange={(e, zoomValue) => setZoom(zoomValue)}
            />
          </div>
        </div>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleConfirmCrop}
          >
            Confirm Crop
          </Button>
        </div>
      </Modal>
      {/* Email Modal */}
      <Modal
        isOpen={openEmailModal}
        onRequestClose={() => setOpenEmailModal(false)}
        contentLabel="Send Email"
        style={{
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            transform: "translate(-50%, -50%)",
            width: "600px",
            maxWidth: "90vw",
            background: "#333",
            color: "#fff",
            padding: "20px",
          },
        }}
        ariaHideApp={false}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
          <h3>Send Email</h3>
          <IconButton onClick={() => setOpenEmailModal(false)}>
            <CloseIcon style={{ color: "#fff" }} />
          </IconButton>
        </div>
        <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
          <TextField
            label="Subject"
            variant="filled"
            fullWidth
            value={emailSubject}
            onChange={(e) => setEmailSubject(e.target.value)}
            InputProps={{ style: { color: "#fff" } }}
            InputLabelProps={{ style: { color: "#aaa" } }}
            sx={{ backgroundColor: "#222" }}
          />
          <TextField
            label="To"
            variant="filled"
            fullWidth
            value={emailTo}
            onChange={(e) => setEmailTo(e.target.value)}
            InputProps={{ style: { color: "#fff" } }}
            InputLabelProps={{ style: { color: "#aaa" } }}
            sx={{ backgroundColor: "#222" }}
          />
          <TextField
            label="CC"
            variant="filled"
            fullWidth
            value={emailCc}
            onChange={(e) => setEmailCc(e.target.value)}
            InputProps={{ style: { color: "#fff" } }}
            InputLabelProps={{ style: { color: "#aaa" } }}
            sx={{ backgroundColor: "#222" }}
          />
          <TextField
            label="Body"
            variant="filled"
            multiline
            rows={5}
            fullWidth
            value={emailBody}
            onChange={(e) => setEmailBody(e.target.value)}
            InputProps={{
              style: { color: "#fff" },
              endAdornment: (
                <IconButton onClick={handleEnhanceEmail}>
                  <SmartToyIcon sx={{ color: "#fff" }} />
                </IconButton>
              ),
            }}
            InputLabelProps={{ style: { color: "#aaa" } }}
            sx={{ backgroundColor: "#222" }}
          />
          <div>
            <InputLabel sx={{ mb: 1, color: "#aaa" }}>
              Additional Attachments
            </InputLabel>
            <Button variant="contained" component="label">
              Add Files
              <input
                type="file"
                hidden
                multiple
                onChange={(e) => {
                  const files = e.target.files;
                  if (files && files.length > 0) {
                    setEmailAttachments((prev) => [
                      ...prev,
                      ...Array.from(files),
                    ]);
                  }
                }}
              />
            </Button>
            {emailAttachments.length > 0 && (
              <List dense sx={{ mt: 2, maxHeight: 150, overflowY: "auto" }}>
                {emailAttachments.map((file, idx) => (
                  <ListItem key={idx}>
                    <ListItemText
                      primary={file.name}
                      secondary={`${(file.size / 1024).toFixed(2)} KB`}
                    />
                    <ListItemSecondaryAction>
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        onClick={() => handleRemoveEmailAttachment(idx)}
                      >
                        <DeleteIcon sx={{ color: "#fff" }} />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>
            )}
          </div>
          <Typography variant="body2" sx={{ mt: 2 }}>
            <strong>Auto-attached:</strong> {combinedPdfBlob?.name || "PDF"}
          </Typography>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "10px",
            }}
          >
            <Button
              variant="contained"
              color="secondary"
              onClick={handleSendEmailModal}
            >
              Send
            </Button>
          </div>
        </div>
      </Modal>
    </ThemeProvider>
  );
}

export default EditCustoms;
