// EditOrder.js
import React, { useState, useEffect, useRef } from "react";
import { Button, Toolbar } from "@mui/material";
import Spinner from "./Spinner";
import SideNavBar from "./SideNavBar";
import { useNavigate, useLocation } from "react-router-dom";
import "./Edit.css";
import OrderDetails from "./OrderDetails";

// ---- Supabase ----
import { createClient } from "@supabase/supabase-js";
const supabaseUrl = "https://bkyisyxrbccchnmvksvj.supabase.co";
const supabaseKey =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImJreWlzeXhyYmNjY2hubXZrc3ZqIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MzQzMDMyNzcsImV4cCI6MjA0OTg3OTI3N30.qqnqqNWQK27zUzmuqydQA5lmS3-x_Yd41mqykrUkBcc";
const supabase = createClient(supabaseUrl, supabaseKey);

function EditOrder() {
  const [editData, setEditData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [newFile, setNewFile] = useState(null);
  const fileInputRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  const fromPage = location.state?.from || null;

  // New states for dropdown options and selected IDs
  const [truckOptions, setTruckOptions] = useState([]);
  const [trailerOptions, setTrailerOptions] = useState([]);
  const [driverOptions, setDriverOptions] = useState([]);
  const [selectedTruck, setSelectedTruck] = useState("");
  const [selectedTrailer, setSelectedTrailer] = useState("");
  const [selectedDriver, setSelectedDriver] = useState({
    driverId: "",
    name: "",
  });

  // Helper to format location data (array of objects or a single object)
  const formatLocation = (locationData) => {
    if (Array.isArray(locationData)) {
      return locationData
        .map((loc) => {
          const { locationName, streetAddress, city, state, zip } = loc;
          return [locationName, streetAddress, city, state, zip]
            .filter(Boolean)
            .join(", ");
        })
        .join(" | ");
    } else if (locationData && typeof locationData === "object") {
      const { locationName, streetAddress, city, state, zip } = locationData;
      return [locationName, streetAddress, city, state, zip]
        .filter(Boolean)
        .join(", ");
    }
    return locationData || "";
  };

  useEffect(() => {
    // Retrieve the orderId from sessionStorage
    const orderId = sessionStorage.getItem("selectedOrderId");
    if (!orderId) {
      navigate("/order-history");
      return;
    }

    const fetchOrderData = async () => {
      setIsLoading(true);
      try {
        const response = await fetch(
          `/api/get-order?orderId=${encodeURIComponent(orderId)}`
        );
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        const result = data.result || {};

        // Attempt to parse PickupAddress and DeliveryAddress JSON fields (if stored)
        try {
          if (result.PickupAddress) {
            // We don't need the parsed data in a separate variable
            // so we store them directly into editData's "pickups" below
            JSON.parse(result.PickupAddress);
          }
          if (result.DeliveryAddress) {
            // Same note as above
            JSON.parse(result.DeliveryAddress);
          }
        } catch (err) {
          console.error("Error parsing address fields:", err);
        }

        setEditData({
          orderNumber: result.customerOrderNo || "",
          dbOrderNumber: result.orderId || "",
          pickupDate: result.pickupDate || "",
          pickupLocation: formatLocation(result.pickupLocation),
          deliveryDate: result.deliveryDate || "",
          deliveryLocation: formatLocation(result.deliveryLocation),
          rate: result.rate ? parseFloat(result.rate) : 0,
          priceGiven: result.priceGiven ? parseFloat(result.priceGiven) : 0,
          currency: result.currency ? result.currency.toUpperCase() : "",
          tripType: result.tripType || "",
          equipment: result.carrierOrEquipment || "",
          accountingEmail:
            result.accountingEmail ||
            result.CustomerCompany?.accountingEmail ||
            "",
          dispatcherEmail:
            result.dispatchEmail || result.CustomerCompany?.dispatchEmail || "",
          pickups: result.pickupLocation || [],
          deliveries: result.deliveryLocation || [],
          loadGivingCompany: {
            companyName: result.CustomerCompany?.name || "",
            streetAddress: result.CustomerCompany?.address || "",
            city: result.CustomerCompany?.city || "",
            state: result.CustomerCompany?.state || "",
            zip: result.CustomerCompany?.zip || "",
            phoneNumber: result.CustomerCompany?.phoneNumber || "",
            organizationId: result.CustomerCompany?.organizationId || "",
          },
          pdfUrl: result.pdf || null,
          dateAdded: result.dateAdded || "",
          truckId: result.truckId || "",
          trailerId: result.trailerId || "",
          driverId: result.driverId || "",
          outsourceCompany: result.outsourceCompanyId || "",
          status: result.status || "Pending",
        });

        // Set selected values for dropdowns
        setSelectedTruck(result.truckId || "");
        setSelectedTrailer(result.trailerId || "");
        setSelectedDriver({ driverId: result.driverId || "", name: "" });
      } catch (error) {
        console.error("Failed to fetch order data:", error);
        alert(`Error fetching order data: ${error.message}`);
        navigate("/order-history");
      } finally {
        setIsLoading(false);
      }
    };

    fetchOrderData();
  }, [navigate]);

  // Once we have the organizationId from loadGivingCompany, fetch truck, trailer, and driver options
  useEffect(() => {
    if (!editData || !editData.loadGivingCompany.organizationId) return;
    const organizationId = editData.loadGivingCompany.organizationId;

    async function fetchTruckOptions() {
      try {
        const response = await fetch(
          `/api/fetch-trucks?organizationId=${encodeURIComponent(
            organizationId
          )}`
        );
        if (response.ok) {
          const data = await response.json();
          setTruckOptions(data.result || []);
        }
      } catch (error) {
        console.error("Error fetching truck options:", error);
      }
    }

    async function fetchTrailerOptions() {
      try {
        const response = await fetch(
          `/api/fetch-trailers?organizationId=${encodeURIComponent(
            organizationId
          )}`
        );
        if (response.ok) {
          const data = await response.json();
          setTrailerOptions(data.result || []);
        }
      } catch (error) {
        console.error("Error fetching trailer options:", error);
      }
    }

    async function fetchDriverOptions() {
      try {
        const response = await fetch(
          `/api/fetch-drivers?organizationId=${encodeURIComponent(
            organizationId
          )}`
        );
        if (response.ok) {
          const data = await response.json();
          setDriverOptions(data.result || []);
          // Update selected driver name if possible
          if (selectedDriver.driverId) {
            const found = (data.result || []).find(
              (d) => d.driverId === selectedDriver.driverId
            );
            if (found) {
              setSelectedDriver({ driverId: found.driverId, name: found.name });
            }
          }
        }
      } catch (error) {
        console.error("Error fetching driver options:", error);
      }
    }

    fetchTruckOptions();
    fetchTrailerOptions();
    fetchDriverOptions();
  }, [editData, selectedDriver.driverId]);

  const handleSave = async () => {
    if (!editData) return;
    setIsLoading(true);
    try {
      // 1) If a new file was selected, remove the old one and upload the new file.
      let updatedPdfUrl = editData.pdfUrl;
      if (newFile) {
        if (editData.pdfUrl) {
          try {
            const oldFilePathPart = editData.pdfUrl.split("/pdf-storage/")[1];
            const oldFilePath = decodeURIComponent(oldFilePathPart);
            const { error: removeError } = await supabase.storage
              .from("pdf-storage")
              .remove([oldFilePath]);
            if (removeError) {
              console.error("Error removing old file:", removeError.message);
            } else {
              console.log("Old file removed successfully:", oldFilePath);
            }
          } catch (removeErr) {
            console.error("Error removing old file:", removeErr.message);
          }
        }
        updatedPdfUrl = await uploadFileToSupabase(newFile, editData);
      }

      const dataToSend = {
        customerOrderNo: editData.orderNumber,
        orderId: editData.dbOrderNumber,
        pickupDate: editData.pickupDate,
        pickupLocation: editData.pickupLocation,
        deliveryDate: editData.deliveryDate,
        deliveryLocation: editData.deliveryLocation,
        price: editData.rate, // rate mapped from backend price field
        currency: editData.currency,
        priceKept: editData.priceGiven,
        tripType: editData.tripType,
        carrierOrEquipment: editData.equipment,
        status: editData.status,
        accountingEmail: editData.accountingEmail,
        dispatchEmail: editData.dispatcherEmail,
        pickupAddress: JSON.stringify(editData.pickups || []),
        deliveryAddress: JSON.stringify(editData.deliveries || []),
        CompanyAddress: JSON.stringify(editData.loadGivingCompany || {}),
        dateAdded: editData.dateAdded,
        pdf: updatedPdfUrl,
        // Use the selected dropdown values
        truckId: selectedTruck,
        trailerId: selectedTrailer,
        driverId: selectedDriver.driverId,
        outsourceCompany: editData.outsourceCompany,
      };

      const response = await fetch("/api/update-order", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(dataToSend),
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const updatedData = await response.json();
      console.log("Order updated:", updatedData);
      alert("Order updated successfully!");
      if (fromPage === "invoicing") {
        navigate(`/invoicing/${editData.dbOrderNumber}`);
      } else {
        navigate("/order-history");
      }
    } catch (error) {
      console.error("Failed to update order:", error);
      alert(`Error updating order: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  const uploadFileToSupabase = async (file, editDataObj) => {
    try {
      const fileName = file.name;
      const extension = fileName.includes(".")
        ? fileName.split(".").pop().toLowerCase()
        : "pdf";
      const sanitizedCompanyName = editDataObj.loadGivingCompany.companyName
        .trim()
        .replace(/\s+/g, "-");
      const sanitizedOrderNumber = editDataObj.orderNumber
        .toString()
        .replace(/\s+/g, "-");
      const sanitizedRate = editDataObj.rate.toString().replace(".", "-");
      const storageFileName = `${sanitizedCompanyName}-${sanitizedOrderNumber}-${sanitizedRate}.${extension}`;
      const { data: uploadData, error: uploadError } = await supabase.storage
        .from("pdf-storage")
        .upload(`MG Transport LC/${storageFileName}`, file, {
          upsert: true,
        });
      if (uploadError || !uploadData) {
        throw new Error(
          `Error uploading file to Supabase: ${uploadError?.message}`
        );
      }
      const { data: publicURLData } = supabase.storage
        .from("pdf-storage")
        .getPublicUrl(`MG Transport LC/${storageFileName}`);
      if (!publicURLData || !publicURLData.publicUrl) {
        throw new Error("Unable to retrieve public URL from Supabase.");
      }
      return publicURLData.publicUrl;
    } catch (err) {
      console.error("uploadFileToSupabase error:", err);
      throw err;
    }
  };

  const handleFileButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files?.[0];
    if (!file) return;
    setNewFile(file);
  };

  const handleDownloadFile = async () => {
    const fileUrl = editData?.pdfUrl;
    if (!fileUrl) return;
    try {
      const response = await fetch(fileUrl);
      if (!response.ok) {
        throw new Error(`Error fetching file: ${response.statusText}`);
      }
      const blob = await response.blob();
      const fileURL = URL.createObjectURL(blob);
      const contentType = response.headers.get("content-type") || "";
      let fileExtension = "pdf";
      if (contentType.includes("pdf")) {
        fileExtension = "pdf";
      } else if (contentType.includes("image/png")) {
        fileExtension = "png";
      } else if (contentType.includes("image/jpeg")) {
        fileExtension = "jpg";
      } else if (
        contentType.includes("application/msword") ||
        contentType.includes(
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        )
      ) {
        fileExtension = "docx";
      }
      let suggestedFilename = `${
        editData.loadGivingCompany.companyName || "order"
      }-details.${fileExtension}`;
      const contentDisposition = response.headers.get("content-disposition");
      if (contentDisposition && contentDisposition.includes("filename=")) {
        const match = contentDisposition.match(/filename="?(.+)"?/);
        if (match?.[1]) {
          suggestedFilename = match[1];
        }
      }
      const link = document.createElement("a");
      link.href = fileURL;
      link.download = suggestedFilename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(fileURL);
    } catch (error) {
      console.error("Error downloading file:", error);
      alert(`Error downloading file: ${error.message}`);
    }
  };

  if (isLoading) {
    return (
      <div className="container">
        <SideNavBar />
        <div className="edit-window">
          <Toolbar style={{ minHeight: "83px" }} />
          <div className="edit-body">
            <div className="spinner-container">
              <Spinner />
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (!editData) {
    return (
      <div className="container">
        <SideNavBar />
        <div className="edit-window">
          <Toolbar style={{ minHeight: "83px" }} />
          <div className="edit-body">
            <p>No data to edit.</p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="container">
      <SideNavBar />
      <div className="edit-window">
        <Toolbar style={{ minHeight: "83px" }} />
        <div className="edit-body">
          <OrderDetails
            orderNumber={editData.orderNumber}
            setOrderNumber={(val) =>
              setEditData((prev) => ({ ...prev, orderNumber: val }))
            }
            pickupDate={editData.pickupDate}
            setPickupDate={(val) =>
              setEditData((prev) => ({ ...prev, pickupDate: val }))
            }
            pickupLocation={editData.pickupLocation}
            setPickupLocation={(val) =>
              setEditData((prev) => ({ ...prev, pickupLocation: val }))
            }
            deliveryDate={editData.deliveryDate}
            setDeliveryDate={(val) =>
              setEditData((prev) => ({ ...prev, deliveryDate: val }))
            }
            deliveryLocation={editData.deliveryLocation}
            setDeliveryLocation={(val) =>
              setEditData((prev) => ({ ...prev, deliveryLocation: val }))
            }
            rate={editData.rate}
            setRate={(val) => setEditData((prev) => ({ ...prev, rate: val }))}
            currency={editData.currency}
            setCurrency={(val) =>
              setEditData((prev) => ({ ...prev, currency: val }))
            }
            priceGiven={editData.priceGiven}
            setPriceGiven={(val) =>
              setEditData((prev) => ({ ...prev, priceGiven: val }))
            }
            tripType={editData.tripType}
            setTripType={(val) =>
              setEditData((prev) => ({ ...prev, tripType: val }))
            }
            equipment={editData.equipment}
            setEquipment={(val) =>
              setEditData((prev) => ({ ...prev, equipment: val }))
            }
            status={editData.status}
            setStatus={(val) =>
              setEditData((prev) => ({ ...prev, status: val }))
            }
            accountingEmail={editData.accountingEmail}
            setAccountingEmail={(val) =>
              setEditData((prev) => ({ ...prev, accountingEmail: val }))
            }
            dispatcherEmail={editData.dispatcherEmail}
            setDispatcherEmail={(val) =>
              setEditData((prev) => ({ ...prev, dispatcherEmail: val }))
            }
            pickups={editData.pickups}
            setPickups={(val) =>
              setEditData((prev) => ({ ...prev, pickups: val }))
            }
            deliveries={editData.deliveries}
            setDeliveries={(val) =>
              setEditData((prev) => ({ ...prev, deliveries: val }))
            }
            loadGivingCompany={editData.loadGivingCompany}
            setLoadGivingCompany={(val) =>
              setEditData((prev) => ({ ...prev, loadGivingCompany: val }))
            }
            // Pass dropdown option lists and selected IDs
            truckOptions={truckOptions}
            trailerOptions={trailerOptions}
            driverOptions={driverOptions}
            truckId={selectedTruck}
            setTruckId={setSelectedTruck}
            trailerId={selectedTrailer}
            setTrailerId={setSelectedTrailer}
            driverId={selectedDriver.driverId}
            setDriverId={(id) =>
              setSelectedDriver((prev) => ({ ...prev, driverId: id }))
            }
            outsourceCompany={editData.outsourceCompany}
            setOutsourceCompany={(val) =>
              setEditData((prev) => ({ ...prev, outsourceCompany: val }))
            }
            onSave={handleSave}
          />

          <div style={{ marginTop: "20px" }}>
            {editData.pdfUrl ? (
              <Button
                variant="contained"
                color="success"
                onClick={handleDownloadFile}
                style={{ marginRight: "10px" }}
              >
                Download File
              </Button>
            ) : (
              <p>No file available for this order.</p>
            )}

            <Button
              variant="contained"
              color="primary"
              onClick={handleFileButtonClick}
            >
              Upload New File
            </Button>
            <input
              type="file"
              accept="
                application/pdf,
                image/*,
                application/msword,
                application/vnd.openxmlformats-officedocument.wordprocessingml.document
              "
              style={{ display: "none" }}
              ref={fileInputRef}
              onChange={handleFileChange}
            />

            {newFile && (
              <div style={{ marginTop: "10px" }}>
                <strong>New file selected:</strong> {newFile.name}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditOrder;
