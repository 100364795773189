import React, { useState, useEffect } from "react";
import {
  Card,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Toolbar,
  Typography,
  Pagination,
  Paper,
  Box,
  ToggleButton,
  ToggleButtonGroup,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import Spinner from "./Spinner";
import SideNavBar from "./SideNavBar";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import "./Invoices.css";

function Invoices() {
  // State variables
  const [invoices, setInvoices] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  // Default to "Paid" or "Unpaid". Must match the values you pass to toggle buttons
  const [invoiceFilter, setInvoiceFilter] = useState("Paid");
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  // Payment Dialog
  const [isPaymentDialogOpen, setIsPaymentDialogOpen] = useState(false);
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const [newPaymentDate, setNewPaymentDate] = useState("");

  // 1) Fetch the invoices whenever the filter changes
  useEffect(() => {
    async function fetchInvoices() {
      const storedOrganizationId = sessionStorage.getItem(
        "storageOrganizationId"
      );
      setIsLoading(true);
      try {
        const response = await fetch(
          `/api/fetch-invoices?organizationId=${encodeURIComponent(
            storedOrganizationId
          )}&type=${encodeURIComponent(invoiceFilter)}`,
          {
            method: "GET",
            headers: { "Content-Type": "application/json" },
          }
        );
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();

        // data.result is an array of objects:
        // [
        //   {
        //     "invoiceId": 3,
        //     "status": "Paid",
        //     "invoiceDate": "2025-03-06",
        //     "paymentDate": "2025-03-06",
        //     "orderId": 88,
        //     "organizationId": 2
        //   }
        //   ...
        // ]
        setInvoices(data.result || []);
      } catch (error) {
        console.error("Failed to fetch invoice data:", error);
      } finally {
        setIsLoading(false);
      }
    }

    fetchInvoices();
  }, [invoiceFilter]);

  // 2) Toggle "Paid" / "Unpaid"
  const handleInvoiceFilter = (event, newFilter) => {
    if (newFilter !== null) {
      setInvoiceFilter(newFilter);
      setCurrentPage(1);
    }
  };

  // 3) Search logic
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1);
  };

  // 4) Filter the invoice data
  const filteredItems = invoices.filter((inv) => {
    const lowerSearch = searchTerm.toLowerCase();
    const invIdString = inv.invoiceId?.toString().toLowerCase() || "";
    const orderIdString = inv.orderId?.toString().toLowerCase() || "";
    const invoiceDateString = inv.invoiceDate?.toLowerCase() || "";
    const paymentDateString = inv.paymentDate?.toLowerCase() || "";
    const statusString = inv.status?.toLowerCase() || "";

    return (
      invIdString.includes(lowerSearch) ||
      orderIdString.includes(lowerSearch) ||
      invoiceDateString.includes(lowerSearch) ||
      paymentDateString.includes(lowerSearch) ||
      statusString.includes(lowerSearch)
    );
  });

  // 5) Pagination
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredItems.slice(indexOfFirstItem, indexOfLastItem);

  // 6) MUI Dark Theme
  const darkTheme = createTheme({
    palette: {
      mode: "dark",
      background: { default: "#000000", paper: "#000000" },
      text: { primary: "#ffffff", secondary: "#aaaaaa" },
      primary: { main: "#3c94fc" },
    },
  });

  // 7) Handle Mark Paid
  const handleMarkPaidClick = (invoice) => {
    setSelectedInvoice(invoice);
    setNewPaymentDate("");
    setIsPaymentDialogOpen(true);
  };

  const handleConfirmPayment = async () => {
    if (!selectedInvoice) return;
    try {
      const response = await fetch("/api/update-payment-date", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          invoiceId: selectedInvoice.invoiceId,
          paymentDate: newPaymentDate,
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      alert("Payment date updated successfully!");

      // Locally update paymentDate and status to "Paid"
      setInvoices((prev) =>
        prev.map((inv) =>
          inv.invoiceId === selectedInvoice.invoiceId
            ? { ...inv, paymentDate: newPaymentDate, status: "Paid" }
            : inv
        )
      );
    } catch (error) {
      console.error("Failed to update payment date:", error);
      alert(`Error updating payment info: ${error.message}`);
    } finally {
      setIsPaymentDialogOpen(false);
      setSelectedInvoice(null);
    }
  };

  const handleCancelPayment = () => {
    setIsPaymentDialogOpen(false);
    setSelectedInvoice(null);
  };

  return (
    <ThemeProvider theme={darkTheme}>
      <div className="invoices-history-container">
        <SideNavBar />
        <div className="invoices-history-window">
          <Toolbar style={{ minHeight: "83px" }} />
          <div className="invoices-history-body">
            <Card sx={{ maxWidth: "100%", overflowX: "auto", m: 2 }}>
              <Typography
                variant="h4"
                component="div"
                sx={{
                  m: 2,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                Invoices
              </Typography>

              {/* Toggle for Paid / Unpaid */}
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                width="100%"
                sx={{ mb: 2 }}
              >
                <ToggleButtonGroup
                  value={invoiceFilter}
                  exclusive
                  onChange={handleInvoiceFilter}
                  aria-label="invoice filter"
                >
                  <ToggleButton value="Paid" aria-label="Paid">
                    Paid
                  </ToggleButton>
                  <ToggleButton value="Unpaid" aria-label="Unpaid">
                    Unpaid
                  </ToggleButton>
                </ToggleButtonGroup>
              </Box>

              {/* Search Field */}
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                width="100%"
              >
                <TextField
                  variant="outlined"
                  value={searchTerm}
                  onChange={handleSearchChange}
                  placeholder="Search invoiceId, orderId, date, status"
                  margin="normal"
                  sx={{
                    m: 2,
                    width: "50%",
                    input: { color: "#fff" },
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "#555",
                      },
                      "&:hover fieldset": {
                        borderColor: "#777",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#3c94fc",
                      },
                    },
                  }}
                  InputLabelProps={{ style: { color: "#fff" } }}
                />
              </Box>

              {/* Table */}
              {isLoading ? (
                <Box
                  sx={{
                    m: 2,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  <Spinner sx={{ color: "#3c94fc" }} />
                </Box>
              ) : (
                <TableContainer component={Paper}>
                  <Table stickyHeader aria-label="invoices table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Invoice ID</TableCell>
                        <TableCell>Order ID</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>Invoice Date</TableCell>
                        {/* Show PaymentDate only if filter=Paid */}
                        {invoiceFilter === "Paid" && (
                          <TableCell>Payment Date</TableCell>
                        )}
                        {/* Action column if filter=Unpaid */}
                        {invoiceFilter === "Unpaid" && (
                          <TableCell>Action</TableCell>
                        )}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {currentItems.length > 0 ? (
                        currentItems.map((inv) => (
                          <TableRow key={inv.invoiceId}>
                            <TableCell>{inv.invoiceId}</TableCell>
                            <TableCell>{inv.orderId}</TableCell>
                            <TableCell>{inv.status}</TableCell>
                            <TableCell>
                              {inv.invoiceDate
                                ? new Date(inv.invoiceDate).toLocaleDateString()
                                : "N/A"}
                            </TableCell>

                            {/* Payment Date if "Paid" */}
                            {invoiceFilter === "Paid" && (
                              <TableCell>
                                {inv.paymentDate
                                  ? new Date(
                                      inv.paymentDate
                                    ).toLocaleDateString()
                                  : ""}
                              </TableCell>
                            )}

                            {/* Mark Paid if "Unpaid" */}
                            {invoiceFilter === "Unpaid" && (
                              <TableCell>
                                <Button
                                  variant="contained"
                                  color="primary"
                                  onClick={() => handleMarkPaidClick(inv)}
                                >
                                  Mark Paid
                                </Button>
                              </TableCell>
                            )}
                          </TableRow>
                        ))
                      ) : (
                        <TableRow>
                          <TableCell colSpan={5} align="center">
                            No invoices found
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>

                  {/* Pagination */}
                  <Pagination
                    count={Math.ceil(filteredItems.length / itemsPerPage)}
                    page={currentPage}
                    onChange={(event, value) => setCurrentPage(value)}
                    color="primary"
                    sx={{
                      py: 2,
                      display: "flex",
                      justifyContent: "center",
                    }}
                    showFirstButton
                    showLastButton
                  />
                </TableContainer>
              )}

              {/* Payment Confirmation Dialog */}
              <Dialog
                open={isPaymentDialogOpen}
                onClose={handleCancelPayment}
                aria-labelledby="payment-dialog-title"
              >
                <DialogTitle id="payment-dialog-title">
                  Mark as Paid
                </DialogTitle>
                <DialogContent>
                  <Typography gutterBottom>
                    Invoice ID #{selectedInvoice?.invoiceId} — Order ID #
                    {selectedInvoice?.orderId}
                  </Typography>
                  <Typography>
                    You are about to mark this invoice as <strong>Paid</strong>.
                  </Typography>
                  <Box sx={{ mt: 2 }}>
                    <TextField
                      label="Payment Date"
                      type="date"
                      value={newPaymentDate}
                      onChange={(e) => setNewPaymentDate(e.target.value)}
                      sx={{ width: "100%", mb: 2 }}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Box>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleCancelPayment} color="secondary">
                    Cancel
                  </Button>
                  <Button
                    onClick={handleConfirmPayment}
                    color="primary"
                    variant="contained"
                  >
                    Confirm
                  </Button>
                </DialogActions>
              </Dialog>
            </Card>
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
}

export default Invoices;
