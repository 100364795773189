// OrderHistory.js
import React, { useState, useEffect } from "react";
import {
  Card,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Toolbar,
  Typography,
  Pagination,
  Paper,
  Box,
  ToggleButton,
  ToggleButtonGroup,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import Spinner from "./Spinner";
import SideNavBar from "./SideNavBar";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate } from "react-router-dom";
import "./OrderHistory.css";

function OrderHistory() {
  const [orders, setOrders] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  // Using "In Progress" to match what’s stored
  const [statusFilter, setStatusFilter] = useState("In Progress");
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const navigate = useNavigate();

  // State for Delete Confirmation Dialog
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);

  // Updated helper function to format location values
  const formatLocation = (location) => {
    if (Array.isArray(location)) {
      // Format each object in the array and join with a separator
      return location
        .map((loc) => {
          const { locationName, streetAddress, city, state, zip } = loc;
          return [locationName, streetAddress, city, state, zip]
            .filter(Boolean)
            .join(", ");
        })
        .join(" | ");
    } else if (location && typeof location === "object") {
      const { locationName, streetAddress, city, state, zip } = location;
      return [locationName, streetAddress, city, state, zip]
        .filter(Boolean)
        .join(", ");
    }
    return location;
  };

  useEffect(() => {
    const fetchOrders = async () => {
      const orgId = sessionStorage.getItem("storageOrganizationId");
      if (!orgId) {
        console.error("Organization ID not found in session storage");
        return;
      }
      setIsLoading(true);
      try {
        const response = await fetch(
          `/api/fetch-orders?orgId=${encodeURIComponent(
            orgId
          )}&status=${encodeURIComponent(statusFilter)}`,
          {
            method: "GET",
            headers: { "Content-Type": "application/json" },
          }
        );
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        const normalizedData = data.result.map((item) => ({
          ...item,
          status: item.status || "None",
        }));
        setOrders(normalizedData);
      } catch (error) {
        console.error("Failed to fetch orders data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchOrders();
  }, [statusFilter]);

  const handleStatusFilter = (event, newStatus) => {
    if (newStatus !== null) {
      setStatusFilter(newStatus);
      setCurrentPage(1);
    }
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1);
  };

  const filteredOrders = orders.filter((order) => {
    const searchLower = searchTerm.toLowerCase();
    return (
      order.customerOrderNo?.toString().toLowerCase().includes(searchLower) ||
      order.company_name?.toLowerCase().includes(searchLower) ||
      order.pickupDate?.toLowerCase().includes(searchLower) ||
      formatLocation(order.pickupLocation)
        .toLowerCase()
        .includes(searchLower) ||
      order.deliveryDate?.toLowerCase().includes(searchLower) ||
      formatLocation(order.deliveryLocation)
        .toLowerCase()
        .includes(searchLower) ||
      order.tripType?.toLowerCase().includes(searchLower) ||
      order.status?.toLowerCase().includes(searchLower)
    );
  });

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredOrders.slice(indexOfFirstItem, indexOfLastItem);

  const darkTheme = createTheme({
    palette: {
      mode: "dark",
      background: { default: "#000", paper: "#000" },
      text: { primary: "#fff", secondary: "#aaa" },
      primary: { main: "#3c94fc" },
      secondary: { main: "#f50057" },
    },
    components: {
      MuiPagination: {
        styleOverrides: {
          root: { backgroundColor: "#000", padding: "16px 0" },
          ul: { justifyContent: "center" },
        },
      },
      MuiPaginationItem: {
        styleOverrides: {
          root: {
            color: "#fff",
            backgroundColor: "#000",
            "&.Mui-selected": { backgroundColor: "#3c94fc", color: "#fff" },
            "&:hover": { backgroundColor: "#111" },
          },
        },
      },
    },
  });

  const handleEdit = (order) => {
    sessionStorage.setItem("selectedOrderId", order.orderId);
    navigate("/edit-order", { state: { from: "orderHistory" } });
  };

  const handleDeleteClick = (order) => {
    setSelectedOrder(order);
    setIsDeleteDialogOpen(true);
  };

  const handleConfirmDelete = async () => {
    if (!selectedOrder) return;
    const orderNumber = selectedOrder.customerOrderNo;
    setIsLoading(true);
    try {
      const response = await fetch("/api/delete-order", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ orderNumber }),
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      alert("Order deleted successfully!");
      setOrders((prev) =>
        prev.filter((order) => order.customerOrderNo !== orderNumber)
      );
    } catch (error) {
      console.error("Failed to delete order:", error);
      alert(`Error deleting order: ${error.message}`);
    } finally {
      setIsLoading(false);
      setIsDeleteDialogOpen(false);
      setSelectedOrder(null);
    }
  };

  const handleCancelDelete = () => {
    setIsDeleteDialogOpen(false);
    setSelectedOrder(null);
  };

  return (
    <ThemeProvider theme={darkTheme}>
      <div className="order-history-container">
        <SideNavBar />
        <div className="order-history-window">
          <Toolbar style={{ minHeight: "83px" }} />
          <div className="order-history-body">
            <Card sx={{ maxWidth: "100%", overflowX: "auto", m: 2 }}>
              <Typography
                variant="h4"
                component="div"
                sx={{
                  m: 2,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                Order History
              </Typography>

              {/* Status Filter */}
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                width="100%"
                sx={{ mb: 2 }}
              >
                <ToggleButtonGroup
                  value={statusFilter}
                  exclusive
                  onChange={handleStatusFilter}
                  aria-label="status filter"
                >
                  <ToggleButton value="In Progress" aria-label="In Progress">
                    In Progress
                  </ToggleButton>
                  <ToggleButton value="None" aria-label="None">
                    None
                  </ToggleButton>
                  <ToggleButton value="Delivered" aria-label="Delivered">
                    Delivered
                  </ToggleButton>
                  <ToggleButton value="All" aria-label="All">
                    All
                  </ToggleButton>
                </ToggleButtonGroup>
              </Box>

              {/* Search Field */}
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                width="100%"
              >
                <TextField
                  variant="outlined"
                  value={searchTerm}
                  onChange={handleSearchChange}
                  placeholder="Search by any column"
                  margin="normal"
                  sx={{
                    m: 2,
                    width: "50%",
                    input: { color: "#fff" },
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": { borderColor: "#555" },
                      "&:hover fieldset": { borderColor: "#777" },
                      "&.Mui-focused fieldset": { borderColor: "#3c94fc" },
                    },
                  }}
                  InputLabelProps={{ style: { color: "#fff" } }}
                />
              </Box>

              {isLoading ? (
                <Box
                  sx={{
                    m: 2,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  <Spinner sx={{ color: "#3c94fc" }} />
                </Box>
              ) : (
                <TableContainer component={Paper}>
                  <Table stickyHeader aria-label="order history table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Order Number</TableCell>
                        <TableCell>Company</TableCell>
                        <TableCell>Pickup Date</TableCell>
                        <TableCell>Pickup Location</TableCell>
                        <TableCell>Delivery Date</TableCell>
                        <TableCell>Delivery Location</TableCell>
                        <TableCell>Trip Type</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {currentItems.length > 0 ? (
                        currentItems.map((order, index) => (
                          <TableRow key={index}>
                            <TableCell>{order.customerOrderNo}</TableCell>
                            <TableCell>{order.CustomerCompany.name}</TableCell>
                            <TableCell>
                              {new Date(order.pickupDate).toLocaleDateString()}
                            </TableCell>
                            <TableCell>
                              {formatLocation(order.pickupLocation)}
                            </TableCell>
                            <TableCell>
                              {new Date(
                                order.deliveryDate
                              ).toLocaleDateString()}
                            </TableCell>
                            <TableCell>
                              {formatLocation(order.deliveryLocation)}
                            </TableCell>
                            <TableCell>{order.tripType}</TableCell>
                            <TableCell>{order.status}</TableCell>
                            <TableCell>
                              <IconButton
                                onClick={() => handleEdit(order)}
                                sx={{ color: "#fff" }}
                                aria-label="edit"
                              >
                                <EditIcon />
                              </IconButton>
                              <IconButton
                                onClick={() => handleDeleteClick(order)}
                                sx={{ color: "#fff" }}
                                aria-label="delete"
                              >
                                <DeleteIcon />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))
                      ) : (
                        <TableRow>
                          <TableCell colSpan={9} align="center">
                            No data found
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                  <Pagination
                    count={Math.ceil(filteredOrders.length / itemsPerPage)}
                    page={currentPage}
                    onChange={(event, value) => setCurrentPage(value)}
                    color="primary"
                    sx={{ py: 2, display: "flex", justifyContent: "center" }}
                    showFirstButton
                    showLastButton
                  />
                </TableContainer>
              )}

              {/* Delete Confirmation Dialog */}
              <Dialog
                open={isDeleteDialogOpen}
                onClose={handleCancelDelete}
                aria-labelledby="delete-dialog-title"
              >
                <DialogTitle id="delete-dialog-title">
                  Confirm Deletion
                </DialogTitle>
                <DialogContent>
                  Are you sure you want to delete Order#{" "}
                  {selectedOrder?.customerOrderNo}?
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleCancelDelete} color="secondary">
                    Cancel
                  </Button>
                  <Button
                    onClick={handleConfirmDelete}
                    color="primary"
                    variant="contained"
                  >
                    Delete
                  </Button>
                </DialogActions>
              </Dialog>
            </Card>
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
}

export default OrderHistory;
