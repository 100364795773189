import React, { useState, useEffect } from "react";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { Toolbar } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Spinner from "./Spinner";
import {
  Card,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Button,
  Box,
  Typography,
  Pagination,
  Paper,
} from "@mui/material";
import SideNavBar from "./SideNavBar";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import "./Broker.css";

function TrucksManagement() {
  const [trucksInfo, setTrucksInfo] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [organizationId, setOrganizationId] = useState("");

  // State for Add Truck Dialog
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const openAddModal = () => setIsAddModalOpen(true);
  const closeAddModal = () => setIsAddModalOpen(false);

  // State for Edit Truck Dialog
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const closeEditModal = () => setIsEditModalOpen(false);

  // State for new truck details (Add Truck)
  const [newTruck, setNewTruck] = useState({
    number: "",
    make: "",
    model: "",
    vin: "",
    licensePlate: "",
  });

  // State for editing truck details (Edit Truck)
  const [editTruck, setEditTruck] = useState({
    truckId: "",
    number: "",
    make: "",
    model: "",
    vin: "",
    licensePlate: "",
  });

  // Fetch trucks data
  const fetchData = async () => {
    const storedOrganizationId = sessionStorage.getItem(
      "storageOrganizationId"
    );
    if (storedOrganizationId) setOrganizationId(storedOrganizationId);

    setIsLoading(true);
    try {
      const response = await fetch(
        `/api/fetch-trucks?organizationId=${encodeURIComponent(
          storedOrganizationId
        )}`,
        {
          method: "GET",
          headers: { "Content-Type": "application/json" },
        }
      );
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      setTrucksInfo(data.result);
    } catch (error) {
      console.error("Failed to fetch Trucks information:", error);
      alert("Failed to fetch Trucks information. Please try again later.");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleEditTruck = (truckData) => {
    setEditTruck(truckData);
    setIsEditModalOpen(true);
  };

  // Handle delete truck
  const handleDeleteTruck = async (truckId, number) => {
    const confirmDelete = window.confirm(
      `Are you sure you want to delete Truck: ${number}?`
    );
    if (!confirmDelete) return;

    setIsLoading(true);
    try {
      const response = await fetch(`/api/delete-truck`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ truckId }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      alert("Truck deleted successfully!");
      await fetchData();
    } catch (error) {
      console.error("Failed to delete Truck:", error);
      alert(`Error deleting Truck: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  // Handle input change for new truck (Add Truck)
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewTruck((prev) => ({ ...prev, [name]: value }));
  };

  // Handle adding a new truck
  const handleAddTruck = async () => {
    if (
      !newTruck.number ||
      !newTruck.make ||
      !newTruck.model ||
      !newTruck.vin ||
      !newTruck.licensePlate
    ) {
      alert("All fields are required.");
      return;
    }

    closeAddModal();
    setIsLoading(true);
    try {
      const response = await fetch(`/api/insert-truck`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ ...newTruck, organizationId }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(
          errorData.error || `HTTP error! Status: ${response.status}`
        );
      }

      alert("Truck added successfully!");
      await fetchData();
      setNewTruck({
        number: "",
        make: "",
        model: "",
        vin: "",
        licensePlate: "",
      });
    } catch (error) {
      console.error("Failed to add Truck:", error);
      alert(`Error adding Truck: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  // Handle input change for edit truck (Edit Truck)
  const handleEditInputChange = (e) => {
    const { name, value } = e.target;
    setEditTruck((prev) => ({ ...prev, [name]: value }));
  };

  // Handle saving the edited truck
  const handleSaveEditTruck = async () => {
    if (
      !editTruck.number ||
      !editTruck.make ||
      !editTruck.model ||
      !editTruck.vin ||
      !editTruck.licensePlate
    ) {
      alert("All fields are required.");
      return;
    }

    setIsLoading(true);
    try {
      const response = await fetch(`/api/edit-truck`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ ...editTruck, organizationId }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(
          errorData.error || `HTTP error! Status: ${response.status}`
        );
      }

      const updatedTruck = await response.json();
      setTrucksInfo((prevTrucks) =>
        prevTrucks.map((truck) =>
          truck.truckId === updatedTruck.result[0].truckId
            ? updatedTruck.result[0]
            : truck
        )
      );

      alert("Truck updated successfully!");
      closeEditModal();
    } catch (error) {
      console.error("Failed to edit Truck:", error);
      alert(`Error editing Truck: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  const filteredItems = trucksInfo.filter((item) => {
    const searchLower = searchTerm.toLowerCase();
    return (
      item.number?.toLowerCase().includes(searchLower) ||
      item.make?.toLowerCase().includes(searchLower) ||
      item.model?.toLowerCase().includes(searchLower) ||
      item.vin?.toLowerCase().includes(searchLower) ||
      item.licensePlate?.toLowerCase().includes(searchLower)
    );
  });

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredItems.slice(indexOfFirstItem, indexOfLastItem);

  const darkTheme = createTheme({
    palette: {
      mode: "dark",
      background: {
        default: "#000000",
        paper: "#000000",
      },
      text: {
        primary: "#ffffff",
        secondary: "#aaaaaa",
      },
      primary: {
        main: "#3c94fc",
      },
      secondary: {
        main: "#f50057",
      },
    },
    components: {
      MuiPagination: {
        styleOverrides: {
          root: {
            backgroundColor: "#000000",
            padding: "16px 0",
          },
          ul: {
            justifyContent: "center",
          },
        },
      },
      MuiPaginationItem: {
        styleOverrides: {
          root: {
            color: "#fff",
            backgroundColor: "#000000",
            "&.Mui-selected": {
              backgroundColor: "#3c94fc",
              color: "#fff",
            },
            "&:hover": {
              backgroundColor: "#111111",
            },
          },
        },
      },
    },
  });

  return (
    <ThemeProvider theme={darkTheme}>
      <div className="chat-container">
        <SideNavBar />
        <div className="broker-window">
          <Toolbar style={{ minHeight: "83px" }} />
          <div className="broker-body">
            <Card sx={{ maxWidth: "100%", overflowX: "auto", m: 2 }}>
              <Typography
                variant="h4"
                component="div"
                sx={{
                  m: 2,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                Trucks
              </Typography>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                width="100%"
              >
                <TextField
                  variant="outlined"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  placeholder="Search by Number, Make, Model, VIN, or License Plate"
                  margin="normal"
                  sx={{
                    m: 2,
                    width: "50%",
                    input: { color: "#fff" },
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "#555",
                      },
                      "&:hover fieldset": {
                        borderColor: "#777",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#3c94fc",
                      },
                    },
                  }}
                  InputLabelProps={{
                    style: { color: "#fff" },
                  }}
                />
                <Button
                  onClick={openAddModal}
                  variant="contained"
                  color="primary"
                  className="add-button"
                  sx={{ m: 2 }}
                  style={{
                    backgroundColor: "transparent",
                    color: "#fff",
                    border: "1px solid #3c94fc",
                    borderRadius: "15px",
                    padding: "10px 20px",
                  }}
                >
                  Add Truck
                </Button>
              </Box>

              {isLoading ? (
                <Box
                  sx={{
                    m: 2,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  <Spinner sx={{ color: "#3c94fc" }} />
                </Box>
              ) : (
                <>
                  <TableContainer component={Paper}>
                    <Table stickyHeader aria-label="trucks table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Number</TableCell>
                          <TableCell>Make</TableCell>
                          <TableCell>Model</TableCell>
                          <TableCell>VIN</TableCell>
                          <TableCell>License Plate</TableCell>
                          <TableCell>Action</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {currentItems.map((item) => (
                          <TableRow key={item.truckId}>
                            <TableCell>{item.number}</TableCell>
                            <TableCell>{item.make}</TableCell>
                            <TableCell>{item.model}</TableCell>
                            <TableCell>{item.vin}</TableCell>
                            <TableCell>{item.licensePlate}</TableCell>
                            <TableCell>
                              <IconButton
                                onClick={() => handleEditTruck(item)}
                                sx={{ color: "#fff" }}
                              >
                                <EditIcon />
                              </IconButton>
                              <IconButton
                                onClick={() =>
                                  handleDeleteTruck(item.truckId, item.number)
                                }
                                sx={{ color: "#fff" }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                    <Pagination
                      count={Math.ceil(filteredItems.length / itemsPerPage)}
                      page={currentPage}
                      onChange={(event, value) => setCurrentPage(value)}
                      color="primary"
                      sx={{
                        py: 2,
                        display: "flex",
                        justifyContent: "center",
                      }}
                      showFirstButton
                      showLastButton
                    />
                  </TableContainer>
                </>
              )}

              {/* Add Truck Dialog */}
              <Dialog open={isAddModalOpen} onClose={closeAddModal} fullWidth>
                <DialogTitle>Add New Truck</DialogTitle>
                <DialogContent>
                  {isLoading ? (
                    <Box
                      sx={{
                        m: 2,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        textAlign: "center",
                      }}
                    >
                      <Spinner sx={{ color: "#3c94fc" }} />
                    </Box>
                  ) : (
                    <Box
                      component="form"
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        m: 2,
                      }}
                    >
                      <label htmlFor="number" className="input-label">
                        Number:
                      </label>
                      <TextField
                        name="number"
                        variant="standard"
                        value={newTruck.number}
                        onChange={handleInputChange}
                        required
                        InputLabelProps={{
                          style: { color: "#fff" },
                        }}
                        sx={{
                          input: { color: "#fff" },
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              borderColor: "#555",
                            },
                            "&:hover fieldset": {
                              borderColor: "#777",
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: "#3c94fc",
                            },
                          },
                        }}
                        margin="dense"
                      />
                      <label htmlFor="make" className="input-label">
                        Make:
                      </label>
                      <TextField
                        name="make"
                        variant="standard"
                        value={newTruck.make}
                        onChange={handleInputChange}
                        required
                        InputLabelProps={{
                          style: { color: "#fff" },
                        }}
                        sx={{
                          input: { color: "#fff" },
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              borderColor: "#555",
                            },
                            "&:hover fieldset": {
                              borderColor: "#777",
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: "#3c94fc",
                            },
                          },
                        }}
                        margin="dense"
                      />
                      <label htmlFor="model" className="input-label">
                        Model:
                      </label>
                      <TextField
                        name="model"
                        variant="standard"
                        value={newTruck.model}
                        onChange={handleInputChange}
                        required
                        InputLabelProps={{
                          style: { color: "#fff" },
                        }}
                        sx={{
                          input: { color: "#fff" },
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              borderColor: "#555",
                            },
                            "&:hover fieldset": {
                              borderColor: "#777",
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: "#3c94fc",
                            },
                          },
                        }}
                        margin="dense"
                      />
                      <label htmlFor="vin" className="input-label">
                        VIN:
                      </label>
                      <TextField
                        name="vin"
                        variant="standard"
                        value={newTruck.vin}
                        onChange={handleInputChange}
                        required
                        InputLabelProps={{
                          style: { color: "#fff" },
                        }}
                        sx={{
                          input: { color: "#fff" },
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              borderColor: "#555",
                            },
                            "&:hover fieldset": {
                              borderColor: "#777",
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: "#3c94fc",
                            },
                          },
                        }}
                        margin="dense"
                      />
                      <label htmlFor="licensePlate" className="input-label">
                        License Plate:
                      </label>
                      <TextField
                        name="licensePlate"
                        variant="standard"
                        value={newTruck.licensePlate}
                        onChange={handleInputChange}
                        required
                        InputLabelProps={{
                          style: { color: "#fff" },
                        }}
                        sx={{
                          input: { color: "#fff" },
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              borderColor: "#555",
                            },
                            "&:hover fieldset": {
                              borderColor: "#777",
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: "#3c94fc",
                            },
                          },
                        }}
                        margin="dense"
                      />
                    </Box>
                  )}
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleAddTruck} color="primary">
                    Add Truck
                  </Button>
                  <IconButton
                    onClick={closeAddModal}
                    aria-label="close"
                    style={{
                      position: "absolute",
                      top: "10px",
                      right: "10px",
                      zIndex: 9000,
                      border: "2px solid red",
                    }}
                  >
                    <CloseIcon style={{ color: "red" }} />
                  </IconButton>
                </DialogActions>
              </Dialog>

              {/* Edit Truck Dialog */}
              <Dialog open={isEditModalOpen} onClose={closeEditModal} fullWidth>
                <DialogTitle>Edit Truck</DialogTitle>
                <DialogContent>
                  <Box
                    component="form"
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 2,
                      mt: 1,
                    }}
                  >
                    <TextField
                      label="Number"
                      name="number"
                      variant="outlined"
                      value={editTruck.number}
                      onChange={handleEditInputChange}
                      required
                      InputLabelProps={{
                        style: { color: "#fff" },
                      }}
                      sx={{
                        input: { color: "#fff" },
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderColor: "#555",
                          },
                          "&:hover fieldset": {
                            borderColor: "#777",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "#3c94fc",
                          },
                        },
                      }}
                    />
                    <TextField
                      label="Make"
                      name="make"
                      variant="outlined"
                      value={editTruck.make}
                      onChange={handleEditInputChange}
                      required
                      InputLabelProps={{
                        style: { color: "#fff" },
                      }}
                      sx={{
                        input: { color: "#fff" },
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderColor: "#555",
                          },
                          "&:hover fieldset": {
                            borderColor: "#777",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "#3c94fc",
                          },
                        },
                      }}
                    />
                    <TextField
                      label="Model"
                      name="model"
                      variant="outlined"
                      value={editTruck.model}
                      onChange={handleEditInputChange}
                      required
                      InputLabelProps={{
                        style: { color: "#fff" },
                      }}
                      sx={{
                        input: { color: "#fff" },
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderColor: "#555",
                          },
                          "&:hover fieldset": {
                            borderColor: "#777",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "#3c94fc",
                          },
                        },
                      }}
                    />
                    <TextField
                      label="VIN"
                      name="vin"
                      variant="outlined"
                      value={editTruck.vin}
                      onChange={handleEditInputChange}
                      required
                      InputLabelProps={{
                        style: { color: "#fff" },
                      }}
                      sx={{
                        input: { color: "#fff" },
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderColor: "#555",
                          },
                          "&:hover fieldset": {
                            borderColor: "#777",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "#3c94fc",
                          },
                        },
                      }}
                    />
                    <TextField
                      label="License Plate"
                      name="licensePlate"
                      variant="outlined"
                      value={editTruck.licensePlate}
                      onChange={handleEditInputChange}
                      required
                      InputLabelProps={{
                        style: { color: "#fff" },
                      }}
                      sx={{
                        input: { color: "#fff" },
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderColor: "#555",
                          },
                          "&:hover fieldset": {
                            borderColor: "#777",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "#3c94fc",
                          },
                        },
                      }}
                    />
                  </Box>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={handleSaveEditTruck}
                    color="primary"
                    variant="contained"
                  >
                    Save Changes
                  </Button>
                  <Button
                    onClick={closeEditModal}
                    color="secondary"
                    variant="outlined"
                  >
                    Cancel
                  </Button>
                </DialogActions>
              </Dialog>
            </Card>
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
}

export default TrucksManagement;
