import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

// MSAL (Azure AD B2C) imports
import {
  MsalProvider,
  useMsal,
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import { EventType, InteractionStatus } from "@azure/msal-browser";

// IMPORTANT: Use 'import jwt_decode from "jwt-decode";' (not { jwtDecode }).
import { jwtDecode } from "jwt-decode";

// Auth configuration (adjust paths and exports to match your setup)
import { loginRequest, b2cPolicies } from "./authConfig";

// Your component imports (adjust as needed)
import Navbar from "./Navbar";
import Home from "./Home";
import BrokerList from "./BrokerList";
import EditPod from "./EditPod";
import Paps from "./Paps";
import Pars from "./Pars";
import Edit from "./Edit";
import Chat from "./Chat";
import PodHistory from "./PodHistory";
import Pod from "./Pod";
import PdfEditor from "./PdfEditor";
import MainPage from "./MainPage";
import LoadQuote from "./LoadQuote";
import DocumentGeneration from "./DocumentGeneration";
import EmailGeneration from "./EmailGeneration";
import OrderHistory from "./OrderHistory";
import EditOrder from "./EditOrder";
import Orders from "./Orders";
import Trucks from "./Trucks";
import EditTruck from "./EditTruck";
import TruckPayments from "./TruckPayments";
import Invoices from "./Invoices";
import CreateInvoice from "./CreateInvoice";
import Invoicing from "./Invoicing";
import MsAuthSetup from "./MsAuthSetup";
import CustomsHistory from "./CustomsHistory";
import EditCustoms from "./EditCustoms";
import TrailersManagement from "./TrailersManagement";
import DriversManagement from "./DriverManagement";

// ---------------------------
// AppProvider: Handles the MSAL authentication flow and user/organization logic
// ---------------------------
const AppProvider = ({ children }) => {
  const { instance, inProgress } = useMsal();
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    // Check if we already have an authenticated user in MSAL
    const accounts = instance.getAllAccounts();
    setIsAuthenticated(accounts.length > 0);

    if (accounts && accounts.length > 0) {
      instance.setActiveAccount(accounts[0]);
    }

    // Listen for MSAL events (login, token acquisition, logout, etc.)
    const callbackId = instance.addEventCallback(async (event) => {
      if (
        (event.eventType === EventType.LOGIN_SUCCESS ||
          event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) &&
        event.payload.account
      ) {
        // User has successfully logged in or acquired a new token
        const account = event.payload.account;
        instance.setActiveAccount(account);
        setIsAuthenticated(true);

        if (account && account.idToken) {
          // Decode the ID token to get claims
          const idTokenClaims = jwtDecode(account.idToken);

          // Extract the relevant fields from the token
          const azureUserId = idTokenClaims.sub; // Unique user ID in AAD B2C
          const givenName = idTokenClaims.given_name;
          const familyName = idTokenClaims.family_name;
          const phone = idTokenClaims.extension_PhoneNumber; // If you have a custom attribute set up
          const organizationId = idTokenClaims.extension_OrganizationId;
          console.log(idTokenClaims);

          // 1) Call UpsertUser (does NOT return org details)
          try {
            const userData = await upsertUser({
              azureUserId,
              givenName,
              familyName,
              phone,
              organizationId,
              // Optionally pass an organizationId if you have it from somewhere else.
            });

            // 2) Store user data in sessionStorage
            if (userData) {
              sessionStorage.setItem("storageUserId", userData.azureUserId);
              sessionStorage.setItem("storageFirstName", userData.firstName);
              sessionStorage.setItem("storageLastName", userData.lastName);
              sessionStorage.setItem("storagePhone", userData.phone);
              sessionStorage.setItem(
                "storageOrganizationId",
                userData.organizationId
              );

              // 3) If there's an organizationId, fetch the org details
              if (userData.organizationId) {
                try {
                  const orgData = await getUserOrganization(
                    userData.organizationId
                  );

                  if (orgData && orgData.organization) {
                    sessionStorage.setItem(
                      "storageCompanyName",
                      orgData.organization.name
                    );
                    sessionStorage.setItem(
                      "storageAddress",
                      orgData.organization.streetAddress
                    );
                    sessionStorage.setItem(
                      "storageCity",
                      orgData.organization.city
                    );
                    sessionStorage.setItem(
                      "storageState",
                      orgData.organization.province
                    );
                    sessionStorage.setItem(
                      "storageZip",
                      orgData.organization.postal
                    );
                    sessionStorage.setItem(
                      "storageCompanyLogo",
                      orgData.organization.logo
                    );
                    sessionStorage.setItem(
                      "storageCompanyWebsite",
                      orgData.organization.website
                    );
                    sessionStorage.setItem(
                      "storageCompanyPhone",
                      orgData.organization.phone
                    );
                  }
                } catch (orgErr) {
                  console.error(
                    "Failed to retrieve organization data:",
                    orgErr
                  );
                }
              }
              if (userData.azureUserId) {
                const userInfo = await getUserInformation(userData.azureUserId);
                if (userInfo) {
                  sessionStorage.setItem(
                    "storageUserAccountingEmail",
                    userInfo.user.accountingEmail
                  );
                  sessionStorage.setItem(
                    "storgaeUserDispatchEmail",
                    userInfo.user.customsEmail
                  );
                }
              }
            }
          } catch (err) {
            console.error("Failed to upsert user:", err);
          }
        }
      } else if (event.eventType === EventType.LOGOUT_SUCCESS) {
        // User has logged out
        setIsAuthenticated(false);
        sessionStorage.clear();
      }
    });

    // Cleanup event callback on unmount
    return () => {
      instance.removeEventCallback(callbackId);
    };
  }, [instance]);

  // If the user isn't authenticated and there's no interaction in progress,
  // initiate the login redirect.
  useEffect(() => {
    if (!isAuthenticated && inProgress === InteractionStatus.None) {
      instance.loginRedirect(loginRequest).catch(console.error);
    }
  }, [isAuthenticated, inProgress, instance]);

  // ---------------------------------------------------------
  // 1) UpsertUser function (calls /api/upsert-user)
  // ---------------------------------------------------------
  const upsertUser = async ({
    azureUserId,
    givenName,
    familyName,
    phone,
    organizationId, // If no orgId is provided, default to an empty string
  }) => {
    const response = await fetch("/api/upsert-user", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        azureUserId,
        firstName: givenName,
        lastName: familyName,
        phone,
        organizationId,
      }),
    });

    if (!response.ok) {
      throw new Error(`Failed to upsert user: ${response.statusText}`);
    }

    // The response should be something like:
    // {
    //   userId, azureUserId, firstName, lastName, phone, organizationId
    // }
    return response.json();
  };

  // ---------------------------------------------------------
  // 2) GetUserOrganization function (calls /api/get-user-org)
  // ---------------------------------------------------------
  const getUserOrganization = async (orgId) => {
    const response = await fetch("/api/get-user-org", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ organizationId: orgId }),
    });

    if (!response.ok) {
      throw new Error(`Failed to fetch organization: ${response.statusText}`);
    }

    // Expects a JSON of shape: { organization: {...} }
    return response.json();
  };

  const getUserInformation = async (azureUserId) => {
    const response = await fetch("/api/get-user-info", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ azureUserId: azureUserId }),
    });

    if (!response.ok) {
      throw new Error(`Failed to fetch organization: ${response.statusText}`);
    }

    // Expects a JSON of shape: { organization: {...} }
    return response.json();
  };

  return (
    <>
      {/* AuthenticatedTemplate only renders children if user is authenticated */}
      <AuthenticatedTemplate>{children}</AuthenticatedTemplate>

      {/* UnauthenticatedTemplate shows fallback if not authenticated */}
      <UnauthenticatedTemplate>
        <div>Authenticating...</div>
      </UnauthenticatedTemplate>
    </>
  );
};

// ---------------------------
// The main App component
// ---------------------------
function App({ instance }) {
  // If you're using MSAL hooks, you can also get 'inProgress' here
  const { inProgress } = useMsal();

  return (
    // Wrap our entire app in the MSAL provider
    <MsalProvider instance={instance}>
      {/* AppProvider handles the B2C events and user upsert logic */}
      <AppProvider>
        <Router>
          {/* Navbar might need to know about inProgress or b2cPolicies */}
          <Navbar
            b2cPolicies={b2cPolicies}
            instance={instance}
            inProgress={inProgress}
          />

          <Routes>
            <Route path="/" element={<MainPage />} />
            <Route path="/customs" element={<Home />} />
            <Route path="/broker-list" element={<BrokerList />} />
            <Route path="/paps" element={<Paps />} />
            <Route path="/pars" element={<Pars />} />
            <Route path="/edit" element={<Edit />} />
            <Route path="/edit-pod" element={<EditPod />} />
            <Route path="/pod" element={<Pod />} />
            <Route path="/pod-history" element={<PodHistory />} />
            <Route path="/edit-pdf" element={<PdfEditor />} />
            <Route path="/chat" element={<Chat />} />
            <Route path="/mainPage" element={<MainPage />} />
            <Route path="/load-quote" element={<LoadQuote />} />
            <Route
              path="/document-generation"
              element={<DocumentGeneration />}
            />
            <Route path="/email-generation" element={<EmailGeneration />} />
            <Route path="/order-history" element={<OrderHistory />} />
            <Route path="/edit-order" element={<EditOrder />} />
            <Route path="/orders" element={<Orders />} />
            <Route path="/trucks" element={<Trucks />} />
            <Route path="/edit-truck" element={<EditTruck />} />
            <Route path="/truck-payments" element={<TruckPayments />} />
            <Route path="/invoices" element={<Invoices />} />
            <Route path="/create-invoice" element={<CreateInvoice />} />
            <Route path="/invoicing/:orderNumber" element={<Invoicing />} />
            <Route path="/ms-auth-setup" element={<MsAuthSetup />} />
            <Route path="/customs-history" element={<CustomsHistory />} />
            <Route path="/edit-customs" element={<EditCustoms />} />
            <Route path="/trailers" element={<TrailersManagement />} />
            <Route path="/drivers" element={<DriversManagement />} />
          </Routes>
        </Router>
      </AppProvider>
    </MsalProvider>
  );
}

export default App;
