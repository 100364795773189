import React, {
  useState,
  useEffect,
  useMemo,
  useRef,
  useCallback,
} from "react";
import { PDFDocument, StandardFonts, rgb } from "pdf-lib";
import Modal from "react-modal";
import bwipjs from "bwip-js";
import CropEasy from "react-easy-crop";
import {
  TextField,
  Button,
  Toolbar,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Typography,
  Slider,
} from "@mui/material";
import PdfEditor from "./PdfEditor";
import MessageModal from "./MessageModal";
import SideNavBar from "./SideNavBar";
import ConfirmationModal from "./ConfirmationModal";
import CloseIcon from "@mui/icons-material/Close";
import SmartToyIcon from "@mui/icons-material/SmartToy";
import DeleteIcon from "@mui/icons-material/Delete";
import "./Home.css";

// ----------------------------
// Helper Functions for Image Processing & Cropping
// ----------------------------
const createImage = (url) =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener("load", () => resolve(image));
    image.addEventListener("error", (error) => reject(error));
    image.setAttribute("crossOrigin", "anonymous");
    image.src = url;
  });

async function getCroppedImg(imageSrc, croppedAreaPixels) {
  const image = await createImage(imageSrc);
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");

  canvas.width = croppedAreaPixels.width;
  canvas.height = croppedAreaPixels.height;

  ctx.drawImage(
    image,
    croppedAreaPixels.x,
    croppedAreaPixels.y,
    croppedAreaPixels.width,
    croppedAreaPixels.height,
    0,
    0,
    croppedAreaPixels.width,
    croppedAreaPixels.height
  );

  return new Promise((resolve, reject) => {
    canvas.toBlob((file) => {
      if (file) {
        resolve(file);
      } else {
        reject(new Error("Canvas is empty"));
      }
    }, "image/jpeg");
  });
}

function grayscaleAndAutoLevel(imageData) {
  const { data } = imageData;
  let minGray = 255,
    maxGray = 0;
  for (let i = 0; i < data.length; i += 4) {
    const gray = Math.round((data[i] + data[i + 1] + data[i + 2]) / 3);
    data[i] = gray;
    data[i + 1] = gray;
    data[i + 2] = gray;
    if (gray < minGray) minGray = gray;
    if (gray > maxGray) maxGray = gray;
  }
  const range = maxGray - minGray || 1;
  for (let i = 0; i < data.length; i += 4) {
    const g = data[i];
    const scaled = ((g - minGray) / range) * 255;
    data[i] = scaled;
    data[i + 1] = scaled;
    data[i + 2] = scaled;
  }
}

function sharpenGrayscale(imageData) {
  const { data, width, height } = imageData;
  const output = new Uint8ClampedArray(data.length);
  const kernel = [
    [0, -1, 0],
    [-1, 5, -1],
    [0, -1, 0],
  ];
  const idx = (x, y) => 4 * (y * width + x);
  for (let y = 1; y < height - 1; y++) {
    for (let x = 1; x < width - 1; x++) {
      let sum = 0;
      for (let ky = -1; ky <= 1; ky++) {
        for (let kx = -1; kx <= 1; kx++) {
          const pixelIndex = idx(x + kx, y + ky);
          sum += data[pixelIndex] * kernel[ky + 1][kx + 1];
        }
      }
      sum = Math.min(255, Math.max(0, sum));
      const outIndex = idx(x, y);
      output[outIndex] = sum;
      output[outIndex + 1] = sum;
      output[outIndex + 2] = sum;
      output[outIndex + 3] = 255;
    }
  }
  // Copy borders as is.
  for (let x = 0; x < width; x++) {
    let topIdx = idx(x, 0);
    output[topIdx] = data[topIdx];
    output[topIdx + 1] = data[topIdx + 1];
    output[topIdx + 2] = data[topIdx + 2];
    output[topIdx + 3] = data[topIdx + 3];
    let botIdx = idx(x, height - 1);
    output[botIdx] = data[botIdx];
    output[botIdx + 1] = data[botIdx + 1];
    output[botIdx + 2] = data[botIdx + 2];
    output[botIdx + 3] = data[botIdx + 3];
  }
  for (let y = 0; y < height; y++) {
    let leftIdx = idx(0, y);
    output[leftIdx] = data[leftIdx];
    output[leftIdx + 1] = data[leftIdx + 1];
    output[leftIdx + 2] = data[leftIdx + 2];
    output[leftIdx + 3] = data[leftIdx + 3];
    let rightIdx = idx(width - 1, y);
    output[rightIdx] = data[rightIdx];
    output[rightIdx + 1] = data[rightIdx + 1];
    output[rightIdx + 2] = data[rightIdx + 2];
    output[rightIdx + 3] = data[rightIdx + 3];
  }
  for (let i = 0; i < data.length; i++) {
    data[i] = output[i];
  }
}

async function processImageFile(file) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    const url = URL.createObjectURL(file);
    img.onload = () => {
      const canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0);
      let imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
      grayscaleAndAutoLevel(imageData);
      sharpenGrayscale(imageData);
      ctx.putImageData(imageData, 0, 0);
      canvas.toBlob(
        (blob) => {
          if (blob) {
            resolve(blob);
          } else {
            reject(new Error("Canvas conversion failed."));
          }
          URL.revokeObjectURL(url);
        },
        file.type,
        1
      );
    };
    img.onerror = (error) => {
      URL.revokeObjectURL(url);
      reject(error);
    };
    img.src = url;
  });
}

// ----------------------------
// Predefined borders arrays
// ----------------------------
const pars_borders = [
  "Huntingdon, BC",
  "Pacific Highway, BC",
  "Osoyoos, BC",
  "Kingsgate, BC",
  "Coutts, AB",
  "North Portal, SK",
  "Regway, SK",
  "Emerson, MB",
  "Fort Erie, ON",
  "Windsor, ON",
  "Sarnia, ON",
  "Prescott, ON",
  "Lacolle, QC",
  "Stanstead, QC",
  "St. Stephen, NB",
  "Woodstock, NB",
];
const paps_borders = [
  "Sumas, WA",
  "Blaine, WA",
  "Oroville, WA",
  "Eastport, ID",
  "Sweet Grass, MT",
  "Portal, ND",
  "Raymond, MT",
  "Pembina, ND",
  "Buffalo, NY",
  "Detroit, MI",
  "Port Huron, MI",
  "Ogdensburg, NY",
  "Champlain, NY",
  "Derby Line, VT",
  "Calais, ME",
  "Houlton, ME",
];

function App() {
  // ----------------------------
  // State variables
  // ----------------------------
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState("");
  const [number, setNumber] = useState("");
  const [border, setBorder] = useState("");
  const [matchedBorder, setMatchedBorder] = useState("");
  const [storageOrganizationId, setStorageOrganizationId] = useState("");
  const [storageUserId, setStorageUserId] = useState("");
  const [userCompanyName, setUserCompanyName] = useState("");
  const [userCustomsEmail, setUserCustomsEmail] = useState("");
  const [userFirstName, setUserFirstName] = useState("");
  const [userLastName, setUserLastName] = useState("");
  const [userPhone, setUserPhone] = useState("");
  const [userAddress, setUserAddress] = useState("");
  const [userCity, setUserCity] = useState("");
  const [userState, setUserState] = useState("");
  const [userZip, setUserZip] = useState("");
  const [userCompanyLogo, setUserCompanyLogo] = useState("");
  const [userCompanyWebsite, setUserCompanyWebsite] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [loadCompany, setLoadCompany] = useState("");
  const [customsBroker, setCustomsBroker] = useState("");
  const [brokerId, setBrokerId] = useState(""); // New state for brokerId
  const [isPdfEditorOpen, setIsPdfEditorOpen] = useState(false);
  const [combinedPdfBlob, setCombinedPdfBlob] = useState(null);
  const [toEmail, setToEmail] = useState("");
  const [prefix, setPrefix] = useState("");
  const [pdfFiles, setPdfFiles] = useState([]);
  const [imageFiles, setImageFiles] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const fileInputRef = useRef(null);
  const [brokerList, setBrokerList] = useState([]);
  const [loadCompanyList, setLoadCompanyList] = useState([]);
  const [isBrokersLoading, setIsBrokersLoading] = useState(false);
  const [isLoadCompaniesLoading, setIsLoadCompaniesLoading] = useState(false);

  // States for cropping modal
  const [openCropModal, setOpenCropModal] = useState(false);
  const [cropImageObj, setCropImageObj] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [cropSize, setCropSize] = useState({ width: 350, height: 300 });

  // ----------------------------
  // New states for Email Modal
  // ----------------------------
  const [openEmailModal, setOpenEmailModal] = useState(false);
  const [emailSubject, setEmailSubject] = useState("");
  const [emailBody, setEmailBody] = useState("");
  const [emailTo, setEmailTo] = useState("");
  const [emailCc, setEmailCc] = useState("");
  const [emailAttachments, setEmailAttachments] = useState([]);

  // ----------------------------
  // Fetch functions and useEffects
  // ----------------------------
  const fetchAllLoadCompanies = async () => {
    setIsLoadCompaniesLoading(true);
    try {
      const orgId = sessionStorage.getItem("storageOrganizationId");
      // Pass orgId and a default status (e.g., "In Progress") to your API endpoint.
      const response = await fetch(
        `/api/fetch-orders?orgId=${encodeURIComponent(
          orgId
        )}&status=In%20Progress`
      );
      if (!response.ok) {
        throw new Error("Failed to fetch load companies");
      }
      const data = await response.json();
      setLoadCompanyList(data.result || []);
    } catch (error) {
      console.error("Error fetching all load companies:", error);
      setMessage("Error fetching load companies.");
      setStatus("Bad");
    } finally {
      setIsLoadCompaniesLoading(false);
    }
  };

  // --- New: Update customs row in the Customs table ---
  const updateCustomsRow = async () => {
    try {
      const body = {
        border: matchedBorder,
        brokerId: brokerId,
        orderId: loadCompany,
        type: prefix,
        number: number,
        organizationId: storageOrganizationId,
      };
      const response = await fetch("/api/add-customs", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });
      const data = await response.json();
      if (response.ok) {
        setMessage(`Update successful: ${data.message}`);
      } else {
        throw new Error(data.error || "Failed to update customs row");
      }
    } catch (error) {
      setMessage(`Error: ${error.message}`);
    }
  };

  useEffect(() => {
    fetchAllBrokers();
    fetchAllLoadCompanies();
  }, []);

  useEffect(() => {
    if (prefix === "PAPS") fetchPapsNumberForPrefix();
    else if (prefix === "PARS") fetchParsNumberForPrefix();
  }, [prefix]);

  const closeMessageModal = () => {
    setMessage("");
    setStatus("");
  };

  const fetchAllBrokers = async () => {
    setIsBrokersLoading(true);
    try {
      const response = await fetch("/api/fetch-brokers");
      if (!response.ok) {
        throw new Error("Failed to fetch broker list");
      }
      const data = await response.json();
      setBrokerList(data.result || []);
    } catch (error) {
      console.error("Error fetching all brokers:", error);
      setMessage("Error fetching brokers.");
      setStatus("Bad");
    } finally {
      setIsBrokersLoading(false);
    }
  };

  // Modified broker selection to also store brokerId.
  const handleBrokerSelect = (event) => {
    const selectedName = event.target.value;
    setCustomsBroker(selectedName);
    const selectedBrokerObj = brokerList.find((b) => b.name === selectedName);
    if (selectedBrokerObj) {
      setBrokerId(selectedBrokerObj.brokerId); // Save brokerId
      if (prefix === "PARS") {
        setToEmail(selectedBrokerObj.parsEmail || "");
      } else if (prefix === "PAPS") {
        setToEmail(selectedBrokerObj.papsEmail || "");
      }
    }
  };

  useEffect(() => {
    const storedOrganizationId = sessionStorage.getItem(
      "storageOrganizationId"
    );
    const storedCompanyName = sessionStorage.getItem("storageCompanyName");
    const storedCustomsEmail = sessionStorage.getItem("storageCustomsEmail");
    const storedFirstName = sessionStorage.getItem("storageFirstName");
    const storedLastName = sessionStorage.getItem("storageLastName");
    const storedPhone = sessionStorage.getItem("storagePhone");
    const storedAddress = sessionStorage.getItem("storageAddress");
    const storedCity = sessionStorage.getItem("storageCity");
    const storedState = sessionStorage.getItem("storageState");
    const storedZip = sessionStorage.getItem("storageZip");
    const companyLogoUrl = sessionStorage.getItem("storageCompanyLogo");
    const companyWebsite = sessionStorage.getItem("storageCompanyWebsite");
    const storedUserId = sessionStorage.getItem("storageUserId");
    if (storedOrganizationId) setStorageOrganizationId(storedOrganizationId);
    if (storedUserId) setStorageUserId(storedUserId);
    if (storedCompanyName) setUserCompanyName(storedCompanyName);
    if (storedCustomsEmail) setUserCustomsEmail(storedCustomsEmail);
    if (storedFirstName) setUserFirstName(storedFirstName);
    if (storedLastName) setUserLastName(storedLastName);
    if (storedPhone) setUserPhone(storedPhone);
    if (storedAddress) setUserAddress(storedAddress);
    if (storedCity) setUserCity(storedCity);
    if (storedState) setUserState(storedState);
    if (storedZip) setUserZip(storedZip);
    if (companyLogoUrl) setUserCompanyLogo(companyLogoUrl);
    if (companyWebsite) setUserCompanyWebsite(companyWebsite);
  }, []);

  const togglePdfEditorModal = () => {
    setIsPdfEditorOpen(!isPdfEditorOpen);
  };

  const handleNewChat = () => {
    setMessage("");
    setNumber("");
    setBorder("");
    setMatchedBorder("");
    setLoadCompany("");
    setCustomsBroker("");
    setBrokerId("");
    setIsPdfEditorOpen(false);
    setCombinedPdfBlob(null);
    setToEmail("");
    setPrefix("");
    setPdfFiles([]);
    setImageFiles([]);
    if (fileInputRef.current) {
      fileInputRef.current.value = null;
    }
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const confirmNewChat = () => {
    handleNewChat();
    closeModal();
  };

  // ----------------------------
  // File Change: Accept both PDFs and Images
  // ----------------------------
  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    const newPdfFiles = [];
    const newImageFiles = [];
    files.forEach((file) => {
      if (file.type === "application/pdf") {
        newPdfFiles.push(file);
      } else if (file.type.startsWith("image/")) {
        const previewUrl = URL.createObjectURL(file);
        newImageFiles.push({
          id: Math.random().toString(36).substring(2),
          file,
          previewUrl,
          croppedBlob: null,
          processedBlob: null,
          isCropped: false,
          error: null,
        });
      }
    });
    setPdfFiles((prev) => [...prev, ...newPdfFiles]);
    setImageFiles((prev) => [...prev, ...newImageFiles]);
  };

  // ----------------------------
  // Cropping modal handlers
  // ----------------------------
  const handleOpenCropModal = (imgFileObj) => {
    setCropImageObj(imgFileObj);
    setOpenCropModal(true);
  };

  const handleCloseCropModal = () => {
    setOpenCropModal(false);
    setCrop({ x: 0, y: 0 });
    setZoom(1);
    setCroppedAreaPixels(null);
    setCropImageObj(null);
  };

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const handleConfirmCrop = async () => {
    if (!cropImageObj || !croppedAreaPixels) return;
    try {
      const croppedBlob = await getCroppedImg(
        cropImageObj.previewUrl,
        croppedAreaPixels
      );
      const processedBlob = await processImageFile(croppedBlob);
      setImageFiles((prev) =>
        prev.map((item) => {
          if (item.id === cropImageObj.id) {
            return {
              ...item,
              croppedBlob,
              processedBlob,
              isCropped: true,
              error: null,
            };
          }
          return item;
        })
      );
    } catch (error) {
      console.error(error);
      setImageFiles((prev) =>
        prev.map((item) => {
          if (item.id === cropImageObj.id) {
            return { ...item, error: "Cropping failed." };
          }
          return item;
        })
      );
    }
    handleCloseCropModal();
  };

  // ----------------------------
  // New Email Modal Handlers
  // ----------------------------
  const handleOpenEmailModal = () => {
    if (!combinedPdfBlob) {
      setMessage("Please generate a PDF first.");
      setStatus("Bad");
      return;
    }
    // Split the current 'toEmail' (which may be comma-separated) into primary and cc
    const emails = toEmail
      .split(",")
      .map((e) => e.trim())
      .filter(Boolean);
    const primary = emails[0] || "";
    const cc = emails.slice(1).join(", ");
    setEmailTo(primary);
    setEmailCc(cc);
    setEmailSubject(`${prefix} ${number} ${matchedBorder}`);
    setEmailBody(`Thanks.`);
    setOpenEmailModal(true);
  };

  const handleEmailAttachmentChange = (event) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      setEmailAttachments((prev) => [...prev, ...Array.from(files)]);
    }
  };

  const handleRemoveEmailAttachment = (index) => {
    setEmailAttachments((prev) => {
      const updated = [...prev];
      updated.splice(index, 1);
      return updated;
    });
  };

  const handleEnhanceEmail = async () => {
    try {
      const response = await fetch("/api/enhance-subject-body-customs", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          subject: emailSubject,
          body: emailBody,
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to enhance subject/body");
      }

      const data = await response.json();

      // data.message is expected to be a JSON string
      const parsedMessage = JSON.parse(data.message);

      if (parsedMessage.subject) {
        setEmailSubject(parsedMessage.subject);
        console.log("Enhanced Subject:", parsedMessage.subject);
      }
      if (parsedMessage.body) {
        setEmailBody(parsedMessage.body);
        console.log("Enhanced Body:", parsedMessage.body);
      }
    } catch (error) {
      console.error("Error in AI enhancement:", error);
      alert("Error enhancing email content. Please try again.");
    }
  };

  // ----------------------------
  // Updated Email Sending Handler
  // ----------------------------
  const handleSendEmailModal = async () => {
    setIsLoading(true);
    try {
      const combinedEmails = emailTo + (emailCc ? `, ${emailCc}` : "");
      await sendEmailWithAttachment(
        combinedEmails,
        emailSubject,
        combinedPdfBlob,
        emailBody,
        emailAttachments
      );
      // Call the new update function for Customs instead of separate PAPS/PARS updates
      await updateCustomsRow();
      setMessage("Email sent successfully.");
      setStatus("Good");
      setOpenEmailModal(false);
    } catch (error) {
      console.error("Error sending email:", error);
      setMessage("An error occurred while sending the email.");
      setStatus("Bad");
    } finally {
      setIsLoading(false);
    }
  };

  // ----------------------------
  // PDF Generation – All pages forced to Letter Size (612x792)
  // ----------------------------
  const generatePdf = async () => {
    if (pdfFiles.length === 0 && imageFiles.length === 0) {
      setMessage(
        "Please select at least one PDF or image file before generating."
      );
      setStatus("Bad");
      return;
    }
    if (!number) {
      setMessage("Please provide a number for the barcode.");
      setStatus("Bad");
      return;
    }
    setIsLoading(true);
    try {
      // Create a new PDF document
      const mergedPdf = await PDFDocument.create();
      const letterWidth = 612;
      const letterHeight = 792;
      // Create the cover page (letter size)
      const customPage = mergedPdf.addPage([letterWidth, letterHeight]);
      const fontSize = 30;
      const font = await mergedPdf.embedFont(StandardFonts.Helvetica);
      const text1 = `${prefix} ${number}`;
      const text2 = `Crossing at ${matchedBorder}`;
      const nowUTC = new Date();
      const currentDate = new Date(
        nowUTC.toLocaleString("en-US", { timeZone: "America/Vancouver" })
      );
      currentDate.setHours(currentDate.getHours() + 2);
      if (currentDate.getMinutes() >= 30) {
        currentDate.setMinutes(0);
        currentDate.setHours(currentDate.getHours() + 1);
      } else {
        currentDate.setMinutes(0);
      }
      const options = {
        day: "2-digit",
        month: "long",
        year: "numeric",
        hour: "numeric",
        minute: "2-digit",
        hour12: true,
      };
      const dateString = currentDate.toLocaleString("en-US", options);
      const text3 = `On ${dateString}`;
      const centerX = letterWidth / 2;
      const centerY = letterHeight / 2;
      // Generate barcode image using helper below
      const barcodeImage = await generateBarcodeImage(number, mergedPdf);
      if (!barcodeImage) {
        setMessage("Error generating barcode. Please try again.");
        setStatus("Bad");
        setIsLoading(false);
        return;
      }
      const barcodeHeight = 60;
      customPage.drawImage(barcodeImage, {
        x: centerX - barcodeImage.width / 2,
        y: centerY + barcodeHeight,
        width: barcodeImage.width,
        height: barcodeHeight,
      });
      const text1Width = font.widthOfTextAtSize(text1, fontSize);
      const text2Width = font.widthOfTextAtSize(text2, fontSize);
      const text3Width = font.widthOfTextAtSize(text3, fontSize);
      customPage.drawText(text1, {
        x: centerX - text1Width / 2,
        y: centerY,
        size: fontSize,
        font,
      });
      customPage.drawText(text2, {
        x: centerX - text2Width / 2,
        y: centerY - 40,
        size: fontSize,
        font,
      });
      customPage.drawText(text3, {
        x: centerX - text3Width / 2,
        y: centerY - 80,
        size: fontSize,
        font,
      });
      // Footer line and text
      customPage.drawLine({
        start: { x: 40, y: 40 },
        end: { x: letterWidth - 40, y: 40 },
        thickness: 0.5,
        color: rgb(0, 0, 0),
      });
      customPage.drawText("Generated by LoadMinds ©", {
        x: 40,
        y: 25,
        size: 9,
        font,
        color: rgb(0.39, 0.39, 0.39),
      });

      // Helper function to add PDF pages forcing letter size
      const addPdfPagesToMerged = async (mergedPdf, pdfBytes, forceLetter) => {
        const loadedPdf = await PDFDocument.load(pdfBytes);
        const pageIndices = loadedPdf.getPageIndices();
        for (const idx of pageIndices) {
          if (forceLetter) {
            const [embeddedPage] = await mergedPdf.embedPages([
              loadedPdf.getPage(idx),
            ]);
            const origWidth = embeddedPage.width;
            const origHeight = embeddedPage.height;
            const xScale = letterWidth / origWidth;
            const yScale = letterHeight / origHeight;
            const scale = Math.min(xScale, yScale);
            const scaledWidth = origWidth * scale;
            const scaledHeight = origHeight * scale;
            const x = (letterWidth - scaledWidth) / 2;
            const y = (letterHeight - scaledHeight) / 2;
            const newPage = mergedPdf.addPage([letterWidth, letterHeight]);
            newPage.drawPage(embeddedPage, {
              x,
              y,
              xScale: scale,
              yScale: scale,
            });
          } else {
            const [copiedPage] = await mergedPdf.copyPages(loadedPdf, [idx]);
            mergedPdf.addPage(copiedPage);
          }
        }
      };

      // Append uploaded PDF files as letter pages
      for (const file of pdfFiles) {
        const fileBytes = await file.arrayBuffer();
        await addPdfPagesToMerged(mergedPdf, fileBytes, true);
      }

      // Append uploaded image files (using processedBlob if available)
      for (const imgObj of imageFiles) {
        const blobToUse = imgObj.processedBlob || imgObj.file;
        if (blobToUse) {
          const buffer = await blobToUse.arrayBuffer();
          let embeddedImage;
          // Use the actual blob's MIME type rather than the original file type.
          if (blobToUse.type.includes("png")) {
            embeddedImage = await mergedPdf.embedPng(buffer);
          } else {
            embeddedImage = await mergedPdf.embedJpg(buffer);
          }
          const { width: origWidth, height: origHeight } =
            embeddedImage.scale(1);
          const xScale = letterWidth / origWidth;
          const yScale = letterHeight / origHeight;
          const scale = Math.min(xScale, yScale);
          const scaledWidth = origWidth * scale;
          const scaledHeight = origHeight * scale;
          const x = (letterWidth - scaledWidth) / 2;
          const y = (letterHeight - scaledHeight) / 2;
          const newPage = mergedPdf.addPage([letterWidth, letterHeight]);
          newPage.drawImage(embeddedImage, {
            x,
            y,
            width: scaledWidth,
            height: scaledHeight,
          });
        }
      }

      const mergedPdfBytes = await mergedPdf.save();
      // Create a custom file name using prefix, number, and matched border (remove commas)
      const cleanBorder = matchedBorder.replace(/,/g, "");
      const fileName = `${prefix} ${number} ${cleanBorder}.pdf`;
      // Create a File object so that the filename is preserved
      const mergedFile = new File([mergedPdfBytes], fileName, {
        type: "application/pdf",
      });
      setCombinedPdfBlob(mergedFile);
      togglePdfEditorModal();
    } catch (error) {
      console.error("Error generating PDF:", error);
      setMessage("An unexpected error occurred while generating the PDF.");
      setStatus("Bad");
    } finally {
      setIsLoading(false);
    }
  };

  // ----------------------------
  // Barcode generation helper using bwip-js
  // ----------------------------
  const generateBarcodeImage = async (data, mergedPdf) => {
    if (!data) {
      throw new Error("Barcode data is missing");
    }
    try {
      const canvas = document.createElement("canvas");
      await bwipjs.toCanvas(canvas, {
        bcid: "code128",
        text: data,
        scale: 3,
        height: 10,
        includetext: true,
        textxalign: "center",
      });
      const barcodeDataUrl = canvas.toDataURL("image/png");
      const barcodeImageBytes = await fetch(barcodeDataUrl).then((res) =>
        res.arrayBuffer()
      );
      return await mergedPdf.embedPng(barcodeImageBytes);
    } catch (err) {
      console.error("Error generating barcode:", err);
      throw err;
    }
  };

  // ----------------------------
  // Fetch new number functions (for PAPS and PARS)
  // ----------------------------
  const fetchPapsNumberForPrefix = async () => {
    try {
      let storedUserId = sessionStorage.getItem("storageUserId");
      let storedPapsStart = sessionStorage.getItem("storagePapsStart");
      const response = await fetch(
        `/api/fetch-new-number?userId=${encodeURIComponent(
          storedUserId
        )}&papsStart=${encodeURIComponent(storedPapsStart)}`,
        {
          method: "GET",
          headers: { "Content-Type": "application/json" },
        }
      );
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      if (data.result && data.result.length > 0) {
        setNumber(data.result[0].Number);
      } else {
        setMessage("No number found in the response.");
      }
    } catch (error) {
      console.error("Failed to fetch number: ", error.message);
    }
  };

  const fetchParsNumberForPrefix = async () => {
    try {
      let storedUserId = sessionStorage.getItem("storageUserId");
      let storedParsStart = sessionStorage.getItem("storageParsStart");
      const response = await fetch(
        `/api/get-pars-new-number?userId=${encodeURIComponent(
          storedUserId
        )}&parsStart=${encodeURIComponent(storedParsStart)}`,
        {
          method: "GET",
          headers: { "Content-Type": "application/json" },
        }
      );
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      if (data.result && data.result.length > 0) {
        setNumber(data.result[0].Number);
      } else {
        setMessage("No number found in the response.");
      }
    } catch (error) {
      console.error("Failed to fetch number: ", error.message);
    }
  };

  const sortedBorders = useMemo(() => {
    return [...pars_borders, ...paps_borders].sort((a, b) =>
      a.localeCompare(b)
    );
  }, []);

  // Modified sendEmailWithAttachment to handle additional attachments
  const sendEmailWithAttachment = async (
    toEmail,
    subject,
    attachment,
    body,
    additionalAttachments = []
  ) => {
    const emails = toEmail.split(",").map((e) => e.trim());
    const primaryEmail = emails.shift();
    const ccEmails = emails.join(",");
    const replyTo = userCustomsEmail;
    const formData = new FormData();
    formData.append("firstName", userFirstName);
    formData.append("lastName", userLastName);
    formData.append("companyName", userCompanyName);
    formData.append("phone", userPhone);
    formData.append("toEmail", primaryEmail);
    formData.append("storageUserId", storageUserId);
    formData.append("storageOrganizationId", storageOrganizationId);
    formData.append("ccEmails", ccEmails);
    formData.append("subject", subject);
    formData.append("body", body);
    formData.append("attachment", attachment);
    formData.append("replyTo", replyTo);
    formData.append("number", number);
    formData.append("address", userAddress);
    formData.append("city", userCity);
    formData.append("state", userState);
    formData.append("zip", userZip);
    formData.append("companyLogo", userCompanyLogo);
    formData.append("companyWebsite", userCompanyWebsite);

    if (additionalAttachments && additionalAttachments.length > 0) {
      additionalAttachments.forEach((file) => {
        formData.append("additionalAttachments", file);
      });
    }
    try {
      const response = await fetch("/api/send-email-with-attachment-customs", {
        method: "POST",
        body: formData,
      });
      const data = await response.json();
      if (response.ok) {
        setMessage(data.message);
      } else {
        setMessage(data.error);
      }
    } catch (error) {
      setMessage("An error occurred while sending the email.");
    }
  };

  useEffect(() => {
    const textarea = document.getElementById("toEmail");
    if (!textarea) return;
    const autoResize = () => {
      textarea.style.height = "auto";
      textarea.style.height = textarea.scrollHeight + "px";
    };
    autoResize();
    textarea.addEventListener("input", autoResize);
    return () => textarea.removeEventListener("input", autoResize);
  }, [toEmail]);

  const updateBorderAndPrefix = (e) => {
    const inputBorder = e.target.value.trim();
    setBorder(inputBorder);
    const normalizedInputBorder = inputBorder.toLowerCase();
    const foundParsBorder = pars_borders.find((b) =>
      b.toLowerCase().includes(normalizedInputBorder)
    );
    const foundPapsBorder = paps_borders.find((b) =>
      b.toLowerCase().includes(normalizedInputBorder)
    );
    let newPrefix = "";
    if (foundParsBorder) {
      newPrefix = "PARS";
      setMatchedBorder(foundParsBorder);
    } else if (foundPapsBorder) {
      newPrefix = "PAPS";
      setMatchedBorder(foundPapsBorder);
    } else {
      setMatchedBorder("");
    }
    setPrefix(newPrefix);
  };

  // ----------------------------
  // Render
  // ----------------------------
  return (
    <div className="customs-container">
      <SideNavBar handleNewChat={openModal} />
      <ConfirmationModal
        open={isModalOpen}
        handleClose={closeModal}
        handleConfirm={confirmNewChat}
        option="Customs"
      />
      <div className="custom-window">
        <Toolbar style={{ minHeight: "83px" }} />
        <div className="custom-body">
          {/* File Input: Accept PDFs and Images */}
          <input
            type="file"
            id="pdfUpload"
            multiple
            accept="application/pdf, image/*"
            className="file-input"
            onChange={handleFileChange}
            ref={fileInputRef}
          />

          {/* Display uploaded images with crop option */}
          {imageFiles.length > 0 && (
            <div
              style={{
                border: "1px solid #aaa",
                padding: "10px",
                margin: "10px 0",
              }}
            >
              <h3>Uploaded Images (Crop optional)</h3>
              {imageFiles.map((img) => (
                <div
                  key={img.id}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "10px",
                  }}
                >
                  <img
                    src={img.previewUrl}
                    alt="preview"
                    style={{
                      width: 80,
                      height: 80,
                      objectFit: "cover",
                      marginRight: "10px",
                      border: "1px solid #666",
                    }}
                  />
                  <span>
                    {img.isCropped
                      ? "Cropped & Processed"
                      : "Not Cropped (auto processed)"}
                  </span>
                  <Button
                    onClick={() => handleOpenCropModal(img)}
                    style={{ marginLeft: "10px" }}
                    variant="outlined"
                    color="secondary"
                    size="small"
                  >
                    Crop (Optional)
                  </Button>
                  {img.error && (
                    <span style={{ color: "red", marginLeft: "10px" }}>
                      {img.error}
                    </span>
                  )}
                </div>
              ))}
            </div>
          )}

          {/* Border Selection */}
          <div className="label-input-row">
            <label htmlFor="borderSelect" className="input-label label-inline">
              SELECT BORDER :
            </label>
            <select
              id="borderSelect"
              value={border}
              onChange={(e) => {
                setBorder(e.target.value);
                updateBorderAndPrefix(e);
              }}
              className="border-input"
            >
              <option value="">------------------</option>
              {sortedBorders.map((borderOption) => (
                <option key={borderOption} value={borderOption}>
                  {borderOption}
                </option>
              ))}
            </select>
          </div>

          {/* Broker selection */}
          <div className="label-input-row">
            <label htmlFor="customsBroker" className="input-label label-inline">
              SELECT BROKER :
            </label>
            <select
              id="customsBroker"
              value={customsBroker}
              onChange={handleBrokerSelect}
              className="border-input"
              disabled={isBrokersLoading}
            >
              {isBrokersLoading ? (
                <option>Loading...</option>
              ) : (
                <>
                  <option value="">------------------</option>
                  {brokerList.map((broker) => {
                    if (!broker || !broker.brokerId || !broker.name) {
                      return null;
                    }
                    return (
                      <option key={broker.brokerId} value={broker.name}>
                        {broker.name.toUpperCase()}
                      </option>
                    );
                  })}
                </>
              )}
            </select>
          </div>

          {/* Load Company selection */}
          <div className="label-input-row">
            <label
              htmlFor="loadCompanySelect"
              className="input-label label-inline"
            >
              SELECT ORDER NUMBER :
            </label>
            <select
              id="loadCompanySelect"
              value={loadCompany}
              onChange={(e) => setLoadCompany(e.target.value)}
              className="border-input"
              disabled={isLoadCompaniesLoading}
            >
              {isLoadCompaniesLoading ? (
                <option>Loading...</option>
              ) : (
                <>
                  <option value="">------------------</option>
                  {loadCompanyList.map((order) => (
                    <option key={order.id} value={order.orderId}>
                      {order.customerOrderNo} -{" "}
                      {order.CustomerCompany ? order.CustomerCompany.name : ""}
                    </option>
                  ))}
                </>
              )}
            </select>
          </div>

          {/* Broker's Email */}
          <div className="label-input-row">
            <label htmlFor="toEmail" className="input-label label-inline">
              BROKER'S EMAIL :
            </label>
            <TextField
              type="text"
              id="toEmail"
              value={toEmail}
              onChange={(e) => setToEmail(e.target.value)}
              variant="outlined"
              className="text-field-standard"
              InputProps={{ style: { color: "white" } }}
              InputLabelProps={{ className: "input-label" }}
              fullWidth
            />
          </div>

          {/* Number input */}
          <div className="label-input-row">
            <label htmlFor="Number" className="input-label">
              NUMBER :
            </label>
            <input
              type="text"
              value={number}
              onChange={(e) => setNumber(e.target.value)}
              className="border-input"
            />
          </div>

          <Button
            className="generate-button"
            onClick={generatePdf}
            disabled={isLoading}
          >
            GENERATE PDF
          </Button>

          {/* Modified Send Email button that opens the Email Modal */}
          <Button
            className="send-button"
            onClick={handleOpenEmailModal}
            disabled={isLoading}
            style={{ marginBottom: "30px" }}
          >
            SEND EMAIL
          </Button>

          {/* Show Pdf Button */}
          <Button
            className="show-pdf-button"
            onClick={togglePdfEditorModal}
            disabled={!combinedPdfBlob}
            style={{ marginBottom: "30px" }}
          >
            Show Pdf
          </Button>

          {/* PDF Editor Modal */}
          <Modal
            isOpen={isPdfEditorOpen}
            onRequestClose={togglePdfEditorModal}
            contentLabel="PDF Editor"
            style={{ content: { inset: "3%", overflow: "auto" } }}
            ariaHideApp={false}
          >
            <PdfEditor
              combinedPdfBlob={combinedPdfBlob}
              onSave={(updatedBlob) => setCombinedPdfBlob(updatedBlob)}
              onClose={togglePdfEditorModal}
              showSideNavBar={false}
              paddingLeft="12%"
              paddingRight="5%"
              prefix={prefix}
              number={number}
              matchedBorder={matchedBorder}
            />
          </Modal>

          {/* Message Modal */}
          <MessageModal
            open={status !== ""}
            handleClose={closeMessageModal}
            title={
              status === "Good"
                ? "Success"
                : status === "Bad"
                ? "Error"
                : "Processing..."
            }
            message={message}
            status={status}
          />
        </div>
      </div>

      {/* Cropping Modal using react-modal */}
      <Modal
        isOpen={openCropModal}
        onRequestClose={handleCloseCropModal}
        contentLabel="Crop Image"
        style={{
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            transform: "translate(-50%, -50%)",
            width: "90vw",
            height: "80vh",
            background: "#fff",
            padding: "20px",
          },
        }}
        ariaHideApp={false}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h3>Crop your image (optional)</h3>
          <IconButton onClick={handleCloseCropModal}>
            <CloseIcon />
          </IconButton>
        </div>
        <div
          style={{
            position: "relative",
            width: "100%",
            height: "60%",
            background: "#333",
            marginBottom: "20px",
          }}
        >
          {cropImageObj && (
            <CropEasy
              image={cropImageObj.previewUrl}
              crop={crop}
              zoom={zoom}
              aspect={null}
              cropShape="rect"
              showGrid={true}
              restrictPosition={false}
              cropSize={cropSize}
              onCropChange={setCrop}
              onZoomChange={setZoom}
              onCropComplete={onCropComplete}
            />
          )}
        </div>
        <div style={{ marginBottom: "20px" }}>
          <div style={{ display: "flex", gap: "20px", alignItems: "center" }}>
            <div style={{ flex: 1 }}>
              <p>Width: {cropSize.width}px</p>
              <Slider
                value={cropSize.width}
                min={50}
                max={1200}
                step={10}
                onChange={(e, newVal) =>
                  setCropSize((prev) => ({ ...prev, width: newVal }))
                }
              />
            </div>
            <div style={{ flex: 1 }}>
              <p>Height: {cropSize.height}px</p>
              <Slider
                value={cropSize.height}
                min={50}
                max={1200}
                step={10}
                onChange={(e, newVal) =>
                  setCropSize((prev) => ({ ...prev, height: newVal }))
                }
              />
            </div>
          </div>
          <div style={{ marginTop: "20px" }}>
            <p>Zoom: {zoom.toFixed(2)}</p>
            <Slider
              value={zoom}
              min={1}
              max={5}
              step={0.1}
              onChange={(e, zoomValue) => setZoom(zoomValue)}
            />
          </div>
        </div>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleConfirmCrop}
          >
            Confirm Crop
          </Button>
        </div>
      </Modal>

      {/* Email Modal (using react-modal) */}
      <Modal
        isOpen={openEmailModal}
        onRequestClose={() => setOpenEmailModal(false)}
        contentLabel="Send Email"
        style={{
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            transform: "translate(-50%, -50%)",
            width: "600px",
            maxWidth: "90vw",
            background: "#333",
            color: "#fff",
            padding: "20px",
          },
        }}
        ariaHideApp={false}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
          <h3>Send Email</h3>
          <IconButton onClick={() => setOpenEmailModal(false)}>
            <CloseIcon style={{ color: "#fff" }} />
          </IconButton>
        </div>
        <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
          <TextField
            label="Subject"
            variant="filled"
            fullWidth
            value={emailSubject}
            onChange={(e) => setEmailSubject(e.target.value)}
            InputProps={{ style: { color: "#fff" } }}
            InputLabelProps={{ style: { color: "#aaa" } }}
            sx={{ backgroundColor: "#222" }}
          />
          <TextField
            label="To"
            variant="filled"
            fullWidth
            value={emailTo}
            onChange={(e) => setEmailTo(e.target.value)}
            InputProps={{ style: { color: "#fff" } }}
            InputLabelProps={{ style: { color: "#aaa" } }}
            sx={{ backgroundColor: "#222" }}
          />
          <TextField
            label="CC"
            variant="filled"
            fullWidth
            value={emailCc}
            onChange={(e) => setEmailCc(e.target.value)}
            InputProps={{ style: { color: "#fff" } }}
            InputLabelProps={{ style: { color: "#aaa" } }}
            sx={{ backgroundColor: "#222" }}
          />
          <TextField
            label="Body"
            variant="filled"
            multiline
            rows={5}
            fullWidth
            value={emailBody}
            onChange={(e) => setEmailBody(e.target.value)}
            InputProps={{
              style: { color: "#fff" },
              endAdornment: (
                <IconButton onClick={handleEnhanceEmail}>
                  <SmartToyIcon sx={{ color: "#fff" }} />
                </IconButton>
              ),
            }}
            InputLabelProps={{ style: { color: "#aaa" } }}
            sx={{ backgroundColor: "#222" }}
          />
          <div>
            <InputLabel sx={{ mb: 1, color: "#aaa" }}>
              Additional Attachments
            </InputLabel>
            <Button variant="contained" component="label">
              Add Files
              <input
                type="file"
                hidden
                multiple
                onChange={handleEmailAttachmentChange}
              />
            </Button>
            {emailAttachments.length > 0 && (
              <List dense sx={{ mt: 2, maxHeight: 150, overflowY: "auto" }}>
                {emailAttachments.map((file, idx) => (
                  <ListItem key={idx}>
                    <ListItemText
                      primary={file.name}
                      secondary={`${(file.size / 1024).toFixed(2)} KB`}
                    />
                    <ListItemSecondaryAction>
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        onClick={() => handleRemoveEmailAttachment(idx)}
                      >
                        <DeleteIcon sx={{ color: "#fff" }} />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>
            )}
          </div>
          <Typography variant="body2" sx={{ mt: 2 }}>
            <strong>Auto-attached:</strong> {combinedPdfBlob?.name || "PDF"}
          </Typography>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "10px",
            }}
          >
            <Button
              variant="contained"
              color="secondary"
              onClick={handleSendEmailModal}
            >
              Send
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default App;
